import {
  postData,
  getElementByName,
  addCurrentPageEventListener,
} from "../../shared/utils";
import { pushNotification } from "../pushNotification";

export function messagePreview(args) {
  const {
    url,
    testUrl,
    fieldName,
    integrationName = "Slack",
    listenFor,
    type,
  } = args;

  return {
    previewing: false,
    loading: false,
    init() {
      if (listenFor) {
        addCurrentPageEventListener(listenFor, (event) => {
          this.previewing = false;

          this.$refs.textarea.value = event.detail;

          this.$refs.textareaContainer?.setAttribute(
            "data-replicated-value",
            event.detail,
          );
        });
      }

      if (!this.$refs.testMessageButton) {
        return;
      }

      this.$refs.testMessageButton.addEventListener("click", () => {
        if (!testUrl?.length) return;

        postData(testUrl, { text: this.$refs.textarea.value, type }).then(
          () => {
            pushNotification({
              title: "Success!",
              desc: `Check ${integrationName} for a test message.`,
            });
          },
        );
      });
    },
    hidePreview() {
      this.previewing = false;

      this.$nextTick(() => {
        this.$root.querySelector("textarea").focus();
      });
    },
    preview() {
      this.loading = true;
      this.previewing = true;

      const params = { message: getElementByName(fieldName).value };

      postData(url, params).then((data) => {
        this.$refs.preview.innerHTML = data.html;

        this.loading = false;
      });
    },
  };
}

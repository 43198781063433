import { Turbo } from "@hotwired/turbo-rails";

export function squadQuickjump(args) {
  const { baseUrl } = args;

  return {
    open: false,
    toggle() {
      if (this.open) {
        this.open = false;
        return;
      }

      this.open = true;

      const searchField = this.$root.querySelector(".x-searchInput");
      this.$nextTick(() => {
        searchField.focus();
      });
    },
    select(squad) {
      const url =
        baseUrl + (squad.is_parent ? "/parent/" : "/team/") + squad.encoded_id;

      Turbo.visit(url);
    },
  };
}

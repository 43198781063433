export function livePreview(args) {
  const {
    prompt = null,
    rankingItem = null,
    placeholder = "",
    selectedOption = 0,
    showDeleteModal = false,
    deleteConfirmText = "",
  } = args;

  return {
    prompt,
    rankingItem,
    placeholder,
    selectedOption,
    showDeleteModal,
    deleteConfirmText,
  };
}

export function codeBlock({ maxLines, formatCode = false } = {}) {
  return {
    codeToHighlight: "",
    async toHtml(code) {
      if (!code) return null;

      let codeToHighlight = maxLines
        ? code.trim().split("\n").slice(0, maxLines).join("\n")
        : code.trim();

      const { format } = await import("@sqltools/formatter");

      if (formatCode) {
        codeToHighlight = format(codeToHighlight);
      }

      this.codeToHighlight = codeToHighlight;

      const { renderCodeBlockString } = await import("./renderCodeBlockString");

      const codeHtml = renderCodeBlockString({
        code: codeToHighlight,
      });

      return codeHtml;
    },
  };
}

import {
  addCurrentPageEventListener,
  objectToQueryString,
} from "../../shared/utils";

export function intercomLineChart(args) {
  const { endpoint, name, getFilters } = args;
  let chart = null;

  return {
    displayable: false,
    chartData: args.chartData,
    showChart: args.showChart,
    loading: false,
    init() {
      this.chartData.elChart = document.getElementById(this.chartData.chartId);
      this.chartData.yAxisMin = 0;
      this.chartData.yAxisMax = 100;
      chart = new window.LineChart(this.chartData);
      chart.redraw();

      this.$nextTick(() => {
        this.reloadChart();
      });

      addCurrentPageEventListener("dx:intercom-filters-changed", () => {
        this.reloadChart();
      });
    },
    initBarChart(chartName, chartData) {
      this.barChartsData[chartName] = chartData.chart;
    },
    reloadChart: function () {
      const filtersQueryString = objectToQueryString({ name, ...getFilters() });
      this.loading = true;
      fetch(`${endpoint}${"?" + filtersQueryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.chartData = data.chart;
        })
        .then(() => this.loadChart())
        .catch(() => {
          // no op
        });
    },
    loadChart: function () {
      chart.values = this.chartData.data;
      this.displayable = chart.values ? chart.values.length > 0 : false;

      chart.startDate = this.chartData.startDate;
      chart.endDate = this.chartData.endDate;
      chart.yAxisMax =
        Math.max(...this.chartData.data.map((v) => v.value)) * 1.2;
      chart.redraw();
      this.loading = false;
    },
  };
}

import { pushNotification } from "../pushNotification";

export function userProfile(baseUrl) {
  return {
    user: null,
    loading: false,
    closeProfile() {
      this.user = null;
      this.loading = false;
    },
    copyEmail() {
      navigator.clipboard.writeText(this.user.email).then(() =>
        pushNotification({
          title: "Email copied to clipboard!",
          desc: "Paste it wherever you'd like.",
        }),
      );
    },
    fetchProfile(userId) {
      const url = `${baseUrl}?user_id=${userId}`;

      this.user = null;
      this.loading = true;

      fetch(url)
        .then((response) => response.json())
        .then((user) => {
          this.user = user;
          this.loading = false;
        });
    },
  };
}

export function chartContainer() {
  return {
    charts: {},
    shown: {},
    registerChart(name, { showEventName, hideEventName, shown }) {
      this.charts[name] = { showEventName, hideEventName };
      this.shown[name] = shown;
    },

    showChart(name) {
      for (const chartName in this.charts) {
        if (chartName === name) {
          this.$dispatch(this.charts[chartName].showEventName);
          this.shown[chartName] = true;
        } else {
          this.$dispatch(this.charts[chartName].hideEventName);
          this.shown[chartName] = false;
        }
      }
    },
  };
}

import {
  setQueryStringParam,
  deleteQueryStringParam,
  scrollToHash,
} from "../../shared/utils";
import { getQueryParam } from "../../lib/utils";
import { pushNotification } from "../pushNotification";

export function snapshotSetup() {
  return {
    customWorkflowsModalOpen: false,
    behavioralQuestionToEdit: null,
    init() {
      scrollToHash();

      const newlyCreated = getQueryParam("workflow_created");
      const newlySaved = getQueryParam("workflow_saved");
      if (newlyCreated || newlySaved) {
        const desc = `Custom workflow ${newlyCreated ? "created" : newlySaved && "saved"}.`;

        pushNotification({
          title: "Success!",
          desc,
        });
        deleteQueryStringParam("workflow_created");
        deleteQueryStringParam("workflow_saved");
      }

      if (getQueryParam("custom_workflow")) {
        this.openCustomWorkflowModal();
      }
    },
    openCustomWorkflowModal(question) {
      setQueryStringParam("custom_workflow", true);
      this.behavioralQuestionToEdit = question;
      this.customWorkflowsModalOpen = true;
    },
    closeCustomWorkflowModal() {
      deleteQueryStringParam("custom_workflow");
      this.behavioralQuestionToEdit = null;
      this.customWorkflowsModalOpen = false;
    },
  };
}

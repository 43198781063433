import { setQueryStringParam } from "../../shared/utils";
import dayjs from "dayjs";

export function datacloudFilters(args) {
  const { filters, eventBase, intervals } = args;

  return {
    filters,
    intervals,
    selectedInterval: filters.interval || "m",
    init() {
      // Set initial query string
      for (const key in this.filters) {
        const value = this.filters[key];

        setQueryStringParam(key, value);
      }

      this.hideDailyIfNecessary();
    },
    setFilter(key, value) {
      filters[key] = value;

      setQueryStringParam(key, value);

      document.dispatchEvent(
        new CustomEvent(`dx:${eventBase}-filters-changed`, {
          detail: { filters: filters },
        }),
      );
    },
    setFilters(filtersOptions) {
      let changed = false;
      for (const key in filtersOptions) {
        const value = filtersOptions[key];
        if (this.filters[key] !== value) {
          changed = true;
          this.filters[key] = value;
          setQueryStringParam(key, value);
        }
      }

      this.hideDailyIfNecessary();

      if (changed) {
        document.dispatchEvent(
          new CustomEvent(`dx:${eventBase}-filters-changed`, {
            detail: { filters: this.filters },
          }),
        );
      }
    },
    getFilters() {
      return filters;
    },
    hideDailyIfNecessary() {
      if (!this.filters.start_date || !this.filters.end_date) return;
      const daysBetween = Math.abs(
        dayjs(this.filters.end_date).diff(
          dayjs(this.filters.start_date),
          "day",
        ),
      );

      // This will hide the 'daily' interval option if the time period is greater than 90 days
      if (daysBetween > 90) {
        const intervalsWithoutDaily = { ...this.intervals };
        delete intervalsWithoutDaily["d"];

        this.intervals = intervalsWithoutDaily;

        // If the 'daily' interval is already selected when the user changes a filter,
        // it adjusts the filter to weekly
        if (this.selectedInterval == "d") {
          this.selectedInterval = "w";
          this.setFilter("interval", "w");
        }
      } else {
        // This will revert the filters back to their original state if it's within the time constraints.
        this.intervals = intervals;
      }
    },
  };
}

import { toSentence } from "../../shared/utils";

export function userCsvUpload(args) {
  const { requireEmailFields, nonImportableTagGroups } = args;

  return {
    errors: null,
    success: false,
    requireEmailFields,
    filename: null,
    removeFile() {
      document.getElementById("user-csv-upload-input").value = null;

      this.filename = null;
      this.errors = null;
      this.success = false;
    },
    validateFile(e) {
      // Reset the values
      this.errors = null;
      this.success = false;

      const file = e.target.files[0];
      this.filename = file.name;

      if (!/\.csv$/.test(file.name)) {
        this.errors = "File name must be .csv";
        return;
      }

      const reader = new FileReader();
      reader.readAsText(file.slice(0, 1024)); // Reduce the amount of data to 1024 bytes to be read in to focus only on headers
      reader.onload = async function (event) {
        const missingFields = [];
        const csvData = event.target.result;
        const Papa = await import("papaparse");
        const headers = Papa.parse(csvData, { header: true, preview: 1 }).meta
          .fields;

        if (!headers.includes("email")) {
          missingFields.push("email");
        }
        if (!headers.includes("manageremail")) {
          missingFields.push("manageremail");
        }

        if (this.requireEmailFields) {
          if (!headers.includes("name")) {
            missingFields.push("name");
          }
          if (!headers.includes("managername")) {
            missingFields.push("managername");
          }
        }

        let nonImportableTagGroupColumns = [];
        for (const header of headers) {
          const tagGroupIdentifier = "tag:";
          if (header.includes(tagGroupIdentifier)) {
            const tagGroup = header
              .replace(tagGroupIdentifier, "")
              .toLowerCase();
            if (nonImportableTagGroups.includes(tagGroup)) {
              nonImportableTagGroupColumns.push(header);
            }
          }
        }

        if (missingFields.length > 0) {
          this.$nextTick(() => {
            this.errors = `Missing ${toSentence(missingFields)}.`;
          });
        } else if (nonImportableTagGroupColumns.length > 0) {
          this.$nextTick(() => {
            this.errors = `Cannot import columns: ${toSentence(
              nonImportableTagGroupColumns,
            )}. These tags are set during the survey.`;
          });
        } else {
          this.$nextTick(() => {
            this.success = true;
          });
        }
      }.bind(this);
    },
  };
}

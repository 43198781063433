import Sortable from "sortablejs";
import { pushNotification } from "../pushNotification";
import { addCurrentPageEventListener } from "../../shared/utils";

export function intercomStudyForm(args) {
  const {
    templates,
    prompt,
    userSentReminderText,
    personalMessageText,
    questions,
  } = args;

  const initialQuestions = JSON.stringify(questions) == "{}" ? [] : questions;

  return {
    templates,
    prompt,
    userSentReminderText,
    personalMessageText,
    questions: initialQuestions,
    drivenBy: args.drivenBy,
    interviewerChangedToMe: args.interviewerChangedToMe,
    canChangeInterviewer: args.canChangeInterviewer,
    questionModalOpen: false,
    editingQuestionIdx: null,
    questionFormData: {},
    startFromScratch: false,
    copyExistingSurveyOpen: false,
    stagedSurveyToCopy: null,
    init() {
      this.initSortable("question-sortable-list");

      // Make sure prequalifier is at idx 0 so that sorting the other questions works
      this.questions = [this.prequalifierQuestion]
        .concat(this.nonPrequalifierQuestions)
        .filter((q) => !!q);

      const form = this.$root.closest("form");

      form.dispatchEvent(
        new CustomEvent("dx:input-init", {
          detail: { name: "intercomStudyForm", values: this.questions },
        }),
      );
      form.dispatchEvent(
        new CustomEvent("dx:input-init", {
          detail: {
            name: "interviewerChangedToMe",
            values: this.interviewerChangedToMe,
          },
        }),
      );
      form.addEventListener("submit", (e) => {
        if (!this.isValid) {
          e.preventDefault();
          e.stopPropagation();
        }
      });

      this.$watch("questions", (value) => {
        if (JSON.stringify(value) != JSON.stringify(initialQuestions)) {
          const event = new CustomEvent("dx:input-changed", {
            detail: { name: "intercomStudyForm", values: value },
          });
          form.dispatchEvent(event);
        }
      });

      // Block submitting parent form via enter, in case user hits Enter with the question modal open
      form.addEventListener("keydown", function (e) {
        if (e.key === "Enter" && e.target.tagName !== "TEXTAREA") {
          e.preventDefault();
        }
      });

      addCurrentPageEventListener("dx:copy-existing-survey-questions", (e) => {
        this.questions = e.detail.questions;
        this.copyExistingSurveyOpen = false;
      });
    },
    loadTemplate(template) {
      this.questions = JSON.parse(templates[template]);
    },
    addQuestion(questionType) {
      this.editingQuestionIdx = null;

      this.questionFormData = {
        type: questionType,
        optional: false,
        options: [],
      };

      if (questionType === "multiple_choice") {
        this.questionFormData.options = [
          { label: "Option A" },
          { label: "Option B" },
        ];
      } else if (questionType === "rating_scale") {
        this.questionFormData.options = [
          { label: "Bad" },
          { label: "So-so" },
          { label: "Good" },
        ];
      } else if (questionType === "prequalifier") {
        this.questionFormData.options = [
          { label: "Yes", proceed: true },
          { label: "No" },
        ];
      } else if (questionType === "multi_select") {
        this.questionFormData.optional = true;
        this.questionFormData.options = [
          { label: "Option A" },
          { label: "Option B" },
        ];
      }

      this.questionModalOpen = true;

      this.$nextTick(() => {
        this.initSortable("modal-sortable-list", this.questionFormData.options);
      });
    },
    get isValid() {
      if (
        this.drivenBy != "MicrosoftBot" &&
        window.currentAccount.integration == "msteams"
      )
        return false;

      return (
        !!this.prompt.trim() &&
        this.questions.length <= 12 &&
        (this.drivenBy == "User" || this.questions.length >= 1) // Questions required when sending from bot
      );
    },
    removeQuestion(idx) {
      this.questions.splice(idx, 1);
      this.startFromScratch = false; // Allow a user to get back to empty state if all questions are removed
    },
    editQuestion(question, idx) {
      this.questionFormData = JSON.parse(JSON.stringify(question)); // Deep clone question so we don't mutate object
      this.editingQuestionIdx = idx;
      this.questionModalOpen = true;

      this.$nextTick(() => {
        this.initSortable("modal-sortable-list", this.questionFormData.options);
      });
    },
    sendTest() {
      fetch(args.endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.study),
      });

      pushNotification({
        title: "Success!",
        desc: `Check ${args.integrationName} for a test message.`,
      });
    },
    get prequalifierQuestion() {
      return this.questions.find((q) => q.type === "prequalifier");
    },
    get nonPrequalifierQuestions() {
      return this.questions.filter((q) => q.type !== "prequalifier");
    },
    toggleInterviewer() {
      this.interviewerChangedToMe = !this.interviewerChangedToMe;
      const form = this.$root.closest("form");
      form.dispatchEvent(
        new CustomEvent("dx:input-changed", {
          detail: {
            name: "interviewerChangedToMe",
            values: this.interviewerChangedToMe,
          },
        }),
      );
    },

    // Stuff for question modal
    get editing() {
      return this.editingQuestionIdx !== null;
    },
    handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.editing) {
        this.questions[this.editingQuestionIdx] = this.questionFormData;
      } else {
        if (this.questionFormData.type === "prequalifier") {
          this.questions.unshift(this.questionFormData);
        } else {
          this.questions.push(this.questionFormData);
        }
      }

      this.closeQuestionFormModal();
    },
    closeQuestionFormModal() {
      this.questionModalOpen = false;
      this.questionFormData = {};
      this.editingQuestionIdx = null;
    },
    isDuplicate(label) {
      const { options } = this.questionFormData;

      if (!options) return;

      return options.filter((o) => o.label === label).length > 1;
    },
    addOption() {
      const { options } = this.questionFormData;
      options.push({ label: "" });

      this.$nextTick(() => {
        this.$refs.newOption.focus();
      });
    },
    removeOption(idx) {
      const { options } = this.questionFormData;
      options.splice(idx, 1);
    },
    get questionModalIsValid() {
      const { options, label, type } = this.questionFormData;

      let validOptions = true;
      if (["multiple_choice", "rating_scale", "prequalifier"].includes(type)) {
        validOptions =
          options.length >= 2 &&
          options.every((i) => {
            return !!i.label?.trim()?.length;
          }) &&
          !options.some((item) => this.isDuplicate(item.label));
      }

      return !!label?.trim()?.length && validOptions;
    },

    // This handles sorting for the list of questions AND list of options when editing a question
    reorderArray(array, order) {
      const newArray = new Array(array.length);
      order.forEach((newIndex, originalIndex) => {
        newArray[newIndex] = array[originalIndex];
      });

      return newArray;
    },
    initSortable(listId) {
      const self = this;
      const sortableList = document.getElementById(listId);

      if (!sortableList) return;

      const editableContents =
        sortableList.querySelectorAll(".editable-content");

      new Sortable(sortableList, {
        animation: 150,
        handle: ".handle",
        filter: ".prequalifier",
        onStart() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "false"),
          );
        },
        onMove(e) {
          return e.related.className.indexOf("prequalifier") === -1;
        },
        onEnd() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "true"),
          );

          const order = Array.from(
            sortableList.querySelectorAll(".sortable-item"),
          )
            .map((item) => item.dataset.idx)
            .map(Number);

          const toRemove = sortableList.querySelectorAll(".sortable-item");

          // Remove dom elements
          toRemove.forEach((item) => sortableList.removeChild(item));

          // Add dom elements
          const toAdd = self.reorderArray(toRemove, order);
          toAdd.forEach((item) => sortableList.appendChild(item));

          // Update component data
          if (listId == "modal-sortable-list") {
            self.questionFormData.options = order.map(
              (idx) => self.questionFormData.options[idx],
            );
          } else {
            self.questions = order.map((idx) => self.questions[idx]);
          }
        },
      });
    },
    submitCopyExistingSurvey() {
      console.log(this.stagedSurveyToCopy);
    },
  };
}

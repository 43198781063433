import { sortableHeaderHtml } from "./utils";

export function frontlineContributorStatus(groupId, groupMembers, statusData) {
  return {
    groupMembers,
    statusData,
    sort: "name",
    sortDir: "asc",
    detailsOpen: false,
    detailsLoaded: false,
    detailsUser: null,
    lastMergedPRs: [],
    openPRs: [],
    init() {
      this.applySort();
    },
    resetDetails() {
      this.detailsUser = null;
      this.detailsLoaded = false;
      this.lastMergedPRs = [];
      this.openPRs = [];
    },
    openDetails(user) {
      this.resetDetails();
      this.detailsUser = user;
      this.detailsOpen = true;

      fetch(`/frontline/groups/${groupId}/users/${user.id}`)
        .then((response) => response.json())
        .then((resp) => {
          resp.results.forEach((pr) => {
            if (pr.status === "Merged") {
              this.lastMergedPRs.push(pr);
            } else {
              this.openPRs.push(pr);
            }
          });
          this.detailsLoaded = true;
        });
    },
    changeSort(column, defaultDir = "desc") {
      if (column !== this.sort) {
        this.sort = column;
        this.sortDir = defaultDir;
      } else {
        this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
      }

      this.applySort();
    },
    sortableHtml(column) {
      const isCurrentSort = column === this.sort;
      const isAsc = this.sortDir === "asc";

      return sortableHeaderHtml(isCurrentSort, isAsc);
    },
    applySort() {
      let list = this.groupMembers;

      if (this.sort === "name")
        list = list.sort((a, b) => a.name.localeCompare(b.name));
      if (this.sort === "open")
        list = list.sort(
          (a, b) =>
            (this.statusData[a.id]?.num_open || 0) -
            (this.statusData[b.id]?.num_open || 0),
        );
      if (this.sort === "merge")
        list = list.sort(
          (a, b) =>
            new Date(this.statusData[a.id]?.last_merged || 0) -
            new Date(this.statusData[b.id]?.last_merged || 0),
        );

      if (this.sortDir === "desc") list = list.reverse();
      this.groupMembers = list;
    },
  };
}

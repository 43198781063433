import {
  addCurrentPageEventListener,
  objectToQueryString,
  fetchWithRetry,
} from "../../shared/utils";

export function customSelect(args) {
  const {
    options,
    updateOptionsEvent,
    updateOptionsEndpoint,
    getFilters = () => null,
    onChange,
    selectedId,
  } = args;

  let optionGroup = false;

  return {
    options,
    selectedOption: null,
    searchResults: options,
    expanded: false,
    init() {
      optionGroup = !!this.options.find((option) => option.is_group_label);

      if (selectedId) {
        this.selectedOption =
          this.options.find((option) => option.id == selectedId) || null;
      }

      if (updateOptionsEvent) {
        addCurrentPageEventListener(updateOptionsEvent, () => {
          this.updateOptions();
        });
      }
    },
    updateOptions() {
      const filtersQueryString = objectToQueryString(getFilters());

      // API call to endpoint that must return JSON with `options` array. i.e. {"options": ["one", "two"]}
      fetchWithRetry(`${updateOptionsEndpoint}${"?" + filtersQueryString}`)
        .then((res) => res.json())
        .then((data) => {
          const newOptions = data.options.map((optionName) => ({
            id: optionName,
            name: optionName,
          }));
          this.options = newOptions;
          this.searchResults = newOptions;
        });
    },
    toggleDropdown() {
      if (this.expanded) {
        this.unfocus();
      } else {
        this.focus();
      }
    },
    get hasOptionGroups() {
      return !!optionGroup;
    },
    focus() {
      this.expanded = true;
      this.$nextTick(() => {
        this.$refs.searchInput?.focus();
      });
    },
    unfocus() {
      this.expanded = false;

      if (this.$refs.searchInput) {
        this.$refs.searchInput.value = "";
        this.$refs.searchInput.blur();
      }

      this.searchResults = this.options;
    },
    select(option) {
      this.selectedOption = option;

      this.unfocus();

      this.$nextTick(() => {
        onChange(this.$root, option.id);
      });
    },
    clear() {
      this.selectedOption = null;
      this.searchResults = this.options;

      this.unfocus();

      this.$nextTick(() => {
        onChange(this.$root, null);
      });
    },
    search() {
      var searchTerm = this.$refs.searchInput?.value;

      if (searchTerm.length > 0) {
        this.searchResults = this.options.filter((option) =>
          option.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
      } else {
        this.searchResults = this.options;
      }
    },
  };
}

export function internalTool(args) {
  const {
    deleteConfirmText,
    usersUploaded,
    emailList,
    showCsvModal,
    internalToolName,
    internalToolPrompt,
    showDeleteModal,
    targetAudience,
    configuredValue,
    targetRole,
    placeholder,
    selectedOption,
    excludeNaResponders,
    takenNames,
    platformXProjectId,
  } = args;

  return {
    csv: null,
    deleteConfirmText,
    usersUploaded,
    emailList,
    showCsvModal,
    internalToolName,
    internalToolPrompt,
    showDeleteModal,
    targetAudience,
    targetRole,
    placeholder,
    selectedOption,
    excludeNaResponders,
    platformXProjectId,
    setRadioButtons() {
      if (this.targetAudience != configuredValue) {
        this.targetAudience = configuredValue;
      }
    },
    isSelected(option) {
      return option == this.targetAudience;
    },
    isDuplicateName() {
      return (
        !!this.internalToolName &&
        takenNames.find((n) => n.trim() === this.internalToolName.trim())
      );
    },
    formValid() {
      if (
        this.internalToolName &&
        !!this.internalToolName.trim().length &&
        !this.isDuplicateName() &&
        this.internalToolPrompt
      ) {
        if (this.targetAudience === "platformx") {
          return (
            this.platformXProjectId !== "0" && this.platformXProjectId !== null
          );
        } else if (this.targetAudience === "custom") {
          return this.usersUploaded;
        }
        return true;
      } else {
        return false;
      }
    },
  };
}

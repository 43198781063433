import { setLocalStorageItem } from "../../shared/utils";

export function insightsTeamFilter() {
  return {
    init() {
      this.$el.value =
        localStorage.getItem("trendCompareTo") || "previous_mark";
    },
    onChange(e) {
      const compareToEvent = new CustomEvent("dx:compareTo-change", {
        detail: { benchmark: e.target.value },
      });
      document.dispatchEvent(compareToEvent);
      setLocalStorageItem("trendCompareTo", e.target.value);
    },
  };
}

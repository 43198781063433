export function atlasEditor(path) {
  return {
    previewContent: "",
    previewOpen: false,
    openPreview() {
      const params = new URLSearchParams();
      [...document.forms["editor-form"].elements].forEach((field) => {
        const isIgnored =
          !field.name ||
          field.type == "submit" ||
          field.type == "button" ||
          field.type == "hidden";
        if (isIgnored) return;
        params.append(field.name, field.value);
      });

      fetch(path, {
        method: "POST",
        body: params,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => response.text())
        .then((data) => {
          this.previewContent = data;
          this.previewOpen = true;
        });
    },
  };
}

import { ScatterChart } from "@get-dx/d3-charts";
import { setQueryStringParam } from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

export function scatterplotBuilder(args) {
  const { xAxisId, yAxisId, xOptions, yOptions, compareEndpoint } = args;

  let chart;

  return {
    xAxisId,
    yAxisId,
    hasData: true,
    initialLoad: true,
    init() {
      setQueryStringParam("x", xAxisId);
      setQueryStringParam("y", yAxisId);
    },
    setXAxis(value) {
      this.xAxisId = value;
      setQueryStringParam("x", value);
    },
    setYAxis(value) {
      this.yAxisId = value;
      setQueryStringParam("y", value);
    },
    selectedOption(axis) {
      if (axis === "x")
        return xOptions.find((option) => option.id === this.xAxisId);

      return yOptions.find((option) => option.id === this.yAxisId);
    },
    label(axis) {
      const measurement_label = this.selectedOption(axis).measurement_label;
      let label = this.selectedOption(axis).name;

      if (measurement_label) label += ` (${measurement_label.trimStart()})`;

      return label;
    },
    renderChart() {
      if (!this.xAxisId || !this.yAxisId) return;

      fetch(`${compareEndpoint}?x=${this.xAxisId}&y=${this.yAxisId}`)
        .then((response) => response.json())
        .then((data) => {
          const selectedXOption = this.selectedOption("x");
          const selectedYOption = this.selectedOption("y");

          let xMax = selectedXOption.max_value || 0;
          let yMax = selectedYOption.max_value || 0;

          const xMeasurementLabel = selectedXOption.measurement_label
            ? selectedXOption.measurement_label
            : "";
          const yMeasurementLabel = selectedYOption.measurement_label
            ? selectedYOption.measurement_label
            : "";

          data.forEach((datapoint) => {
            if (xMax < datapoint.x) xMax = datapoint.x;
            if (yMax < datapoint.y) yMax = datapoint.y;
          });

          if (chart) {
            // Reset the max values
            chart.axis = {
              x: {
                tooltipLabel: selectedXOption.name,
                min: 0,
                max: xMax,
              },
              y: {
                tooltipLabel: selectedYOption.name,
                min: 0,
                max: yMeasurementLabel == "%" ? 100 : yMax,
              },
            };
            chart.values = data;
            // Since the measurement label is statically passed in (as it's interpolated), reset the tooltip
            (chart.tooltipHtml = chartTooltipHtml.scatterplot({
              xMeasurementLabel,
              yMeasurementLabel,
            })),
              chart.redraw();
          } else {
            const el = document.getElementById("scatterplot-builder");
            if (!el) return;

            chart = new ScatterChart({
              elChart: el,
              axis: {
                x: {
                  tooltipLabel: selectedXOption.name,
                  min: 0,
                  max: xMax,
                },
                y: {
                  tooltipLabel: selectedYOption.name,
                  min: 0,
                  max: yMeasurementLabel == "%" ? 100 : yMax,
                },
              },
              values: data,
              showTrendline: true,
              tooltipHtml: chartTooltipHtml.scatterplot({
                xMeasurementLabel,
                yMeasurementLabel,
              }),
            });

            chart.redraw();
          }

          this.initialLoad = false;

          // This needs to go after the chart is drawn because if the initial chart has no data the redraw needs to have
          // some widths on the rect to properly work
          if (data.length == 0) {
            this.hasData = false;
            return;
          } else {
            this.hasData = true;
          }
        });
    },
  };
}

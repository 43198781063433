import {
  getQueryStringParams,
  deleteQueryStringParam,
} from "../../shared/utils";

export const triageSquadManagerStore = {
  setInitialState({ incompleteSquads }) {
    this.incompleteSquads = incompleteSquads;
  },
  numIncomplete() {
    return Object.keys(this.incompleteSquads).length;
  },
  openModalAutomatically() {
    return getQueryStringParams().get("triage") == "1";
  },
  clearOpenAutomaticallyParam() {
    deleteQueryStringParam("triage");
  },
};

export function snapshotIntakeInternalToolGroups(args) {
  const { selections, groups, maxSelections, naSelected } = args;

  return {
    selectionsMap: {},
    naSelected,
    maxSelections,
    init() {
      selections.forEach((id) => {
        if (groups.find((g) => g.id === id)) {
          this.selectionsMap[id] = true;
        }
      });
    },
    get numSelections() {
      return Object.values(this.selectionsMap).filter((s) => s).length;
    },
    get atMaxSelections() {
      return maxSelections == this.numSelections;
    },
    formValid() {
      return this.numSelections >= 1 || this.naSelected;
    },
    toggleSelection(groupId) {
      if (this.selectionsMap[groupId]) {
        delete this.selectionsMap[groupId];
      } else {
        this.selectionsMap[groupId] = true;
        this.naSelected = false;
      }
    },
    isSelected(groupId) {
      return this.selectionsMap[groupId];
    },
    toggleNa() {
      if (!this.naSelected) {
        this.selectionsMap = {};
        this.naSelected = true;
      } else {
        this.naSelected = false;
      }
    },
  };
}

import {
  addCurrentPageEventListener,
  outOfView,
  prevKeys,
  nextKeys,
} from "../../shared/utils";

export function userSelect(args) {
  const { selectedUser, excludedIds, onChange, listenFor } = args;

  return {
    selectedUser,
    focusedUser: null,
    focused: false,
    searchResults: [],
    searchResultsLoaded: false,
    init() {
      if (listenFor) {
        addCurrentPageEventListener(listenFor, (event) => {
          this.setSelected(event.detail);
        });
      }
    },
    focus() {
      this.focusedUser = null;
      this.focused = true;

      const input = this.$refs.searchInput;

      this.$nextTick(() => {
        input.focus();
      });

      window.userSearch.search("", excludedIds).then((results) => {
        if (!results) return;

        this.searchResults = results.slice(0, 4);

        this.searchResultsLoaded = true;
      });
    },
    unfocus() {
      this.searchResultsLoaded = false;
      this.focused = false;
      this.$refs.searchInput.value = "";
      this.$refs.searchInput.blur();
    },
    select(user) {
      this.selectedUser = user;

      this.unfocus();

      this.$nextTick(() => {
        onChange(this.$root, user);
      });
    },
    setSelected(user) {
      this.selectedUser = user;
    },
    clear() {
      this.selectedUser = null;

      this.unfocus();

      this.$nextTick(() => {
        onChange(this.$el, null);
      });
    },
    search() {
      this.focusedUser = null;

      const searchTerm = this.$refs.searchInput.value;

      window.userSearch.search(searchTerm, excludedIds).then((results) => {
        results = results.sort((a, b) =>
          a.name.length > b.name.length
            ? 1
            : b.name.length > a.name.length
              ? -1
              : 0,
        );

        if (searchTerm.length > 0) {
          this.searchResults = results.slice(0, 25);

          if (this.searchResults.length > 0) {
            this.focusedUser = this.searchResults[0];
          }
        } else {
          this.searchResults = results.slice(0, 4);
        }
      });
    },
    handleInputKeydown(event) {
      if (event.key == "Enter") {
        event.preventDefault();

        if (this.focusedUser) this.select(this.focusedUser);

        return;
      }

      if (nextKeys.includes(event.key) && this.searchResultsLoaded) {
        event.preventDefault();

        if (this.focusedUser == null) {
          this.focusedUser = this.searchResults[0];
        } else {
          const index = this.searchResults.findIndex((user) => {
            return user.id == this.focusedUser.id;
          });

          const next = this.searchResults[index + 1];

          if (next) {
            this.focusedUser = next;

            const ul = this.$refs.searchResultsContainer;
            const li = ul.querySelector(`[data-userId="${next.id}"]`);

            if (outOfView(ul, li)) {
              ul.scrollTo(0, li.offsetTop);
            }
          }
        }

        return;
      }

      if (prevKeys.includes(event.key) && this.searchResultsLoaded) {
        event.preventDefault();

        if (this.focusedUser) {
          const index = this.searchResults.findIndex((user) => {
            return user.id == this.focusedUser.id;
          });

          const prev = this.searchResults[index - 1];

          if (prev) {
            this.focusedUser = prev;

            const ul = this.$refs.searchResultsContainer;
            const li = ul.querySelector(`[data-userId="${prev.id}"]`);

            ul.scrollTo(0, li.offsetTop - li.clientHeight);
          }
        }
      }

      if (event.keyCode == 9) {
        event.preventDefault();
      }
    },
  };
}

export function groupedMultiselect(args) {
  const { onInit, data, onChange, label, open = false } = args;

  const allRootIds = new Set(
    data.flatMap((d) => d.items.map((item) => item.value)),
  );
  const selectedValues = new Set(
    args.selectedIds.filter((id) => allRootIds.has(id)),
  );

  let previousSelectedValues = new Set(selectedValues);

  const setsAreEqual = (setA, setB) => {
    if (setA.size !== setB.size) return false;
    for (let value of setA) {
      if (!setB.has(value)) return false;
    }
    return true;
  };

  return {
    searchText: "",
    debouncedSearchText: "",
    open,
    filterSelected: false,
    selectedValues,
    group: null,
    data,
    label,

    init() {
      this.$watch("searchText", (value) => {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.debouncedSearchText = value;
        }, 300);
      });
      onInit?.bind(this)();
    },

    handleClose() {
      this.open = false;
      this.group = null;

      if (!setsAreEqual(this.selectedValues, previousSelectedValues)) {
        previousSelectedValues = new Set(this.selectedValues);
        // Defer the call to onChange to ensure the DOM is updated first
        this.$nextTick(() => {
          onChange(this.$root, Array.from(this.selectedValues));
        });
      }
    },

    handleClear(e) {
      e.stopPropagation();
      this.selectedValues.clear();
      this.filterSelected = false;

      if (!setsAreEqual(this.selectedValues, previousSelectedValues)) {
        previousSelectedValues = new Set(this.selectedValues);
        this.$nextTick(() => {
          onChange(this.$root, []);
        });
      }
    },

    handleSelect(selectedValue) {
      if (this.selectedValues.has(selectedValue)) {
        this.selectedValues.delete(selectedValue);
      } else {
        this.selectedValues.add(selectedValue);
      }
    },

    handleSelectGroup(selectedValue) {
      this.group = selectedValue;
      this.searchText = "";
    },

    handleSelectAll() {
      const groupItems =
        this.data.find((d) => d.group === this.group)?.items || [];
      const filteredItems = groupItems.filter((item) => {
        const matchesSearch = item.label
          .toLowerCase()
          .includes(this.debouncedSearchText.toLowerCase());
        return matchesSearch;
      });
      filteredItems.forEach((item) => this.selectedValues.add(item.value));
    },

    countSelectedItemsPerGroup(groupValue) {
      const groupItems =
        this.data.find((d) => d.group === groupValue)?.items || [];
      return groupItems.filter((item) => this.selectedValues.has(item.value))
        .length;
    },

    get filteredItems() {
      const groupItems =
        this.data.find((d) => d.group === this.group)?.items || [];
      return groupItems.filter((item) => {
        const matchesSearch = item.label
          .toLowerCase()
          .includes(this.debouncedSearchText.toLowerCase());
        const matchesFilter =
          !this.filterSelected || this.selectedValues.has(item.value);
        return matchesSearch && matchesFilter;
      });
    },

    renderSelectedValues() {
      return this.selectedValues.size === 0 ? "All" : this.selectedValues.size;
    },
  };
}

export function snapshotIntakeResearch(args) {
  const { values } = args;

  return {
    values,
    focusedKey: null,
    init() {
      this.focusNextRow();

      this.$watch("values", () => this.focusNextRow());
    },
    focusNextRow() {
      this.$nextTick(() => {
        const nextRow = document.querySelector(".x-unfilled");

        if (nextRow) this.focusedKey = nextRow.dataset.key;
      });
    },
    formValid() {
      for (const [_, value] of Object.entries(this.values)) {
        if (value === null) return false;
      }

      return true;
    },
    isFilled(key) {
      return (
        this.values[key] !== null &&
        this.values[key] !== undefined &&
        this.values[key] !== ""
      );
    },
    isFilledOrFocused(key) {
      return this.isFilled(key) || key == this.focusedKey;
    },
  };
}

export function defaultNudgeMsg(target, pastDeadline, slack) {
  if (target === "parent_dri") {
    if (pastDeadline) {
      return `Hey ${
        slack ? "<@RECIPIENT>" : "there"
      }, the snapshot is about to be closed and there are teams that you're over with individuals that haven't filled it out yet. Could you follow up with the team leads to make sure folks respond ASAP?`;
    }

    return `Hey ${
      slack ? "<@RECIPIENT>" : "there"
    }, it looks like there are teams that you're over with individuals that haven't filled it out yet. Could you follow up with the team leads to make sure folks respond?`;
  }

  if (target === "leaf_dri") {
    if (pastDeadline) {
      return `Hey ${
        slack ? "<@RECIPIENT>" : "there"
      }, the snapshot is about to be closed and there are folks from your team that haven't filled it out yet. Could you follow up to make sure folks respond ASAP?`;
    }

    return `Hey ${
      slack ? "<@RECIPIENT>" : "there"
    }, it looks like there are folks from your team that haven't filled out the snapshot yet. Could you follow up with folks to make sure they respond?`;
  }

  if (target === "contributor") {
    if (pastDeadline) {
      return `Hey ${
        slack ? "<@RECIPIENT>" : "there"
      }, we're trying to close the snapshot and it looks like you haven't filled it out yet. Could you fill it out when you have a minute?`;
    }

    return `Hey ${
      slack ? "<@RECIPIENT>" : "there"
    }, just wanted to reach out because it looks like you haven't filled out the snapshot yet. Could you fill it out when you have a minute?`;
  }

  if (target === "triage_dri") {
    return `Hey ${
      slack ? "<@RECIPIENT>" : "there"
    }, I wanted to reach out because it looks like you haven't triaged your team's snapshot results yet.\n\nThis helps close the feedback loop and tells us where additional support is needed. Could you fill it out when you have a minute?`;
  }

  return "";
}

export function bulkNudge(args) {
  const {
    selectExpanded,
    targetGroupSelected,
    snapshotPastDeadline,
    usesSlack,
  } = args;

  return {
    selectExpanded,
    targetGroupSelected,
    snapshotPastDeadline,
    slackPreviewButtonText: null,
    messageChanged: false,
    noSelectedOptions() {
      return !this.targetGroupSelected;
    },
    isSelected(targetGroup) {
      return targetGroup == this.targetGroupSelected;
    },
    select(targetGroup) {
      this.targetGroupSelected = targetGroup;
      this.setDefaultValue();
      this.selectExpanded = false;
    },
    get slackMessageTextArea() {
      return document.getElementsByTagName("textarea")[0];
    },
    setDefaultValue() {
      if (this.messageChanged) return;

      this.slackMessageTextArea.value = defaultNudgeMsg(
        this.targetGroupSelected,
        this.snapshotPastDeadline,
        usesSlack,
      );
    },
  };
}

import { objectToQueryString } from "../../shared/utils";

export function tagUsersTable(args) {
  const {
    endpoint,
    sortColumn,
    sortDirection = "asc",
    tagGroupId,
    tagId,
  } = args;

  return {
    showDataTable: false,
    sortColumn,
    sortDirection,
    page: 1,
    tagGroupId,
    tagId,
    dataTable: {},
    initialPageLoaded: false,
    loading: false,

    showAndFetch() {
      this.showDataTable = true;
      this.page = 1; // if we don't reset page we can end up with a blank page
      this.fetchRawData();
    },
    fetchRawData() {
      if (this.loading) return;

      this.loading = true;

      const queryString = objectToQueryString({
        page: this.page,
        tag_group_id: this.tagGroupId,
        tag_id: this.tagId,
        s: this.sortColumn,
        d: this.sortDirection,
      });

      fetch(`${endpoint}?${queryString}`)
        .then((response) => {
          this.loading = false;
          this.initialPageLoaded = true;
          if (response.ok) return response.json();

          alert("Something went wrong. Please reload the page and try again.");
        })
        .then((data) => {
          this.dataTable = data;
        })
        .catch(() => {
          alert(
            "Your connection dropped. Please reload the page and try again.",
          );
        });
    },
    sortData(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortDirection = "asc";
      }
      this.fetchRawData();
    },
    changePage(page) {
      this.page = page;
      this.fetchRawData();
    },
    range(start, end) {
      // add support for 1 based arrays for easier pagination
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
  };
}

import { eqArrays } from "../../shared/utils";

export function multiselectV2(args) {
  const { label, includeSearch, options, onChange, onToggle } = args;

  const allRootIds = new Set(options.map((o) => o.id));
  const selectedIds = new Set(
    args.selectedIds.filter((id) => allRootIds.has(id)),
  );

  let _valueAtOpen;

  return {
    searchResults: [],
    selectedIds,
    includeSearch,
    label,
    expanded: false,
    get selectedIdsArr() {
      return Array.from(this.selectedIds);
    },
    focus() {
      this.searchResults = options;

      this.expanded = true;

      this.$nextTick(() => {
        if (this.$refs.searchInput) this.$refs.searchInput.focus();
      });

      _valueAtOpen = this.selectedIdsArr;
    },
    toggleDropdown() {
      if (this.expanded) {
        return this.unfocus();
      }

      this.focus();
    },
    unfocus() {
      if (!this.expanded) return;

      this.expanded = false;

      const valueNow = this.selectedIdsArr;
      const changed = !eqArrays(_valueAtOpen, valueNow);

      this.$nextTick(() => {
        if (changed) {
          onChange(this.$root, Array.from(valueNow));
        }

        if (this.$refs.searchInput) {
          this.$refs.searchInput.value = "";
          this.$refs.searchInput.blur();
        }
      });
    },
    isSelected(id) {
      return this.selectedIds.has(id);
    },
    toggleSelectTag(id) {
      if (this.isSelected(id)) {
        this.selectedIds.delete(id);
      } else {
        this.selectedIds.add(id);
      }

      onToggle(this.$root, Array.from(this.selectedIds));
    },
    deselectAll() {
      this.selectedIds.clear();

      onChange(this.$root, Array.from(this.selectedIds));

      this.expanded = false;
    },
    search() {
      const searchTerm = this.$refs.searchInput.value;

      if (searchTerm.length > 0) {
        let tag_group_name = "";
        const matchingResult = options.reverse().filter((option) => {
          const isNonTagGroupNameMatch =
            option.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !option.is_group_label;
          const isTagGroupOfMatch =
            option.is_group_label && option.name === tag_group_name;

          if (isNonTagGroupNameMatch || isTagGroupOfMatch) {
            tag_group_name = option.tag_group_name;
            return true;
          } else {
            return false;
          }
        });
        options.reverse();
        this.searchResults = matchingResult.reverse();

        return;
      }

      this.searchResults = options;
    },
  };
}

const POSITIONS = {
  top: "top",
  top_left: "top-left",
  top_right: "top-right",
  bottom: "bottom",
  bottom_left: "bottom-left",
  bottom_right: "bottom-right",
};

export function pushNotification({
  title,
  desc,
  success = true,
  position = POSITIONS.top_right,
  customPositionStyles = [],
  cta,
  ctaLink,
}) {
  // Create the element using the createElement method.
  const div = document.createElement("div");

  const positionClasses = getPosition(position);

  //Add your content to the DIV
  div.innerHTML = `
    <div x-cloak x-data="{hide: true}" x-init='
        setTimeout(() => { hide = false }, 0);
        setTimeout(() => { hide = true }, 3000);
      ' x-show='!hide'
      x-transition:enter="ease-out duration-500 transition"
      x-transition:enter-start="translate-y-8 opacity-0"
      x-transition:enter-end="translate-y-0 opacity-100"
      x-transition:leave="transition ease-in duration-200"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0"
      class="fixed z-50 p-4 ${positionClasses} ${customPositionStyles
        .map((w) => "!" + w)
        .join(
          " ",
        )} w-96 bg-gray-700 shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden flex items-start">
      <div class="-mt-0.5 shrink-0">
        <i class='bx text-lg ${
          success
            ? "bxs-check-circle text-green-400"
            : "bxs-x-circle text-red-500"
        }'></i>
      </div>
      <div class="ml-2.5 flex-1 pt-0.5">
        <p class="text-sm font-medium text-white">
          ${title}
        </p>
        <p class="mt-1 text-tiny text-gray-200">
          ${desc ? desc : ""}
        </p>
        <template x-if="${!!cta && !!ctaLink}">
          <div>
            <a class="button bg-gray-600 px-2 rounded inline-block mt-2 hover:bg-gray-500" href=${ctaLink}>
              <p class="font-bold text-white">${cta}</p>
            </a>
          </div>
        </template>
      </div>
      <div class="ml-4 shrink-0 flex">
        <button type="button" @click='hide = true' class="-mt-1 inline-flex text-gray-400 hover:text-gray-500">
          <i class='bx bx-x text-xl'></i>
        </button>
      </div>
    </div>
  `;

  // Finally, append the element to the HTML body
  document.body.appendChild(div);
}

function getPosition(position) {
  switch (position) {
    case POSITIONS.top:
      // Halfway on the page, subtract half the width of the element
      return "top-6 right-[calc(50%-12rem)]";
    case POSITIONS.top_left:
      return "top-6 left-6";
    case POSITIONS.top_right:
      return "top-6 right-6";
    case POSITIONS.bottom:
      // Halfway on the page, subtract half the width of the element
      return "bottom-6 right-[calc(50%-12rem)]";
    case POSITIONS.bottom_left:
      return "bottom-6 left-6";
    case POSITIONS.bottom_right:
      return "bottom-6 right-6";
    default:
      return "top-6 right-6";
  }
}

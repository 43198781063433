export function studiesQuestionSummary(args) {
  const colors = [
    [164, 140, 243],
    [131, 144, 241],
    [113, 174, 244],
    [102, 202, 239],
    [93, 218, 216],
    [104, 209, 174],
    [106, 208, 131],
    [143, 219, 120],
    [174, 228, 88],
    [241, 213, 49],
    [242, 192, 75],
    [234, 143, 82],
    [232, 117, 117],
    [234, 121, 161],
    [228, 122, 196],
    [230, 134, 235],
    [199, 135, 245],
  ];

  return {
    data: args.data,
    chartElId: args.chartElId,
    chartData: [],
    chartType: "bar",
    changeChartType(type) {
      if (this.chartType === type) return;

      this.chartType = type;

      this.$nextTick(() => {
        if (this.chartType === "pie") {
          this.drawPieChart();
        }
      });
    },
    drawPieChart() {
      const el = document.getElementById(this.chartElId);
      if (!el) return;

      el.innerHTML = null;

      this.chartData = [];

      Object.entries(this.data.items).forEach((item, idx) => {
        if (item[1].pct === 0) return;

        const name = item[0];

        let label = item[0];
        const truncateLabel = 30;
        if (label.length > truncateLabel) {
          label = label.slice(0, truncateLabel - 3) + "...";
        }

        this.chartData.push({
          name,
          label: label,
          value: item[1].pct,
          color: `rgb(${colors[idx].join(",")})`,
        });
      });

      new window.PieChart({
        elChart: el,
        values: this.chartData,
        minAngleForValueLabel: 361, // Don't show value labels
        tooltipHtml: this.chartTooltip.bind(this),
      });
    },
    chartTooltip(d) {
      return `
        <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5' style='min-width: 80px'>
          <div class='flex border-b pb-1.5 mb-1.5'>
            <div class='grow font-medium'>
              ${d.name}
            </div>
          </div>
          ${Math.round(d.value)}%
        </div>
      `;
    },
  };
}

import {
  setLocalStorageItem,
  getWithDigest,
  setWithDigest,
} from "../../shared/utils";

export function squadsTree(args) {
  const {
    snapshotId = null,
    hideLeaves = false,
    virtualRoot = false,
    rootNodes = { ids: null, hide: false },
    storageKey,
  } = args;

  const { squadsDigests, currentUser } = window;

  return {
    squads: [],
    originalSquads: [],
    expandedSquadsIds: {},
    searchTermPresent: false,
    squadsTreeLoaded: false,
    init() {
      // Use setTimeout so localStorage I/O doesn't block page rendering
      setTimeout(() => {
        this.initializeSquads();
        this.setInputEventListeners();
      }, 50);
    },
    initializeSquads() {
      // Use snapshot IDs as keys for snapshot squads, and 'current' for account squads
      const digestKey = snapshotId || "current";

      // Get digest from global squadsDigests object
      const digest = squadsDigests[digestKey];

      // Construct localStorage key
      const cacheKey = `squads_${currentUser.account_id}_${digestKey}_v2`;

      // Try to load squads from localStorage
      const squads = getWithDigest(cacheKey, digest);
      if (squads) return this.boot(squads);

      // Fetch squads from the server
      const params = {};
      if (snapshotId) params.snapshot_id = snapshotId;

      const url = `/squads/tree?${new URLSearchParams(params)}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setWithDigest(cacheKey, data.squads, data.digest);

          this.boot(data.squads);
        });
    },
    setInputEventListeners() {
      const { searchInput } = this.$refs;

      if (searchInput) {
        this.$watch("open", (open) => {
          this.$nextTick(() => {
            if (open) {
              searchInput.value = "";
              this.searchTermPresent = false;
              this.squads = this.originalSquads;
            }
          });
        });

        searchInput.addEventListener("focus", () => {
          this.open = true;
        });
      }
    },
    boot(squads) {
      if (virtualRoot) {
        if (rootNodes.ids) return;

        squads.forEach((squad) => {
          if (!squad.parent_id) {
            squad.parent_id = -1;
          }
        });

        squads.unshift({
          id: -1,
          name: "All teams",
          depth: -1,
          is_parent: true,
          encoded_id: "org",
          expanded: true,
        });
      }

      if (hideLeaves) {
        squads = squads.filter((squad) => squad.is_parent);
      }

      if (rootNodes.ids) {
        squads = filterByRoots(squads, rootNodes);
      }

      // Set isRoot properties and adjust depths to begin at 0
      squads = setRootsAndDepths(squads);

      // Dispatch event for parent components to get the list of squads, i.e., squadMultiSelect
      const event = new CustomEvent("dx:squadsTree:init", {
        bubbles: true,
        detail: squads,
      });
      this.$root.dispatchEvent(event);

      // Set initial state and render squads
      this.squads = squads;
      this.squadsTreeLoaded = true;
      this.setInitialExpandedSquadsIds();
      this.setInitialExpandedSquads();
    },
    setInitialExpandedSquadsIds() {
      const expandedSquadsIds = JSON.parse(
        localStorage.getItem(storageKey) || "{}",
      );

      // Get root squads
      const roots = this.squads.filter((squad) => squad.isRoot);

      // If there is one root node and it is a parent, expand it
      if (roots.length === 1) {
        const squad = roots.find((squad) => squad.is_parent);

        if (squad) {
          expandedSquadsIds[squad.id] = true;
        }
      }

      this.expandedSquadsIds = expandedSquadsIds;
    },
    setInitialExpandedSquads() {
      this.squads.forEach((squad) => {
        if (squad.parent_id) {
          squad.parentExpanded = !!this.expandedSquadsIds[squad.parent_id];
        }

        squad.expanded = !!this.expandedSquadsIds[squad.id];
      });

      this.originalSquads = this.squads;
    },
    toggleSquad(targetSquad) {
      if (this.searchTermPresent) return;

      this.squads.forEach((squad) => {
        if (targetSquad.id === squad.parent_id) {
          squad.parentExpanded = targetSquad.expanded;
          return;
        }

        if (targetSquad.id === squad.id) {
          targetSquad.expanded = squad.expanded = !squad.expanded;

          if (squad.expanded) {
            this.expandedSquadsIds[targetSquad.id] = true;
          } else {
            delete this.expandedSquadsIds[targetSquad.id];
          }
        }
      });

      if (!targetSquad.expanded) {
        this.collapseChildren(targetSquad);
      }

      setLocalStorageItem(storageKey, JSON.stringify(this.expandedSquadsIds));
    },
    collapseChildren(parent) {
      this.squads.forEach((squad) => {
        if (squad.parent_id !== parent.id) return;

        if (squad.is_parent) this.collapseChildren(squad);

        squad.expanded = false;
        squad.parentExpanded = false;
        delete this.expandedSquadsIds[squad.id];
      });
    },
    search(e) {
      const searchTerm = e.target.value;

      this.searchTermPresent = searchTerm.length > 0;

      if (this.searchTermPresent) {
        this.squads = search(this.originalSquads, searchTerm);
        return;
      }

      this.squads = resetSearch(this.originalSquads);
    },
  };
}

function filterByRoots(squads, rootNodes) {
  const set = new Set(rootNodes.ids);

  let rootDepth = null;

  squads = squads.filter((squad) => {
    if (set.has(squad.id)) {
      rootDepth = squad.depth;
      return true;
    }

    if (rootDepth !== null && rootDepth < squad.depth) {
      return true;
    }

    if (rootDepth !== null && rootDepth >= squad.depth) {
      rootDepth = null;
    }

    return false;
  });

  if (rootNodes.hide) {
    if (rootNodes.ids.length > 1) {
      throw new Error("Cannot hide root when there are multiple root nodes");
    }

    squads.shift();
  }

  return squads;
}

function setRootsAndDepths(squads) {
  let rootDepth = null;

  return squads.map((squad) => {
    if (rootDepth === null || rootDepth >= squad.depth) {
      rootDepth = squad.depth;

      squad.isRoot = true;
      squad.depth = 0;
      return squad;
    }

    squad.depth = squad.depth - rootDepth;
    return squad;
  });
}

function resetSearch(squads) {
  squads.forEach((squad) => delete squad.highlightedName);

  return squads;
}

function search(squads, searchTerm) {
  // Start with -1 to include virtual "All Teams" root node
  let previousDepth = -1;

  const matchingResults = squads.reverse().filter((squad) => {
    const matchIndex = squad.name
      .toLowerCase()
      .indexOf(searchTerm.toLowerCase());

    const isParentOfNameMatch =
      previousDepth !== -1 &&
      squad.depth !== previousDepth &&
      squad.depth < previousDepth;

    delete squad.highlightedName;

    if (matchIndex !== -1 || isParentOfNameMatch) {
      previousDepth = squad.depth;

      if (matchIndex !== -1) {
        const match = squad.name.substring(
          matchIndex,
          matchIndex + searchTerm.length,
        );
        squad.highlightedName = squad.name
          .split(match)
          .join(`<span class='search-highlight'>${match}</span>`);
      }

      return true;
    }

    return false;
  });

  // Reset passed-in squads back to their original order
  squads.reverse();

  return matchingResults.reverse();
}

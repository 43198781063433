export function dataFileUpload() {
  return {
    filename: null,
    submittable: false,
    removeFile() {
      document.getElementById("file-input").value = null;
      this.filename = null;
      this.submittable = false;
    },
    handleFile(e) {
      const file = e.target.files[0];
      this.filename = file.name;
      this.submittable = true;
    },
  };
}

import { groupBy } from "../../shared/utils";

export function accountSwitcher(args) {
  const { accounts } = args;
  const parsedAccounts = accounts.map((a) => JSON.parse(a));
  const groupedAccounts = groupBy(parsedAccounts, "parent_account_name");

  return {
    visible: false,
    loading: true,
    accounts: parsedAccounts,
    groupedAccounts,
    showAccountSwitcher() {
      this.visible = true;
    },
    parentAccountNames() {
      return Object.keys(groupedAccounts).filter(
        (parentAccountName) => parentAccountName != "null",
      );
    },
    hideAccountSwitcher() {
      this.visible = false;
    },
    hasParentAccounts() {
      return this.parentAccountNames().length > 0;
    },
    parentAccountId(parentAccountName) {
      return this.groupedAccounts[parentAccountName][0].parent_public_id;
    },
  };
}

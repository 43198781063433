import { LineChart } from "@get-dx/d3-charts";
import {
  setQueryStringParam,
  deleteQueryStringParam,
  objectToQueryString,
} from "../../shared/utils";
import { getQueryParam } from "../../lib/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";
import tippy from "tippy.js";

export function snapshotAttrition({ url, canViewActuals }) {
  return {
    attritionModalOpen: false,
    url,
    attritionTabs: [{ tab: "risk" }],
    attritionData: null,
    breakdownData: null,
    tabDataLoaded: false,
    tableSort: { col: "group_name", dir: "asc" },
    selectedAttritionTab: "",
    selectedAttritionPeriod: "6mo",
    selectedAttritionInterval: "mo",
    init() {
      const result = getQueryParam("attrition");
      const tab = getQueryParam("adt");
      const period = getQueryParam("adp");
      const interval = getQueryParam("adi");

      if (canViewActuals) this.attritionTabs.push({ tab: "actuals" });

      if (period) this.setPeriod(period);
      if (interval) this.setInterval(interval);

      if (result) this.showAttrition();
      if (tab) this.setTab(tab);
      else this.setTab(this.attritionTabs[0]?.tab);

      this.fetchBreakdownData();
      this.fetchAttritionData();

      this.$watch(
        () => this.$store.snapshotResultFilters,
        () => {
          this.fetchBreakdownData();
          this.fetchAttritionData();
        },
      );
    },
    tooltipIfTruncated(e, fullText) {
      const el = e.target;
      el._tippy?.destroy(); // Delete previous instance

      if (el && el.offsetWidth < el.scrollWidth) {
        tippy(el, {
          content: fullText,
          delay: [250, 0],
          onShow: (instance) => {
            tippy.hideAll({ exclude: instance });
          },
        });
      }
    },
    get sortedBreakdown() {
      const list = this.breakdownData || [];
      const isDesc = this.tableSort.dir === "desc";

      const sortByGroupName = (a, b) => {
        return isDesc
          ? b.group_name.localeCompare(a.group_name)
          : a.group_name.localeCompare(b.group_name);
      };

      const sortBySampleSize = (a, b) => {
        const aSize = a.sample_size || 0;
        const bSize = b.sample_size || 0;
        return isDesc ? bSize - aSize : aSize - bSize;
      };

      const sortByRiskPercentage = (a, b) => {
        const aVal =
          a.risk_percentage === "hidden"
            ? Infinity
            : parseFloat(a.risk_percentage);
        const bVal =
          b.risk_percentage === "hidden"
            ? Infinity
            : parseFloat(b.risk_percentage);
        if (aVal === null) return 1;
        if (bVal === null) return -1;
        return isDesc ? bVal - aVal : aVal - bVal;
      };

      if (this.tableSort.col === "group_name") {
        return list.sort(sortByGroupName);
      } else if (this.tableSort.col === "sample_size") {
        return list.sort(sortBySampleSize);
      } else if (this.tableSort.col === "risk_percentage") {
        return list.sort(sortByRiskPercentage);
      }

      return list;
    },
    get groupTitle() {
      return this.sortedBreakdown[0]?.group_title || "Team";
    },
    sortableHeader(column) {
      const isCurrentSort = column === this.tableSort.col;
      const isAsc = this.tableSort.dir === "asc";

      return `
        <div class="text-gray-300 flex flex-col -space-y-1.5 text-xs -mb-px">
          <i class="-mb-px bx bx-caret-up" :class="{'text-gray-500': ${
            isCurrentSort && isAsc
          }}"></i>
          <i class="bx bx-caret-down" :class="{'text-gray-500': ${
            isCurrentSort && !isAsc
          }}"></i>
        </div>
      `;
    },
    showAttrition() {
      this.attritionModalOpen = true;
      setQueryStringParam("attrition", true);
      this.fetchAttritionData();
    },
    hide() {
      deleteQueryStringParam("adt");
      deleteQueryStringParam("adp");
      deleteQueryStringParam("adi");
      deleteQueryStringParam("attrition");
      this.attritionModalOpen = false;
    },
    formatDate(d) {
      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
      }).format(d);
    },
    setTab(tab) {
      this.selectedAttritionTab = tab;
      this.refreshCharts(tab);
      setQueryStringParam("adt", tab);
    },
    setPeriod(period) {
      setQueryStringParam("adp", period);
      this.selectedAttritionPeriod = period;
      if (this.attritionData) this.fetchAttritionData();
    },
    setInterval(interval) {
      setQueryStringParam("adi", interval);
      this.selectedAttritionInterval = interval;
      if (this.attritionData) this.fetchAttritionData();
    },
    fetchAttritionData() {
      this.tabDataLoaded = false;
      setQueryStringParam("adt", this.selectedAttritionTab);
      if (!this.url) return;

      const fullUrl = `${this.url}${this.queryParams()}`;

      return fetch(fullUrl)
        .then((resp) => resp.json())
        .then((data) => {
          this.attritionData = data;
          this.tabDataLoaded = true;
          this.refreshCharts(this.selectedAttritionTab);
        });
    },
    queryParams() {
      const params = {
        p: this.selectedAttritionPeriod,
        i: this.selectedAttritionInterval,
        tag_ids: getQueryParam("tag_ids"),
        squad_ids: getQueryParam("squad_ids"),
        hg: this.$store.snapshotResultFilters.hierarchyGroup.encoded_id,
        atg: getQueryParam("atg"),
      };
      return `?${objectToQueryString(params)}`;
    },
    refreshCharts(tab) {
      if (this.attritionData?.chart_data.length > 0 && tab == "risk") {
        setTimeout(() => this.buildRiskChart(), 50);
      }
      if (this.attritionData?.data?.length > 0 && tab == "actuals") {
        setTimeout(() => this.buildActualsChart(), 50);
      }
    },
    buildRiskChart() {
      const chartData = this.attritionData.chart_data;
      const chartMax = Math.max(...chartData.map((obj) => obj.value)) || 0;

      const elChart = document.getElementById(`chartattr-risk-chart`);
      if (!elChart) return;

      elChart.innerHTML = null;

      if (chartData.length > 1) {
        const chartParams = {
          elChart,
          startDate: chartData[0].date,
          endDate: chartData[chartData.length - 1].date,
          yAxisMin: 0,
          yAxisMax: Math.round(chartMax * 1.2),
          tooltipHtml(d) {
            const params = {
              heading: "At-risk of attrition",
              d: {
                label: d.label,
                value: d.value,
                formattedValue: `${d.value}% of your organization`,
                contributorCount: d.contributor_count,
              },
            };

            return chartTooltipHtml.attritionRisk(params);
          },
          values: chartData,
        };

        new LineChart(chartParams);
      }
    },
    buildActualsChart() {
      const { data } = this.attritionData;
      if (!data) return;
      const chartMax = Math.max(...data.map((obj) => obj.value)) || 0;

      const elChart = document.getElementById(`chartattr-actuals-chart`);
      if (!elChart) return;
      elChart.innerHTML = null;

      const chartParams = {
        elChart,
        startDate: data[0].date,
        endDate: data[data.length - 1].date,
        yAxisMin: 0,
        yAxisMax: chartMax * 1.2,
        tooltipHtml(d) {
          const params = {
            heading: "Actuals",
            d: {
              label: d.label,
              value: d.value,
              formattedValue: d.value,
            },
          };

          return chartTooltipHtml.default(params);
        },
        values: data,
      };

      new LineChart(chartParams);
    },
    changeTableSort(column, defaultDir = "desc") {
      if (column !== this.tableSort.col) {
        this.tableSort.col = column;
        this.tableSort.dir = defaultDir;
      } else {
        this.tableSort.dir = this.tableSort.dir === "asc" ? "desc" : "asc";
      }
    },
    fetchBreakdownData() {
      const breakdownUrl = `${url}/breakdown${this.queryParams()}`;
      return fetch(breakdownUrl)
        .then((resp) => resp.json())
        .then((data) => {
          this.breakdownData = data.results;
        })
        .catch((error) => {
          console.error("Error fetching breakdown data:", error);
        });
    },
  };
}

import { objectToQueryString } from "../../shared/utils";

export function heatmapNavigator(args) {
  const {
    heatmapUrls,
    behavioralItems,
    teamItems,
    comparisonItems,
    allColumns,
    showIntro,
  } = args;

  return {
    loaded: false,
    behavioral: null,
    breakdown: null,
    comparison: null,
    hideComparison: false,
    behavioralItems,
    allColumns: [],
    deselectedColumnIds: [],
    csvQueryString: location.search.split("?")[1],
    showIntro,
    init() {
      // Since we now have squad_ids that can be appended, the URL with search may not match
      // Reverse so matching of benchmarks comes before the vanilla URL and can use includes
      const urlMap = heatmapUrls
        .reverse()
        .find((u) => (location.pathname + location.search).includes(u.url));

      // Put them back in order as "None" is the default value for navigation to come first
      heatmapUrls.reverse();

      if (urlMap) {
        this.behavioral = urlMap.behavioral;
        this.breakdown = urlMap.breakdown;
        this.comparison = urlMap.comparison;
        this.hideComparison = urlMap.behavioral === "priorities";
      } else {
        this.behavioral = "scores";
        this.breakdown = "team";
        this.comparison = null;
      }

      this.allColumns = allColumns;
      const storedColumnIds =
        JSON.parse(localStorage.getItem("heatmap-columns")) || {};
      this.deselectedColumnIds = storedColumnIds[this.behavioral] || [];

      this.$nextTick(() => {
        this.$watch("behavioral, breakdown, comparison", () => {
          let url = this.heatmapUrl;
          this.csvQueryString = `${url.split("?")[1]}`;

          if (location.pathname !== url) {
            url = this.appendSquadIdsAndBranch(url);
            this.xhrHtmlRequest(url);
          }
        });
      });

      this.loaded = true;

      // if (showIntro) {
      //   setTimeout(() => {
      //     window
      //       .introJs()
      //       .setOptions({
      //         exitOnEsc: false,
      //         exitOnOverlayClick: false,
      //         doneLabel: "Got it",
      //         scrollToElement: false,
      //         disableInteraction: true,
      //         steps: [
      //           {
      //             element: document.querySelector(".x-heatmap-types"),
      //             intro: "View different heatmap types.",
      //           },
      //           {
      //             element: document.querySelector(".x-heatmap-breakdowns"),
      //             intro: "Switch breakdown types.",
      //           },
      //         ],
      //       })
      //       .start()
      //       .oncomplete(() => {
      //         window.ack(showIntro);
      //       });
      //   }, 50);
      // }
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    get branch() {
      return this.$store.snapshotResultFilters.branch;
    },
    get teamItems() {
      return teamItems;
    },
    get comparisonItems() {
      if (this.behavioral === "csat_scores") {
        return comparisonItems.filter(
          (item) => !["50", "75", "90"].includes(item.value),
        );
      }

      return comparisonItems;
    },
    getSelectedItem(dropdown) {
      let items;

      if (dropdown === "behavioral") {
        items = this.behavioralItems;
      } else if (dropdown === "breakdown") {
        items = this.teamItems;
      } else {
        items = this.comparisonItems;
      }

      const currentValue = this[dropdown];
      const itemExists = items.some((item) => item.value === currentValue);

      if (!itemExists) {
        this[dropdown] = null;
      }

      const item = items.find((item) => item.value === this[dropdown]);

      this.hideComparison = this.behavioral === "priorities";

      return item;
    },
    get heatmapUrl() {
      this.expanded = false;

      let urlMap;

      if (this.comparison) {
        urlMap = heatmapUrls.find((u) => {
          return (
            u.behavioral === this.behavioral &&
            u.breakdown === this.breakdown &&
            u.comparison === this.comparison
          );
        });
      }

      urlMap ||= heatmapUrls.find((u) => {
        return (
          u.behavioral === this.behavioral && u.breakdown === this.breakdown
        );
      });

      urlMap ||= heatmapUrls.find((u) => u.behavioral === this.behavioral);

      const { url } = urlMap;

      return url;
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;

      let url = this.heatmapUrl;

      url = this.appendSquadIdsAndBranch(url);

      this.xhrHtmlRequest(url);
    },
    xhrHtmlRequest(url) {
      this.loaded = false;

      window.history.pushState({}, "", url);

      url += url.includes("?") ? "&xhr=1" : "?xhr=1";

      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          if (!this.$refs.heatmapContainer) return; // Can happen if user navigated away before page loaded

          this.$refs.heatmapContainer.innerHTML = html;

          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const heatmapHeaders = doc.querySelectorAll(".heatmap-header");
          const allColumns = Array.from(heatmapHeaders).map((header) => {
            return {
              name: header.textContent.replace(/\n\s*/g, "").trim(),
              factor_id: parseInt(header.id),
            };
          });
          this.allColumns = allColumns;
          const storedColumnIds =
            JSON.parse(localStorage.getItem("heatmap-columns")) || {};
          this.deselectedColumnIds = storedColumnIds[this.behavioral] || [];

          this.loaded = true;

          // Applies default squad tree state
          document.dispatchEvent(new Event("dx:heatmap:loaded"));
        });
    },
    appendSquadIdsAndBranch(url) {
      if (!this.squadIds && !this.branch) return url;

      const paramsObj = {
        branch: this.branch,
        squad_ids: this.squadIds,
      };

      if (!url.includes("?")) {
        url += `?${objectToQueryString(paramsObj)}`;
      } else {
        url += `&${objectToQueryString(paramsObj)}`;
      }

      return url;
    },
    toggleColumn(columnId) {
      const index = this.deselectedColumnIds.indexOf(columnId);
      if (index !== -1) {
        this.deselectedColumnIds.splice(index, 1);
      } else {
        this.deselectedColumnIds.push(columnId);
      }

      const storedColumnIds =
        JSON.parse(localStorage.getItem("heatmap-columns")) || {};
      storedColumnIds[this.behavioral] = this.deselectedColumnIds;
      localStorage.setItem("heatmap-columns", JSON.stringify(storedColumnIds));
    },
    toggleAll() {
      if (this.deselectedColumnIds.length === 0) {
        this.deselectedColumnIds = this.allColumns.map(
          (column) => column.factor_id,
        );
      } else {
        this.deselectedColumnIds = [];
      }
      try {
        const storedColumnIds =
          JSON.parse(localStorage.getItem("heatmap-columns")) || {};
        storedColumnIds[this.behavioral] = this.deselectedColumnIds;
        localStorage.setItem(
          "heatmap-columns",
          JSON.stringify(storedColumnIds),
        );
      } catch (error) {
        const defaultColumnIds = { [this.behavioral]: [] }; // Add a default structure
        localStorage.setItem(
          "heatmap-columns",
          JSON.stringify(defaultColumnIds),
        );
      }
    },
    get columnSelectorText() {
      if (this.deselectedColumnIds.length === 0) {
        return "Deselect all";
      } else {
        return "Select all";
      }
    },
    isColumnSelected(columnId) {
      return !this.deselectedColumnIds.includes(columnId);
    },
  };
}

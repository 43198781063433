import { setQueryStringParam } from "../../shared/utils";

export function intercomFilters(args) {
  const { filters, eventBase } = args;

  return {
    init() {
      // Set initial query string
      for (const key in filters) {
        const value = filters[key];

        setQueryStringParam(key, value);
      }
    },
    setFilter(key, value) {
      filters[key] = value;

      setQueryStringParam(key, value);

      document.dispatchEvent(
        new CustomEvent(`dx:${eventBase}-filters-changed`, {
          detail: { filters },
        }),
      );
    },
    getFilters() {
      return filters;
    },
    currentRange() {
      return filters.range;
    },
  };
}

export function snapshotStatus(args) {
  const { squadCounts, workflows2 } = args;

  return {
    squadCounts,
    squadDelinquentUsers: {},
    squadDetailsShown: {},
    init() {
      this.$root.addEventListener("dx:squadsTree:init", (e) => {
        const squads = e.detail;

        squads.forEach((squad) => {
          if (squad.depth == 0 && !squad.is_parent) {
            this.squadDetailsShown[squad.id] = true;
          }
        });
      });
    },
    loadDelinquentSquadContributors(snapshotId, snapshotSquadId) {
      if (this.squadDelinquentUsers[snapshotSquadId]) return true; // Already loaded, no need to refetch

      let url;

      if (workflows2) {
        url = `/snapshots/v2/${snapshotId}/status/delinquent_squad_contributors/${snapshotSquadId}`;
      } else {
        url = `/snapshot/${snapshotId}/delinquent/${snapshotSquadId}`;
      }

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.squadDelinquentUsers[snapshotSquadId] =
            data.squad_delinquent_users;
        });

      return true;
    },
    hasIncompletions(squadId) {
      return this.snapshotSquadIncompleteCount(squadId) > 0;
    },
    snapshotSquadIncompleteCount(squadId) {
      return this.squadCounts[squadId]?.incomplete || 0;
    },
    snapshotSquadCompleteCount(squadId) {
      return this.squadCounts[squadId]?.completed || 0;
    },
    snapshotSquadTotalCount(squadId) {
      return this.squadCounts[squadId]?.total || 0;
    },
    completionPct(squadId) {
      return (
        (100.0 * this.snapshotSquadCompleteCount(squadId)) /
        this.snapshotSquadTotalCount(squadId)
      );
    },
    completionPctDisplay(squadId) {
      const pct = this.completionPct(squadId);

      if (pct === 0 || pct === 100) return "";

      return ` (${Math.round(pct)}%)`;
    },
    toggleSquadDetailsShown(squadId) {
      if (this.squadDetailsShown[squadId]) {
        delete this.squadDetailsShown[squadId];
        return;
      }

      this.squadDetailsShown[squadId] = true;
    },
  };
}

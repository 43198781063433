import { format, register } from "timeago.js";

register("abbreviated", (_number, index) => {
  return [
    ["Now", "right now"],
    ["Now", "in %s seconds"],
    ["1m", "in 1 minute"],
    ["%sm", "in %s minutes"],
    ["1h", "in 1 hour"],
    ["%sh", "in %s hours"],
    ["1d", "in 1 day"],
    ["%sd", "in %s days"],
    ["1w", "in 1 week"],
    ["%sw", "in %s weeks"],
    ["1mo", "in 1 month"],
    ["%smo", "in %s months"],
    ["1y", "in 1 year"],
    ["%sy", "in %s years"],
  ][index];
});

register("default", (_number, index) => {
  return [
    ["Now", "right now"],
    ["Now", "in %s seconds"],
    ["1 minute", "in 1 minute"],
    ["%s minutes", "in %s minutes"],
    ["1 hour", "in 1 hour"],
    ["%s hours", "in %s hours"],
    ["1 day", "in 1 day"],
    ["%s days", "in %s days"],
    ["1 week", "in 1 week"],
    ["%s weeks", "in %s weeks"],
    ["1 month", "in 1 month"],
    ["%s months", "in %s months"],
    ["1 year", "in 1 year"],
    ["%s years", "in %s years"],
  ][index];
});

export const timeagoAbbreviated = () => {
  return (subject) => format(subject, "abbreviated");
};

export const timeago = () => {
  return (subject) => format(subject, "default");
};

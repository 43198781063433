export function snapshotIntakeAllocation(args) {
  const { questions, values, nas } = args;

  return {
    questions,
    values: values || {},
    nas: nas || {},
    total(itemIds) {
      let sum = 0;
      itemIds.forEach((id) => {
        sum += parseInt(this.values[id]) || 0;
      });

      return sum;
    },
    amountLeft(itemIds) {
      return 100 - this.total(itemIds);
    },
    inputsFilled(itemIds) {
      return itemIds.every((id) => {
        const val = this.values[id];
        return val !== undefined && val !== null && val >= 0 && val <= 100;
      });
    },
    formValid() {
      for (const q of questions) {
        if (!this.nas[q.id]) {
          if (!this.inputsFilled(q.items.map((i) => i.id))) return false;
          if (this.total(q.items.map((i) => i.id)) !== 100) return false;
        }
      }

      return true;
    },
    onInput(itemIds) {
      if (this.total(itemIds) == 100) {
        // Automatically fill in the rest of the inputs with 0
        itemIds.forEach((id) => {
          const val = this.values[id];
          if (val == "" || val == undefined || val == null) {
            this.values[id] = 0;
          }
        });
      }
    },
  };
}

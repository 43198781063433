import { defaultNudgeMsg } from "./bulkNudge";
import { postData } from "../../shared/utils";
import { pushNotification } from "../pushNotification";

export function snapshotSetupNudges(args) {
  const {
    schedules: initialSchedules,
    usesSlack,
    currentUser,
    forTriage,
  } = args;

  const newSchedule = {
    sender: currentUser,
    id: null,
    message: "",
    target: forTriage ? "triage_dri" : "",
    daysFromDeadline: undefined,
    targetSelectExpanded: false,
    daySelectExpanded: false,
    messageChanged: false,
  };

  return {
    schedules: initialSchedules,
    hasChanged: false,
    formOpen: false,
    formData: newSchedule,
    deleteConfirmationId: null,
    deleteConfirmationOpen: false,
    get sortedSchedules() {
      return this.schedules.sort(
        (a, b) => b.days_from_deadline - a.days_from_deadline,
      );
    },
    addSchedule() {
      this.formData = { ...newSchedule };

      this.propagateToMessageTextarea(this.formData.message);

      this.formOpen = true;
    },
    editSchedule(schedule) {
      this.formData = {
        sender: schedule.sender,
        message: schedule.message,
        target: schedule.target,
        daysFromDeadline: schedule.days_from_deadline,
        id: schedule.id,
      };

      this.propagateToMessageTextarea(this.formData.message);

      this.formOpen = true;
    },
    deleteConfirmation(scheduleId) {
      this.deleteConfirmationId = scheduleId;
      this.deleteConfirmationOpen = true;
    },
    closeDeleteConfirmation() {
      this.deleteConfirmationId = null;
      this.deleteConfirmationOpen = false;
    },
    removeSchedule() {
      const id = this.deleteConfirmationId;
      const url = `/snapshot_settings/bulk_nudge_schedules/${id}`;

      return fetch(url, { method: "DELETE" }).then((response) => {
        if (!response.ok) alert("Something went wrong. Please try again.");

        this.schedules = this.schedules.filter((n) => n.id !== id);

        this.formOpen = false;
        this.closeDeleteConfirmation();

        pushNotification({ title: "Success!", desc: "Nudge removed." });
      });
    },
    upsertWithCurrentFormData() {
      const url = `/snapshot_settings/update_bulk_nudge_schedules?ret=${forTriage ? "for_triage" : "for_responses"}`;

      this.formData.submitting = true;
      postData(url, {
        id: this.formData.id,
        message: this.formData.message,
        target: this.formData.target,
        days_from_deadline: this.formData.daysFromDeadline,
      }).then((resp) => {
        pushNotification({
          title: "Success!",
          desc: "Your changes were saved.",
        });

        this.schedules = resp.schedules;
        this.formOpen = false;

        // Avoid button color flash before modal close transition finishes
        setTimeout(() => {
          this.formData.submitting = false;
        }, 100);
      });
    },
    propagateToMessageTextarea(message) {
      document.dispatchEvent(
        new CustomEvent(`dx:bulk-nudge-textarea`, {
          detail: message,
        }),
      );
    },
    scheduleDateTooltip() {},
    get formValid() {
      return this.formData.message.trim().length > 0 && !this.dayAndTargetTaken;
    },
    get dayAndTargetTaken() {
      const { target, daysFromDeadline, id } = this.formData;

      if (!target || daysFromDeadline === undefined) return false;

      const existingSchedule = this.schedules.find((s) => {
        return (
          s.target == target &&
          s.days_from_deadline == daysFromDeadline &&
          s.id !== id
        );
      });

      return !!existingSchedule;
    },
    scrubbedMessage(message) {
      return message.replace(/<@RECIPIENT>/g, "@Recipient");
    },
    onTargetSelect(target) {
      this.formData.target = target;
      this.maybeUpdateDefaultMsg();
      this.formData.targetSelectExpanded = false;
    },
    onDaySelect(daysFromDeadline) {
      this.formData.daysFromDeadline = daysFromDeadline;
      this.maybeUpdateDefaultMsg();
      this.formData.daySelectExpanded = false;
    },
    maybeUpdateDefaultMsg() {
      if (!this.formData.id && !this.formData.messageChanged) {
        const message = defaultNudgeMsg(
          this.formData.target,
          this.formData.daysFromDeadline < 0,
          usesSlack,
        );
        this.formData.message = message;

        this.propagateToMessageTextarea(message);
      }
    },
    onMessageChange(e) {
      this.formData.messageChanged = true;
      this.formData.message = e.target.value;
    },
  };
}

import { setLocalStorageItem } from "../../shared/utils";

export function costEstimator(ftes) {
  const rate = localStorage.getItem("estimatorRate") || null;

  return {
    ftes,
    rate,
    configShown: false,
    get rateNumeric() {
      if (this.rate === null || this.rate.length < 1) return null;

      return parseInt(this.rate.replaceAll(",", ""));
    },
    init() {
      this.$watch("rate", (value) =>
        setLocalStorageItem("estimatorRate", value),
      );
    },
  };
}

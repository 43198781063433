import { arrayRange } from "../../shared/utils";

const targetPositionCountMap = new Map([
  [0, "zero_position_count"],
  [1, "one_position_count"],
  [2, "two_position_count"],
  [3, "three_position_count"],
  [4, "four_position_count"],
  [5, "five_position_count"],
  [6, "six_position_count"],
]);

export function workflowResultDetails(args) {
  const { workflow, maxBehavioralQuestions } = args;

  return {
    workflow,
    get compareTo() {
      return this.$store.snapshotResultFilters.compareTo;
    },
    percentageWidthForPosition(position) {
      return `width: ${this.workflowPercentageOfResponses(position)}`;
    },
    workflowPercentageOfResponses(position) {
      if (this.workflow["count"] === 0) return "0%";

      return `${Math.round(
        (100 * this.workflow[targetPositionCountMap.get(position)]) /
          this.workflow["count"],
      )}%`;
    },
    targetedPositions() {
      if (this.workflow.target_direction == "u")
        return arrayRange(0, this.workflow.target_position, 1);

      return arrayRange(
        this.workflow.target_position,
        maxBehavioralQuestions - 1,
        1,
      ).reverse();
    },
    nonTargetedPositions() {
      if (this.workflow.target_direction == "u")
        return arrayRange(
          this.workflow.target_position + 1,
          maxBehavioralQuestions - 1,
          1,
        );

      return arrayRange(0, this.workflow.target_position - 1, 1).reverse();
    },
    calculateWorkflowComparison() {
      if (this.compareTo == "vs_50th") {
        if (this.workflow.benchmark_50 == null) return null;

        return this.workflow.score - this.workflow.benchmark_50;
      } else if (this.compareTo == "vs_75th") {
        if (this.workflow.benchmark_75 == null) return null;

        return this.workflow.score - this.workflow.benchmark_75;
      } else if (this.compareTo == "vs_90th") {
        if (this.workflow.benchmark_90 == null) return null;

        return this.workflow.score - this.workflow.benchmark_90;
      } else if (this.compareTo == "vs_prev") {
        return this.workflow.previous_score != null
          ? this.workflow.score - this.workflow.previous_score
          : null;
      } else if (this.compareTo == "vs_org") {
        return this.workflow.score - this.workflow.org_score;
      }
    },
  };
}

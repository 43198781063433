import { setLocalStorageItem } from "../../shared/utils";

const previousSnapshotOpts = [["vs_prev", "Previous"]];

const teamOpts = [["vs_org", "Org average"]];

const industryOpts = [
  ["vs_50th", "50th percentile"],
  ["vs_75th", "75th percentile"],
  ["vs_90th", "90th percentile"],
];

export function snapshotCompareTo(args) {
  const { benchmarkSegments, benchmarkSegmentUrl, updateUserBenchmarkUrl } =
    args;

  let snapshots = args?.snapshots;
  // These next few lines ensure the data takes the current snapshot into account
  const currentSnapshotEncodedId = args?.currentSnapshot;
  const indexOfCurrent = snapshots.findIndex(
    (s) => s.encoded_id == currentSnapshotEncodedId,
  );
  snapshots = snapshots.slice(indexOfCurrent + 1);

  const olderSnapshotOpts = snapshots
    .slice(1)
    .map((s) => [`ss-${s?.encoded_id}`, s?.date]); // where the key for the URL param is getting set.

  const optionLabelsMap = new Map([
    ...previousSnapshotOpts,
    ...olderSnapshotOpts,
    ...teamOpts,
    ...industryOpts,
  ]);

  optionLabelsMap.set("vs_50th", "Industry P50");
  optionLabelsMap.set("vs_75th", "Industry P75");
  optionLabelsMap.set("vs_90th", "Industry P90");

  const { currentUser, currentAccount } = window;

  const benchmarkSegmentCacheKey = `benchmark_segment_id_${currentUser.account_id}`;

  return {
    expanded: false,
    benchmarkSegmentOpen: false,
    previousSnapshotOpts: previousSnapshotOpts,
    olderSnapshotOpts,
    benchmarkModalOpen: false,
    teamOpts,
    industryOpts,
    benchmarkSegmentId: null,
    benchmarkSegments,
    optionLabelsMap,
    completedSnapshots: snapshots,
    hidePreviousSnapshots: args?.hidePreviousSnapshots || false,
    updateUserBenchmarkUrl,
    benchmarkSegmentUrl,
    init() {
      this.initializeBenchmarkSegment();
    },
    initializeBenchmarkSegment() {
      // Try to load benchmarkSegmentId from localStorage
      const benchmarkSegmentId = JSON.parse(
        localStorage.getItem(benchmarkSegmentCacheKey),
      );
      if (benchmarkSegmentId) {
        this.benchmarkSegmentId = benchmarkSegmentId;
        return;
      }

      if (this.benchmarkSegmentUrl) {
        fetch(this.benchmarkSegmentUrl)
          .then((response) => response.json())
          .then((data) => {
            // Set the benchmarkSegmentId
            setLocalStorageItem(
              benchmarkSegmentCacheKey,
              data.benchmark_segment_id,
            );
            this.benchmarkSegmentId = data.benchmark_segment_id;
          });
      }
    },
    get benchmarkSegment() {
      return benchmarkSegments.find(
        (benchmarkSegment) => benchmarkSegment.id == this.benchmarkSegmentId,
      );
    },
    // This function is used as part of the callback of change-benchmark-window.
    // We need this because if someone updates a benchmark in a detail modal there is a separate compareToMenu
    // on the main results page that needs to have its state updated and vice versa
    updateBenchmark(detail) {
      this.benchmarkSegmentId = detail.benchmarkSegment.id;
    },
    get benchmarkSegmentLabel() {
      return this.benchmarkSegment?.name;
    },
    toggleDropdown() {
      this.expanded = !this.expanded;
    },
    postProcessChange() {
      this.expanded = false;
    },
    isSelected(opt) {
      const previousSnapshotId =
        this.$store.snapshotResultFilters.previousSnapshotId;

      if (previousSnapshotId) {
        return opt == `ss-${previousSnapshotId}`;
      } else {
        return this.compareTo == opt;
      }
    },
    comparisonMenuLabel(value) {
      const previousSnapshotId =
        this.$store.snapshotResultFilters.previousSnapshotId;

      if (previousSnapshotId) {
        const mapValue = optionLabelsMap.get(`ss-${previousSnapshotId}`);
        return mapValue;
      } else {
        return optionLabelsMap.get(value);
      }
    },
    benchmarkSegmentIsSelected(benchmarkSegment) {
      return benchmarkSegment?.id == this.benchmarkSegmentId;
    },
    selectBenchmark(benchmarkSegment) {
      this.benchmarkSegmentId = benchmarkSegment?.id;
    },
    isAccountSegmentId(benchmarkSegment) {
      return benchmarkSegment?.id == currentAccount.benchmark_segment_id;
    },
    updateBenchmarkSegmentForUser() {
      fetch(this.updateUserBenchmarkUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          benchmark_segment_id: this.benchmarkSegmentId,
        }),
      }).then(() => {
        setLocalStorageItem(benchmarkSegmentCacheKey, this.benchmarkSegmentId);
        this.$store.snapshotResultFilters.benchmarkSegmentId =
          this.benchmarkSegmentId;

        this.$el.dispatchEvent(
          new CustomEvent("change-benchmark-segment", {
            bubbles: true,
            detail: { benchmarkSegment: this.benchmarkSegment },
          }),
        );
      });
    },
  };
}

import { eqArrays } from "../../shared/utils";

export function tagMultiselect(args) {
  const { options, onChange, hideAll } = args;

  const allRootIds = new Set(options.map((o) => o.id));
  const selectedIds = new Set(
    args.selectedIds.filter((id) => allRootIds.has(id)),
  );

  let _valueAtOpen;

  return {
    searchResults: [],
    selectedIds,
    dynamicPrompt: null,
    expanded: false,
    hideAll,
    init() {
      this.setPrompt();
    },
    get selectedIdsArr() {
      return Array.from(this.selectedIds);
    },
    toggleDropdown() {
      if (this.expanded) {
        return this.unfocus();
      }

      this.focus();
    },
    focus() {
      this.searchResults = options;

      this.expanded = true;

      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });

      _valueAtOpen = this.selectedIdsArr;
    },
    unfocus() {
      if (!this.expanded) return;

      this.expanded = false;

      const valueNow = this.selectedIdsArr;

      const changed = !eqArrays(_valueAtOpen, valueNow);

      this.$nextTick(() => {
        if (changed) {
          onChange(this.$root, Array.from(valueNow));
        }

        this.$refs.searchInput.value = "";

        this.$refs.searchInput.blur();
      });
    },
    setPrompt() {
      if (this.selectedIds.size == 1) {
        this.dynamicPrompt = "1 filter";
      } else if (this.selectedIds.size == allRootIds.size) {
        this.dynamicPrompt = "All";
      } else if (this.selectedIds.size > 1) {
        this.dynamicPrompt = this.selectedIds.size + " filters";
      } else {
        this.dynamicPrompt = null;
      }
    },
    selectId(id) {
      this.selectedIds.add(id);
    },
    deselectId(id) {
      this.selectedIds.delete(id);
    },
    isSelected(id) {
      return this.selectedIds.has(id);
    },
    selectTag(id) {
      this.selectId(id);
    },
    deselectTag(id) {
      this.deselectId(id);
    },
    toggleSelectTag(id) {
      if (this.isSelected(id)) {
        this.deselectTag(id);
      } else {
        this.selectTag(id);
      }

      this.setPrompt();
    },
    selectAll() {
      options.forEach((option) => {
        if (!option.is_group_label) this.selectTag(option.id);
      });

      this.setPrompt();
    },
    deselectAll() {
      this.selectedIds.clear();

      this.setPrompt();
    },
    search() {
      const searchTerm = this.$refs.searchInput.value;

      if (searchTerm.length > 0) {
        let tag_group_name = "";
        const matchingResult = options.reverse().filter((option) => {
          const isNonTagGroupNameMatch =
            option.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !option.is_group_label;
          const isTagGroupOfMatch =
            option.is_group_label && option.name === tag_group_name;

          if (isNonTagGroupNameMatch || isTagGroupOfMatch) {
            tag_group_name = option.tag_group_name;
            return true;
          } else {
            return false;
          }
        });
        options.reverse();
        this.searchResults = matchingResult.reverse();

        return;
      }

      this.searchResults = options;
    },
  };
}

import {
  getQueryStringParams,
  setQueryStringParam,
  deleteQueryStringParam,
} from "../../shared/utils";

function convertToNonNullArray(value) {
  if (typeof value === "string") {
    // split the string into an array based on a delimiter (e.g., comma)
    return value.split(",").filter((v) => !!v);
  }

  return [];
}

export const snapshotResultFiltersStore = {
  squadIds:
    convertToNonNullArray(getQueryStringParams().get("squad_ids")) || [],
  tagIds: convertToNonNullArray(getQueryStringParams().get("tag_ids")) || [],
  compareTo: getQueryStringParams().get("ct") || "vs_50th",
  hierarchyGroup: getQueryStringParams().get("hg") || "team",
  aggregationTagGroup: getQueryStringParams().get("atg"),
  previousSnapshotId: null,
  sortBy: getQueryStringParams().get("sb") || "score",
  sortReverse:
    getQueryStringParams().get("sr") == null ||
    getQueryStringParams().get("sr") == "true",
  drilldownSortBy: getQueryStringParams().get("ddsb") || "snapshot_squad_name",
  drilldownSortReverse: getQueryStringParams().get("ddsr") == "true",
  selectedDrilldownTab: getQueryStringParams().get("ddt"),
  branch: getQueryStringParams().get("branch"),
  init() {
    const compareValue = getQueryStringParams().get("ct") || "vs_50th";
    const hierarchyGroupValue = getQueryStringParams().get("hg");
    const aggregationTagGroupValue = getQueryStringParams().get("atg");

    this.previousSnapshotId = getQueryStringParams().get("prev_ss");

    this.setCompareTo(
      this.previousSnapshotId ? `ss-${this.previousSnapshotId}` : compareValue,
      true,
    );

    if (hierarchyGroupValue)
      this.setHierarchyGroup({ encoded_id: hierarchyGroupValue });

    if (aggregationTagGroupValue)
      this.setAggregationTagGroup({ encoded_id: aggregationTagGroupValue });
  },
  setCompareTo(value, initialLoad) {
    const prefix = "ss-";
    const isPriorSnapshot = value?.startsWith(prefix);

    if (isPriorSnapshot) {
      const snapshotId = value.replace(prefix, "");

      this.compareTo = "vs_prev";
      setQueryStringParam("ct", "vs_prev");
      this.previousSnapshotId = snapshotId;
      setQueryStringParam("prev_ss", snapshotId);
    } else {
      this.compareTo = value;
      if (!initialLoad) {
        setQueryStringParam("ct", value);
      }
      this.previousSnapshotId = null;
      deleteQueryStringParam("prev_ss");
    }
  },
  setHierarchyGroup(option) {
    const name = option?.name;
    this.hierarchyGroup = option;
    this.setAggregationTagGroup(null);

    if (name && name.toLowerCase() != "team") {
      setQueryStringParam("hg", option.encoded_id);
    } else if (!name) {
      setQueryStringParam("hg", option.encoded_id);
    } else {
      deleteQueryStringParam("hg");
    }
  },
  setAggregationTagGroup(option) {
    if (!option) {
      this.aggregationTagGroup = null;
      deleteQueryStringParam("atg");
      return;
    }

    const name = option?.name;
    this.aggregationTagGroup = option;
    this.hierarchyGroup = { name: "team" };
    deleteQueryStringParam("hg");

    if (name) {
      setQueryStringParam("atg", option.encoded_id);
    }
  },
};

import { postData } from "../../shared/utils";

export function snapshotResponse(response, commentsExpanded = false) {
  const { liked, like_count, text, is_public, comments } = response;

  const baseUrl = `/snapshot_response/${response.id}`;

  let originalText, originalIsPublic;

  return {
    liked,
    like_count,
    text,
    is_public,
    response,
    comments,
    commentsExpanded,
    creatingComment: false,
    responseContextMenuExpanded: false,
    editingResponse: false,
    commentContextMenuExpanded: false,
    editingComment: false,
    toggleResponseContextMenu() {
      this.responseContextMenuExpanded = !this.responseContextMenuExpanded;
    },
    collapseResponseContextMenu() {
      this.responseContextMenuExpanded = false;
    },
    startEditingResponse() {
      // Save text to allow reverting
      originalText = this.text;
      originalIsPublic = this.is_public;

      this.responseContextMenuExpanded = false;

      this.editingResponse = true;

      this.$nextTick(() => {
        this.$refs.responseTextarea.readOnly = false;
        this.$refs.responseTextarea.focus();
      });
    },
    stopEditingResponse() {
      this.editingResponse = false;
      this.$refs.responseTextarea.readOnly = true;

      // Revert
      this.text = originalText;
      this.is_public = originalIsPublic;
    },
    updateResponse() {
      postData(baseUrl, { text: this.text, is_public: this.is_public });

      this.editingResponse = false;
      this.$refs.responseTextarea.readOnly = true;
    },
    like() {
      postData(`${baseUrl}/like`);

      this.liked = true;
      this.like_count++;
    },
    unlike() {
      postData(`${baseUrl}/unlike`);

      this.liked = false;
      this.like_count--;
    },
    isAuthor(object, user) {
      return object.author.id == user.id;
    },
    expandComments() {
      this.commentsExpanded = true;
    },
    toggleCommentContextMenu(comment) {
      if (this.isCommentContextMenuExpanded(comment)) {
        this.commentContextMenuExpanded = false;
        return;
      }

      this.commentContextMenuExpanded = comment.id;
    },
    collapseCommentContextMenu(comment) {
      if (this.isCommentContextMenuExpanded(comment)) {
        this.commentContextMenuExpanded = false;
      }
    },
    isCommentContextMenuExpanded(comment) {
      return this.commentContextMenuExpanded == comment.id;
    },
    startEditingComment(comment) {
      this.collapseCommentContextMenu(comment);

      this.editingComment = comment.id;

      const textarea = document.getElementById(`textarea${comment.id}`);

      textarea.readOnly = false;
      textarea.focus();
    },
    stopEditingComment(comment) {
      this.editingComment = false;

      const textarea = document.getElementById(`textarea${comment.id}`);

      textarea.readOnly = true;
    },
    isCommentBeingEdited(comment) {
      return this.editingComment == comment.id;
    },
    showNewCommentForm() {
      this.creatingComment = true;

      this.$nextTick(() => {
        this.$refs.newCommentTextarea.focus();
        this.$refs.newCommentTextarea.setSelectionRange(0, 0);
      });
    },
    hideNewCommentForm() {
      this.$refs.newCommentTextarea.value = "";
      this.creatingComment = false;
    },
    createComment() {
      const { value } = this.$refs.newCommentTextarea;

      postData(`${baseUrl}/comments`, { text: value }).then((data) =>
        this.comments.push(data.comment),
      );

      this.expandComments();

      this.hideNewCommentForm();
    },
    updateComment(comment) {
      const { id, text } = comment;

      this.stopEditingComment(comment);

      postData("/snapshot_comment/update", { id, text });
    },
    deleteComment(comment) {
      const { id } = comment;

      // Remove comment from collection
      this.comments = this.comments.filter((c) => c.id !== id);

      postData("/snapshot_comment/delete", { id });
    },
  };
}

import { paginateList, sortableHeaderHtml } from "./utils";

export const frontlinePrAlertsStore = {
  initialized: false,
  pullRequests: [],
  modalOpen: false,
  snoozed: 0,
  reviewTurnaroundAlerts: 0,
  stalenessAlerts: 0,
  sizeAlerts: 0,
  // For details modal:
  paginatedPullRequests: {},
  filters: { includeSnoozed: true },
  sort: "review_wait_time",
  sortDir: "desc",
  setInitialState({ pullRequests }) {
    this.originalPullRequests = pullRequests;
    this.pullRequests = pullRequests;
    this.modalOpen = false;
    this.calculateCounts();
    this.updateDisplayedList();
    this.initialized = true;
  },
  calculateCounts() {
    this.snoozed = this.pullRequests.filter((pr) => pr.snoozed).length;
    this.reviewTurnaroundAlerts = this.pullRequests.filter(
      (pr) => pr.review_turnaround_alert && !pr.snoozed,
    ).length;
    this.stalenessAlerts = this.pullRequests.filter(
      (pr) => pr.staleness_alert && !pr.snoozed,
    ).length;
    this.sizeAlerts = this.pullRequests.filter(
      (pr) => pr.size_alert && !pr.snoozed,
    ).length;
  },
  updatePr(prId, attrs) {
    const objIdx = this.pullRequests.findIndex((pr) => pr.id === prId);
    const obj = this.pullRequests[objIdx];
    const updatedObj = Object.assign(obj, attrs);
    this.pullRequests[objIdx] = updatedObj;

    this.filterAndSort();
    if (!this.filters.includeSnoozed) this.onPageChange(1);
  },
  updateDisplayedList() {
    this.filterAndSort();
    this.onPageChange(1);
  },
  onPageChange(page) {
    this.paginatedPullRequests = paginateList(
      page,
      10,
      this.pullRequests,
      "$store.frontlinePrAlerts.onPageChange",
    );
  },
  changeSort(key, defaultDir = "desc") {
    if (key !== this.sort) {
      this.sort = key;
      this.sortDir = defaultDir;
    } else {
      this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
    }

    this.updateDisplayedList();
  },
  changeFilter(key, val) {
    this.filters[key] = val;
    this.updateDisplayedList();
  },
  filterAndSort() {
    let val = this.originalPullRequests;

    if (!this.filters.includeSnoozed) {
      val = val.filter((pr) => !pr.snoozed);
    }

    if (this.sort === "review_wait_time")
      val = val.sort((a, b) => {
        // Always put nulls last
        if (this.sortDir === "desc") {
          if (!a.review_requested_at) return -1;
          if (!b.review_requested_at) return 1;
        } else {
          if (!a.review_requested_at) return 1;
          if (!b.review_requested_at) return -1;
        }

        return (
          new Date(b.review_requested_at) - new Date(a.review_requested_at)
        );
      });
    if (this.sort === "size")
      val = val.sort((a, b) => a.total_size - b.total_size);
    if (this.sort === "staleness")
      val = val.sort((a, b) => new Date(b.updated) - new Date(a.updated));

    if (this.sortDir === "desc") val = val.reverse();

    this.pullRequests = [...val];
  },
  sortableHtml(column) {
    return sortableHeaderHtml(column === this.sort, this.sortDir === "asc");
  },
};

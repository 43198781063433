import * as JsSearch from "js-search";
import { setWithExpiry, getWithExpiry } from "../shared/utils";

// Private variables
let allUsers, jsSearch, storageKey, useRemoteSearch;

function init(accountId, userCount) {
  // Use remote search if there are >2500 users
  useRemoteSearch = true; // always use remote search for pen tests

  if (useRemoteSearch) return;

  // Dynamically create localStorage key
  storageKey = `userSearch_${accountId}_v4`;

  jsSearch = buildJsSearch();

  // Attempt to load users from cache
  allUsers = getWithExpiry(storageKey);

  // If cached users exist, build search index
  if (allUsers) jsSearch.addDocuments(allUsers);

  fetchUsers();
}

function buildJsSearch() {
  const searchObj = new JsSearch.Search("id");
  searchObj.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
  searchObj.addIndex("normalized_name");
  searchObj.addIndex("name");
  searchObj.addIndex("email");

  return searchObj;
}

function rebuildSearch(users) {
  // Rebuild search index if it is not set or expired
  if (getWithExpiry(storageKey) === null) {
    const newJsSearch = buildJsSearch();
    newJsSearch.addDocuments(users);

    jsSearch = newJsSearch;

    allUsers = users;
  }

  // Cache users so we can instantly build indexes on page reload
  setWithExpiry(storageKey, users, 1000 * 60 * 60 * 5);
}

function fetchUsers(users = [], cursor = null) {
  let url = "/users/list";

  if (cursor) {
    url += `?cursor=${cursor}`;
  }

  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      users = users.concat(data.users);

      if (data.cursor) {
        fetchUsers(users, data.cursor);

        return;
      }

      rebuildSearch(users);
    })
    .catch((error) => {
      // no op
      console.log("Error fetching users", error);
    });
}

async function doRemoteSearch(str, exclude = []) {
  let url = `/users/search?q=${encodeURIComponent(str)}`;

  if (exclude.length > 0) {
    url += `&excl=${exclude.join(",")}`;
  }

  return await fetch(url)
    .then((response) => response.json())
    .then((data) => data.users);
}

function doLocalSearch(str, exclude = []) {
  const exclusions = new Set(exclude);

  let results;

  if (str.length > 0) {
    results = jsSearch.search(str).filter((user) => {
      return !exclusions.has(user.id);
    });
  } else {
    results = allUsers.slice(0, 100).filter((user) => {
      return !exclusions.has(user.id);
    });
  }

  return Promise.resolve(results);
}

function search(str, exclude = []) {
  return useRemoteSearch
    ? doRemoteSearch(str, exclude)
    : doLocalSearch(str, exclude);
}

// Expose functions globally
window.userSearch = {
  search,
  init,
};

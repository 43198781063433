export function snapshotIntakeAttributes(args) {
  const { values, tagGroups } = args;

  const snapshotBranchingEnabled = "snapshot_branch" in values;

  const devTagGroups = tagGroups.filter(
    (tg) => tg.target_role === "dev" || tg.target_role == "all",
  );
  const nondevTagGroups = tagGroups.filter(
    (tg) => tg.target_role === "nondev" || tg.target_role == "all",
  );

  return {
    values,
    focusedKey: null,
    init() {
      this.focusNextRow();

      this.$watch("values", () => this.focusNextRow());
    },
    focusNextRow() {
      this.$nextTick(() => {
        const nextRow = document.querySelector(".x-unfilled:not(.hidden)");

        if (nextRow) this.focusedKey = nextRow.dataset.key;
      });
    },
    isDev() {
      return this.values["developer"] === "1";
    },
    isNonDev() {
      return this.values["developer"] === "0";
    },
    snapshotBranchingUnfilled() {
      return (
        snapshotBranchingEnabled && this.values["snapshot_branch"] === null
      );
    },
    formValid() {
      if (this.values["developer"] === null) {
        return false;
      }

      if (snapshotBranchingEnabled && this.values["snapshot_branch"] === null) {
        return false;
      }

      if (this.isDev()) {
        for (const tagGroup of devTagGroups) {
          if (this.values[`tag_group_${tagGroup.id}`] === null) return false;
        }
      }

      if (this.isNonDev()) {
        for (const tagGroup of nondevTagGroups) {
          if (this.values[`tag_group_${tagGroup.id}`] === null) return false;
        }
      }

      return true;
    },
    isHiddenTagGroup(key) {
      const tagGroup = tagGroups.find((tg) => `tag_group_${tg.id}` == key);

      if (tagGroup.target_role == "dev" && !this.isDev()) {
        return true;
      }

      if (tagGroup.target_role == "nondev" && !this.isNonDev()) {
        return true;
      }

      return false;
    },
    isFilled(key) {
      return (
        this.values[key] !== null &&
        this.values[key] !== undefined &&
        this.values[key] !== ""
      );
    },
    isFilledOrFocused(key) {
      return this.isFilled(key) || key == this.focusedKey;
    },
  };
}

import Sortable from "sortablejs";

export function allocationQuestionConfig(args) {
  const { prompt, targetRole, items } = args;

  return {
    prompt,
    promptPlaceholder:
      "In the past quarter, what proportion of your time was spent between these areas?",
    targetRole,
    showDescriptionModal: false,
    descriptionModalItemIdx: undefined,
    showDeleteModal: false,
    deleteConfirmText: "",
    items,
    init() {
      this.initSortable();
    },
    isDuplicate(name) {
      return this.items.filter((item) => item.name === name).length > 1;
    },
    addItem() {
      this.items.push({ name: "" });

      this.$nextTick(() => {
        this.$refs.newOption.focus();
      });
    },
    updateName(event, option) {
      const updatedName = event.target.textContent;
      option.name = updatedName;
    },
    removeItem(idx) {
      this.items.splice(idx, 1);
    },
    openDescriptionModal(idx) {
      this.descriptionModalItemIdx = idx;
      this.showDescriptionModal = true;
    },
    get formValid() {
      return (
        !!this.prompt.trim().length &&
        this.items.length >= 2 &&
        this.items.every((i) => {
          return (
            i.name !== undefined && i.name !== null && !!i.name?.trim()?.length
          );
        }) &&
        !this.items.some((item) => this.isDuplicate(item.name))
      );
    },
    itemPlaceholder(idx) {
      if (idx === 0) {
        return "Feature work";
      } else if (idx === 1) {
        return "Non-feature work";
      } else {
        return `Option ${idx + 1}`;
      }
    },
    reorderArray(array, order) {
      const newArray = new Array(array.length);
      order.forEach((newIndex, originalIndex) => {
        newArray[newIndex] = array[originalIndex];
      });

      return newArray;
    },
    initSortable() {
      const self = this;
      const sortableList = document.getElementById("sortable-list");
      const editableContents = document.querySelectorAll(".editable-content");

      new Sortable(sortableList, {
        animation: 150,
        handle: ".handle",
        onStart() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "false"),
          );
        },
        onEnd() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "true"),
          );

          const order = Array.from(
            self.$refs.sortableList.querySelectorAll(".sortable-item"),
          )
            .map((item) => item.dataset.idx)
            .map(Number);

          const itemsToRemove = sortableList.querySelectorAll(".sortable-item");

          // Remove dom elements
          itemsToRemove.forEach((item) => {
            sortableList.removeChild(item);
          });

          // Add dom elements
          const itemsToAdd = self.reorderArray(itemsToRemove, order);
          itemsToAdd.forEach((item) => {
            sortableList.appendChild(item);
          });

          // Update component data
          self.items = order.map((index) => self.items[index]);
        },
      });
    },
  };
}

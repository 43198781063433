import {
  addCurrentPageEventListener,
  objectToQueryString,
} from "../../shared/utils";

export function intercomBarChart(args) {
  const { endpoint, name, getFilters } = args;

  return {
    chartData: { total: 0, data: [] },
    showChart: args.showChart,
    loading: false,
    init() {
      this.$nextTick(() => {
        this.reloadChart();
      });

      addCurrentPageEventListener("dx:intercom-filters-changed", () => {
        this.reloadChart();
      });
    },
    reloadChart: function () {
      const filtersQueryString = objectToQueryString({
        name,
        type: "bar",
        ...getFilters(),
      });
      this.loading = true;
      fetch(`${endpoint}${"?" + filtersQueryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.chartData = data.chart;
          this.loading = false;
        })
        .catch(() => {});
    },
  };
}

import { LineChart, StackedAreaChart } from "@get-dx/d3-charts";
import {
  getQueryStringParams,
  setQueryStringParam,
  deleteQueryStringParam,
  objectToQueryString,
  generatePreviousComparisonValues,
  sortFunc,
  snapshotSquadsFromCache,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

export function snapshotCsat(defaults) {
  const {
    baseUrl,
    commentsSearchUrl,
    drilldownTabs,
    minResponseThreshold,
    csatRatingOptions,
    tagOptions,
    snapshotId,
    hasHierarchyGroups,
    commentsEnabled,
  } = defaults;

  return {
    drilldownTabs,
    drilldownData: {},
    drilldownModalOpen: false,
    selectedCsat: null,
    trends: null,
    xStart: null,
    maxCsatScore: null,
    tabDataLoaded: false,
    selectedDrilldownTab: getQueryStringParams().get("ddt"),
    csatScores: [],
    csatRatingOptions,
    resultItemsLoaded: false,
    xEnd: null,
    squadNamesFromSquadIds: [],
    showTeamBreakdown: true,
    commentSearchTerm: "",
    commentSearchResults: [],
    searchCommentsLoaded: false,
    itemSearchTerm: "",
    init() {
      this.$root.addEventListener("dx:squadsTree:init", () => {
        this.determineSquadNamesFromSquadIds();

        // Loading directly to modal from URL cached squads are empty, so wait for event
        if (this.squadIds.length != 1) {
          this.showTeamBreakdown = true;
        } else {
          this.showTeamBreakdown = this.cachedSquads.find(
            (squad) => squad.id == this.squadIds[0],
          )?.is_parent;
        }
      });

      if (this.selectedDrilldownTab) {
        const selectedItem = drilldownTabs.find(
          (t) => t.tab == this.selectedDrilldownTab,
        );
        this.fetchTab(this.selectedDrilldownTab, selectedItem.url);
      }

      this.$store.snapshotResultFilters.hasHierarchyGroups = hasHierarchyGroups;

      this.fetchAll();
    },
    get cachedSquads() {
      return snapshotSquadsFromCache(snapshotId);
    },
    determineSquadNamesFromSquadIds() {
      const selectedSquads = this.cachedSquads.filter(
        (squad) =>
          this.squadIds.includes(String(squad.id)) ||
          this.squadIds.includes(squad.id),
      );

      this.squadNamesFromSquadIds = selectedSquads.map((squad) => squad.name);
      // Loading directly to modal from URL cached squads are empty, so wait for event
      if (this.squadIds.length != 1) {
        this.showTeamBreakdown = true;
      } else {
        this.showTeamBreakdown = this.cachedSquads.find(
          (squad) => squad.id == this.squadIds[0],
        )?.is_parent;
      }
    },
    get tagNamesFromTagIds() {
      const selectedTags = tagOptions.filter(
        (tag) =>
          this.tagIds.includes(String(tag.id)) || this.tagIds.includes(tag.id),
      );

      return selectedTags.map((tag) => tag.name);
    },
    get compareTo() {
      // NOTE: Disabling other comparison options for now until we support it.
      // return this.compareTo;
      return "vs_previous";
    },
    get csatItems() {
      let items = this.csatScores;

      if (this.itemSearchTerm) {
        const normalizedTearm = this.itemSearchTerm.trim().toLowerCase();
        items = items.filter((item) => {
          return (
            item.name.toLowerCase().includes(normalizedTearm) ||
            item.group?.toLowerCase()?.includes(normalizedTearm)
          );
        });
      }

      items = items.sort(
        sortFunc(this.sortBy, this.compareTo, { reverse: this.sortReverse }),
      );

      const fieldsWithReversibleSortFunctions = [
        "score",
        "calculatedComparison",
      ];

      if (
        this.sortReverse &&
        !fieldsWithReversibleSortFunctions.includes(this.sortBy)
      ) {
        return items.reverse();
      }

      return items;
    },
    get selectedCsatComparison() {
      const rowData = this.selectedCsat;

      if (!rowData) return null;

      return rowData[this.compareTo];
    },
    get hasMultipleTrendValues() {
      return this?.trends?.values?.length > 1;
    },
    get visibleDrilldownTabs() {
      let filteredTabs = drilldownTabs.filter((drilldownTab) => {
        if (drilldownTab.tab == "team_breakdown") {
          return this.showTeamBreakdown;
        }

        if (drilldownTab.tab == "comments") {
          return commentsEnabled;
        }

        return true;
      });

      if (hasHierarchyGroups) {
        filteredTabs = filteredTabs.map((t) =>
          t.tab == "team_breakdown" ? { ...t, text: "Breakdowns" } : t,
        );
      }

      return filteredTabs;
    },
    get filtersApplied() {
      return (
        this.$store.snapshotResultFilters.tagIds.length ||
        this.$store.snapshotResultFilters.squadIds.length ||
        this.$store.snapshotResultFilters.hierarchyGroup
      );
    },
    itemPercentage(csatItem, index, withPercent = false) {
      if (!csatItem) return 0;
      const nonNaResponseCount = csatItem.count - csatItem.idk_count;

      if (nonNaResponseCount == 0) return 0;

      const percentage = Math.round(
        (100.0 * csatItem[csatRatingOptions[index].sql_label]) /
          nonNaResponseCount,
      );

      return withPercent ? `${percentage}%` : percentage;
    },
    calculateItemComparison(item) {
      if (!item.previous_score) return null;

      return Math.round(item.score - item.previous_score);
    },
    itemScore(csatItem) {
      if (!csatItem.score) return "NA";

      return Math.round(csatItem.score);
    },
    naPercentageDescription(csatItem) {
      if (!csatItem) return;
      const naPercentage = Math.round(
        (100.0 * csatItem.idk_count) / csatItem.count,
      );

      return `${naPercentage}% of people responded Not applicable`;
    },
    get tagIds() {
      return this.$store.snapshotResultFilters.tagIds;
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    get sortReverse() {
      return this.$store.snapshotResultFilters.sortReverse;
    },
    get sortBy() {
      return this.$store.snapshotResultFilters.sortBy;
    },
    get drilldownSortBy() {
      return this.$store.snapshotResultFilters.drilldownSortBy;
    },
    get drilldownSortReverse() {
      return this.$store.snapshotResultFilters.drilldownSortReverse;
    },
    openDrilldownModal(item) {
      // Don't show any modals if not enough responses
      if (this.count < minResponseThreshold) return;

      this.drilldownModalOpen = true;
      this.selectedCsat = item;

      setQueryStringParam("csat_id", item.id);
      const tab = getQueryStringParams().get("ddt");
      if (!tab) {
        setQueryStringParam("ddt", "overview");
        this.selectedDrilldownTab = "overview";
      }

      const selectedItem =
        this.drilldownTabs.find((t) => t.tab == this.selectedDrilldownTab) ||
        this.drilldownTabs[0];
      this.fetchTab(selectedItem.tab, selectedItem.url);
    },
    hasIdk(item) {
      return item?.idk_count > 0;
    },
    hasAllIdkResponses(item) {
      return item?.idk_count == item?.count;
    },
    hideCsatDetails() {
      this.drilldownModalOpen = false;
      this.selectedDrilldownTab = null;
      this.drilldownData = {};
      this.trends = null;
      this.selectedCsat = null;

      deleteQueryStringParam("ddt");
      deleteQueryStringParam("csat_id");
    },
    percentageOfResponsesOverview(positionCount, totalFieldCount) {
      const item = this.selectedCsat;

      if (!item) return;

      if (item[totalFieldCount] === 0) return "0%";

      return `${Math.round(
        (100 * item[positionCount]) / item[totalFieldCount],
      )}%`;
    },
    fetchTab(tab, url) {
      if (!this.selectedCsat?.id) return;
      if (!this.drilldownData[tab]) {
        this.tabDataLoaded = false;
      }
      // Make this smarter so if we've already fetched the tab for the SAME internal_tool we don't fetch it again
      this.selectedDrilldownTab = tab;
      setQueryStringParam("ddt", tab);

      return fetch(`${url}${this.queryParams()}`)
        .then((resp) => resp.json())
        .then((data) => {
          this.drilldownData[tab] = { data };
          this.tabDataLoaded = true;
          this.renderTabDetails(tab, data);
        });
    },
    queryParams() {
      const paramObj = {
        squad_ids: `${this.squadIds.join(",")}`,
        tag_ids: `${this.tagIds.join(",")}`,
        csat_id: this.selectedCsat?.id,
        hg: this.$store.snapshotResultFilters.hierarchyGroup.encoded_id,
        branch: this.$store.snapshotResultFilters.branch,
      };

      return `?${objectToQueryString(paramObj)}`;
    },
    fetchAll() {
      return this.fetchResultItems();
    },
    fetchResultItems() {
      this.resultItemsLoaded = false;

      let url = `${baseUrl}${this.queryParams()}`;

      return fetch(url)
        .then((resp) => resp.json())
        .then((data) => {
          this.csatScores = data.csat_scores;

          const targetedCsatId = getQueryStringParams().get("csat_id");
          this.selectedCsat = targetedCsatId
            ? this.csatScores?.find((score) => score.id == targetedCsatId)
            : null;
          if (this.selectedCsat) {
            this.openDrilldownModal(this.selectedCsat);
          }

          this.resultItemsLoaded = true;
        });
    },
    renderTabDetails(tab, data) {
      if (tab == "overview") {
        // We get all of the outcome trends in one query so we can reduce queries in the future
        this.trends = data.trends.find(
          (trend) => trend.internal_tool.id == this.selectedCsat?.id,
        ) || { values: [] };
        this.xStart = this.trends.values[0]?.date;
        this.renderCsatTrendChart();
        this.renderCsatDistributionChart();
      }
      if (tab == "team_breakdown") {
        this.calculateMaxTeamScore();
      }
    },
    calculateMaxTeamScore() {
      const arr = this.breakdownItems.map((i) => i.score);

      this.maxCsatScore = Math.max(...arr);
    },
    percentageOfResponses(item, positionCount) {
      if (!item?.count) return "0%";

      return `${Math.round((100 * item[positionCount]) / item.count)}%`;
    },
    renderCsatTrendChart() {
      const elChart = document.getElementById(`csat-trends-chart`);
      if (!elChart || !this.trends.values?.length) return;

      elChart.innerHTML = null;
      const minVal = Math.min(...this.trends.values.map((v) => v.value));
      const maxVal = Math.max(...this.trends.values.map((v) => v.value));

      const trendSize = this.trends.values.length;
      this.xEnd = this.trends.values[trendSize - 1]?.label;
      // Avoid using 'this' in passing in function for the tooltip callback, get the string explicitly
      const heading = this.trends.internal_tool.name;

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: this.trends.values[trendSize - 1]?.date,
        tooltipHtml(d, cd) {
          const params = {
            heading: heading,
            d: {
              label: d.label,
              value: d.value,
              formattedValue: d.value,
            },
          };

          if (cd) {
            params.cd = {
              label: cd.label,
              value: cd.value,
              formattedValue: cd.value,
            };
          }

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: this.trends.values,
      };

      if (this.trends.values.length > 1) {
        attrs.comparisonValues = generatePreviousComparisonValues(this.trends);
      }

      return new LineChart(attrs);
    },
    xStartLabel() {
      const date = new Date(this.xStart);
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${month} ${year}`;
    },
    renderCsatDistributionChart() {
      if (!this.selectedCsat || !this.trends || !this.hasMultipleTrendValues)
        return;
      let elChart = document.getElementById(`csat-distribution-chart`);
      if (!elChart) return;

      const csat = this.selectedCsat;
      const trendData = this.trends;
      elChart.innerHTML = null;

      const minVal = Math.min(
        ...trendData.values.map((v) => v.value).concat(csat.benchmark_50),
      );
      const maxVal = Math.max(
        ...trendData.values.map((v) => v.value).concat(csat.benchmark_90),
      );
      const xEnd = trendData.values[trendData.values.length - 1].date;

      const colors = [
        "#FCA5A5",
        "#FDBA74",
        "#FCD34D",
        "#BEF264",
        "#86EFAC",
        "#6EE7B7",
      ];

      let stackedAreaValues = {
        series: [
          {
            key: "very_dissatisfied",
            name: "Very dissatisfied",
            color: colors[0],
            counts: [],
          },
          {
            key: "dissatisfied",
            name: "Dissatisfied",
            color: colors[1],
            counts: [],
          },
          {
            key: "neutral",
            name: "Neutral",
            color: colors[2],
            counts: [],
          },
          {
            key: "satisfied",
            name: "Satisfied",
            color: colors[3],
            counts: [],
          },
          {
            key: "very_satisfied",
            name: "Very satisfied",
            color: colors[4],
            counts: [],
          },
        ],
        dates: [],
      };

      // NOTE: reversing here so that the stacks matches the coloring of other distribution charts
      stackedAreaValues.series = stackedAreaValues.series.reverse();

      // Formatting the trend data to match the required format the stacked area chart needs
      this.trends.values.forEach((val) => {
        stackedAreaValues.dates.push(val.date);

        stackedAreaValues.series.forEach((breakdownValue) => {
          breakdownValue.counts.push(val.breakdown[breakdownValue.key]);
        });
      });

      // NOTE: reversing here so that the stacks matches the order of the distribution chart above
      stackedAreaValues.series = stackedAreaValues.series.reverse();

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: xEnd,
        tooltipHtml(d, cd) {
          const params = {
            heading: new Intl.DateTimeFormat("en-US", {
              month: "short",
              year: "numeric",
            }).format(d),
            rows: cd
              .map((r) => ({
                label: r.name,
                value: r.count,
                formattedValue: Math.round(r.percentage * 100) + "%",
                color: r.color,
              }))
              .reverse(),
          };

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: stackedAreaValues,
      };

      return new StackedAreaChart(attrs);
    },
    isSortOrder(order) {
      return this.sortBy == order;
    },
    get breakdownItems() {
      if (this.drilldownData.team_breakdown) {
        const items = this.drilldownData.team_breakdown.data.result_items.sort(
          sortFunc(this.drilldownSortBy, this.compareTo, {
            reverse: this.drilldownSortReverse,
          }),
        );

        const fieldsWithReversibleSortFunctions = [
          "score",
          "calculatedComparison",
          "dxiComparison",
        ];

        if (
          !fieldsWithReversibleSortFunctions.includes(this.drilldownSortBy) &&
          this.drilldownSortReverse
        ) {
          return items.reverse();
        }

        return items;
      }

      return [];
    },
    isDrilldownSortOrder(order) {
      return this.drilldownSortBy == order;
    },
    changeSortBy(sortBy) {
      if (this.sortBy == sortBy) {
        this.$store.snapshotResultFilters.sortReverse = !this.sortReverse;

        setQueryStringParam("sr", this.sortReverse);
        return;
      }

      this.$store.snapshotResultFilters.sortBy = sortBy;
      this.$store.snapshotResultFilters.sortReverse = false;

      setQueryStringParam("sb", sortBy);
      setQueryStringParam("sr", false);
    },
    changeDrilldownSortBy(sortBy) {
      if (this.drilldownSortBy == sortBy) {
        this.$store.snapshotResultFilters.drilldownSortReverse =
          !this.drilldownSortReverse;

        setQueryStringParam("ddsr", this.drilldownSortReverse);
        return;
      }

      this.$store.snapshotResultFilters.drilldownSortBy = sortBy;
      this.$store.snapshotResultFilters.drilldownSortReverse = false;

      setQueryStringParam("ddsb", sortBy);
      setQueryStringParam("ddsr", false);
    },
    get drilldownComparsionVal() {
      return this.selectedCsat?.vs_previous
        ? Math.round(this.selectedCsat.vs_previous)
        : null;
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;
      this.determineSquadNamesFromSquadIds();
      setQueryStringParam("squad_ids", squadIds);

      this.fetchAll();
    },
    changeTagIds(tagIds) {
      this.$store.snapshotResultFilters.tagIds = tagIds;
      setQueryStringParam("tag_ids", tagIds);

      this.fetchAll();
    },
    refetchTeamBreakdown() {
      const selectedItem = drilldownTabs.find(
        (t) => t.tab == this.selectedDrilldownTab,
      );

      if (selectedItem) {
        this.fetchTab(this.selectedDrilldownTab, selectedItem.url);
      }
    },
    get comments() {
      if (this.commentSearchResults.length > 0)
        return this.commentSearchResults;

      if (!this.drilldownData.comments?.data) return [];

      return this.drilldownData.comments.data;
    },
    get noSearchResults() {
      return (
        this.commentSearchResults.length == 0 &&
        this.commentSearchTerm &&
        this.searchCommentsLoaded
      );
    },
    performCommentSearch() {
      this.searchCommentsLoaded = false;
      const finalTerm = this.commentSearchTerm.trim();

      if (finalTerm.length > 2) {
        const searchParams = {
          term: finalTerm,
        };

        const fullUrl = `${commentsSearchUrl}${this.queryParams()}&${objectToQueryString(searchParams)}`;

        return fetch(fullUrl)
          .then((resp) => resp.json())
          .then((data) => {
            this.commentSearchResults = data;
            this.searchCommentsLoaded = true;
          })
          .catch((error) => {
            console.error("Error fetching items", error);
          });
      } else {
        this.commentSearchResults = [];
      }
    },
    clearSearchTerm() {
      this.commentSearchTerm = "";
      this.commentSearchResults = [];
      this.searchCommentsLoaded = false;
    },
  };
}

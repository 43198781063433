export function getDirectChildren(root) {
  const rootDepth = parseInt(root.dataset.depth);
  const children = [];

  let row = root.nextElementSibling;

  while (row && parseInt(row.dataset.depth) > rootDepth) {
    if (parseInt(row.dataset.depth) == rootDepth + 1) {
      children.push(row);
    }

    row = row.nextElementSibling;
  }

  return children;
}

export function getRecursiveChildren(root) {
  const rootDepth = parseInt(root.dataset.depth);
  const children = [];

  let row = root.nextElementSibling;

  while (row && parseInt(row.dataset.depth) > rootDepth) {
    children.push(row);

    row = row.nextElementSibling;
  }

  return children;
}

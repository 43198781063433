import Sortable from "sortablejs";

export function snapshotSetupAllocation(args) {
  const { questions, selectedQuestions } = args;

  return {
    questions,
    selectedQuestionIds: selectedQuestions.map((q) => q.id),
    maxSlots: 3,
    selections: {},
    originalOrder: questions.map((i) => i.id),
    init() {
      questions.forEach((q) => {
        if (this.selectedQuestionIds.includes(q.id))
          this.selections[q.id] = true;
      });
    },
    get hasChanged() {
      const orderChanged =
        JSON.stringify(this.originalOrder) !==
        JSON.stringify(this.questions.map((i) => i.id));

      const selectionsChanged =
        JSON.stringify(Object.keys(this.selections).sort()) !=
        JSON.stringify(
          this.selectedQuestionIds.map((id) => id.toString()).sort(),
        );

      return orderChanged || selectionsChanged;
    },
    itemsHtml(question) {
      return question.items
        .filter((i) => !i.deleted_at)
        .sort((a, b) => a.order - b.order)
        .map((i, idx) => `<li>[${idx}] ${i.name}</li>`)
        .join("");
    },
    reorderArray(array, order) {
      const newArray = new Array(array.length);
      order.forEach((newIndex, originalIndex) => {
        newArray[newIndex] = array[originalIndex];
      });

      return newArray;
    },
    isSelected(id) {
      return this.selections[id];
    },
    toggleQuestion(id) {
      if (this.selections[id]) {
        delete this.selections[id];
      } else {
        this.selections[id] = true;
      }
    },
    slotsUsed() {
      return Object.keys(this.selections).length;
    },
    slotsLeft() {
      return this.maxSlots - this.slotsUsed();
    },
    isDisabled(id) {
      return !this.selections[id] && this.slotsLeft() <= 0;
    },
    initSortable() {
      const self = this;
      const sortableList = document.getElementById(
        "sortable-allocation-questions",
      );

      new Sortable(sortableList, {
        animation: 150,
        handle: ".sortable-handle",
        onEnd() {
          const order = Array.from(
            self.$refs.sortableList.querySelectorAll(
              ".sortable-allocation-question",
            ),
          )
            .map((q) => q.dataset.idx)
            .map(Number);

          const toRemove = sortableList.querySelectorAll(
            ".sortable-allocation-question",
          );

          // Remove dom elements
          toRemove.forEach((q) => {
            sortableList.removeChild(q);
          });

          // Add dom elements
          const toAdd = self.reorderArray(toRemove, order);
          toAdd.forEach((q) => {
            sortableList.appendChild(q);
          });

          // Update component data
          self.questions = order.map((index) => self.questions[index]);
        },
      });
    },
  };
}

export function paginateList(
  page,
  perPage,
  list,
  eventHandler = "onPageChange",
) {
  const totalPages = Math.ceil(list.length / perPage);
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  const paginatedItems = list.slice(startIndex, endIndex);

  const paginatedData = {
    page,
    perPage,
    totalItems: list.length,
    totalPages: totalPages,
    items: paginatedItems,
  };

  paginatedData.paginatorHtml = paginateListPaginator(
    paginatedData,
    eventHandler,
  );

  return paginatedData;
}

export function paginateListPaginator(paginatedData, eventHandler) {
  if (!paginatedData) return "";

  const { page, perPage, totalItems, totalPages } = paginatedData;

  if (totalPages === 1) return "";

  const start = (page - 1) * perPage + 1;
  const end = Math.min(page * perPage, totalItems);
  const disablePrev = page === 1;
  const disableNext = page === totalPages;

  const html = `
    <div class="text-tiny flex items-center justify-between">
      <div class="text-gray-500 text-xs">
        ${start}–${end} of ${totalItems}
      </div>
      <div>
        <div class="flex items-center space-x-1.5 text-gray-500">
          <button @click="${eventHandler}(${page - 1});" class="${
            disablePrev ? "opacity-60" : ""
          } text-xs font-medium rounded shadow-sm px-2.5 py-1 border" ${
            disablePrev ? "disabled" : ""
          }>
            Previous
          </button>

          <button @click="${eventHandler}(${page + 1});" class="${
            disableNext ? "opacity-60" : ""
          } text-xs font-medium rounded shadow-sm px-2.5 py-1 border" ${
            disableNext ? "disabled" : ""
          }>
            Next
          </button>
        </div>
      </div>
    </div>
  `;

  return html;
}

export function sortableHeaderHtml(isCurrentSort, currentDirIsAsc) {
  return `
    <div class="flex flex-col -space-y-1.5 text-xs -mb-px">
      <i class="-mb-px bx bx-caret-up text-gray-300" :class="{'text-gray-500': ${
        isCurrentSort && currentDirIsAsc
      }}"></i>
      <i class="bx bx-caret-down text-gray-300" :class="{'text-gray-500': ${
        isCurrentSort && !currentDirIsAsc
      }}"></i>
    </div>
  `;
}

import { getRecursiveChildren, getDirectChildren } from "../shared/tree";
import { setLocalStorageItem } from "../shared/utils";

const iconExpanded = "bx-chevron-down";
const iconCollapsed = "bx-chevron-right";

const { currentUser } = window;
const storageKey = `heatmap_${currentUser?.account_id}_v2`;

let expandedSquadIds = {};

const makeCollapsed = (el) => {
  // Change caret icon
  const caret = el.querySelector(`.${iconExpanded}`);

  if (caret) {
    caret.classList.remove(iconExpanded);
    caret.classList.add(iconCollapsed);
  }

  // Mark as collapsed
  el.classList.remove("x-expanded");
};

const makeExpanded = (el) => {
  // Change caret icon
  const caret = el.querySelector(`.${iconCollapsed}`);

  if (caret) {
    caret.classList.remove(iconCollapsed);
    caret.classList.add(iconExpanded);
  }

  // Mark as expanded
  el.classList.add("x-expanded");
};

const setDefaultExpanded = () => {
  // Retrieve expanded squad IDs from localStorage
  const storedSquadIds = JSON.parse(localStorage.getItem(storageKey) || "{}");
  expandedSquadIds = storedSquadIds;
  // Select all parent rows in the heatmap
  const parentRows = Array.from(
    document.querySelectorAll(".x-heatmap-row.x-parent"),
  );

  // Expand rows based on stored expanded squad IDs
  parentRows
    .filter((row) => storedSquadIds[row.dataset.id])
    .forEach(expandHeatmapAccordion);

  // If there's only one element at depth 0, expand it and its children
  const depthZeroElements = Array.from(
    document.querySelectorAll(".x-heatmap-row"),
  ).filter((el) => parseInt(el.dataset.depth) === 0);

  if (
    depthZeroElements.length === 1 &&
    depthZeroElements[0].classList.contains("x-parent")
  ) {
    const rootElement = depthZeroElements[0];
    // Show children
    getDirectChildren(rootElement).forEach((childEl) => {
      childEl.classList.remove("hidden");
    });

    makeExpanded(rootElement);
  }
};

/**
 * Expands the given heatmap row element without toggling. Used for default expansion.
 * @param {HTMLElement} el - The heatmap row element to expand.
 */
const expandHeatmapAccordion = (el) => {
  const root = el.closest("tr");

  // Show children
  getDirectChildren(root).forEach((childEl) => {
    childEl.classList.remove("hidden");
  });

  makeExpanded(root);
};

function toggleHeatmapAccordion(e) {
  const root = e.closest("tr");

  // Behavior for when accordion is expanded
  if (root.classList.contains("x-expanded")) {
    // Hide and collapse children
    getRecursiveChildren(root).forEach((el) => {
      el.classList.add("hidden");

      makeCollapsed(el);
      // Remove from localStorage
      delete expandedSquadIds[el.dataset.id];
      setLocalStorageItem(storageKey, JSON.stringify(expandedSquadIds));
    });

    makeCollapsed(root);
    // Remove from localStorage
    delete expandedSquadIds[root.dataset.id];
    setLocalStorageItem(storageKey, JSON.stringify(expandedSquadIds));

    return;
  }

  // Show children
  getDirectChildren(root).forEach((el) => {
    el.classList.remove("hidden");
  });

  makeExpanded(root);
  // Add to localStorage
  expandedSquadIds[root.dataset.id] = true;
  setLocalStorageItem(storageKey, JSON.stringify(expandedSquadIds));
}

window.toggleHeatmapAccordion = toggleHeatmapAccordion;

document.addEventListener("turbo:load", () => setDefaultExpanded());
document.addEventListener("dx:heatmap:loaded", () => setDefaultExpanded());

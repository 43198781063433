export function snapshotIntakeOutcomes(args) {
  const { values } = args;

  return {
    values,
    focusedFactorId: null,
    init() {
      this.focusNextRow();

      this.$watch("values", () => this.focusNextRow());
    },
    focusNextRow() {
      this.$nextTick(() => {
        const nextRow = document.querySelector(".x-unfilled");

        if (nextRow) this.focusedFactorId = nextRow.dataset.factor;
      });
    },
    formValid() {
      for (const [_, value] of Object.entries(this.values)) {
        if (value === null) return false;
      }

      return true;
    },
    isFilled(factorId) {
      return this.values[factorId];
    },
    isFilledOrFocused(factorId) {
      return this.isFilled(factorId) || factorId == this.focusedFactorId;
    },
  };
}

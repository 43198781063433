import dayjs from "dayjs";
import { postData } from "../../../shared/utils";

export function frontlineAlertSnoozeSelect(args) {
  return {
    selection: undefined,
    customDate: undefined,
    submitting: false,
    expanded: false,
    toggleDropdown() {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.expanded = true;
      }
    },
    get snoozedUntilDisplay() {
      if (!args.pr.snoozed_until) return "";

      if (dayjs(args.pr.snoozed_until).year() != dayjs(new Date()).year()) {
        return dayjs(args.pr.snoozed_until).format("MMM D, YYYY");
      } else {
        return dayjs(args.pr.snoozed_until).format("MMM D");
      }
    },
    formValid() {
      return (
        (!!this.selection && this.selection !== "custom") ||
        (this.selection === "custom" && !!this.customDate)
      );
    },
    clearForm() {
      this.selection = undefined;
      this.customDate = undefined;
      this.expanded = false;
      this.submitting = false;
    },
    unsnooze() {
      this.submitting = true;
      postData(args.endpoint, {
        pull_request_id: args.pr.id,
        selection: "unsnooze",
      }).then(() => {
        this.$store.frontlinePrAlerts.updatePr(args.pr.id, {
          snoozed: false,
          snoozed_until: undefined,
        });
        this.$store.frontlinePrAlerts.calculateCounts();
        this.clearForm();
      });
    },
    snooze() {
      this.submitting = true;
      postData(args.endpoint, {
        pull_request_id: args.pr.id,
        selection: this.selection,
        custom_date: this.customDate,
      }).then((resp) => {
        this.$store.frontlinePrAlerts.updatePr(args.pr.id, {
          snoozed: true,
          snoozed_until: resp.snoozed_until,
        });
        this.$store.frontlinePrAlerts.calculateCounts();
        this.clearForm();
      });
    },
  };
}

import { LineChart } from "@get-dx/d3-charts";
import {
  setQueryStringParam,
  deleteQueryStringParam,
  objectToQueryString,
  sortFunc,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

const ITEMS_PER_PAGE = 100;

export function snapshotResponseCountDetails(url) {
  return {
    responseCountTabs: [{ tab: "Current snapshot" }, { tab: "Trend" }],
    selectedResponseCountTab: "Current snapshot",
    snapshotRequests: [],
    trendData: {},
    openModal: false,
    resultsLoaded: false,
    currentPage: 1,
    get drilldownSortBy() {
      return this.$store.snapshotResultFilters.drilldownSortBy;
    },
    get drilldownSortReverse() {
      return this.$store.snapshotResultFilters.drilldownSortReverse;
    },
    hide() {
      deleteQueryStringParam("ddsb");
      deleteQueryStringParam("ddsr");
      this.openModal = false;
    },
    async fetchDetails() {
      const response = await fetch(`${url}${this.queryParams()}`);
      const data = await response.json();
      this.snapshotRequests = data.snapshot_requests;
      this.trendData = data.trends;
      this.resultsLoaded = true;
      this.currentPage = 1;
      this.$nextTick(() => this.initInfiniteScroll());
    },
    setTab(tab) {
      this.selectedResponseCountTab = tab;
      if (tab == "Trend") setTimeout(() => this.buildTrendChart(), 50);
    },
    queryParams() {
      const paramObj = {
        squad_ids: `${this.squadIds.join(",")}`,
        tag_ids: `${this.tagIds.join(",")}`,
        factor_id: this.targetedFactor,
        branch: this.$store.snapshotResultFilters.branch,
      };

      return `?${objectToQueryString(paramObj)}`;
    },
    get orderedItems() {
      const items = this.snapshotRequests.sort(sortFunc(this.drilldownSortBy));
      return this.drilldownSortReverse ? items.reverse() : items;
    },
    get visibleItems() {
      return this.orderedItems.slice(0, this.currentPage * ITEMS_PER_PAGE);
    },
    loadMoreItems() {
      this.currentPage += 1;
    },
    initInfiniteScroll() {
      const options = {
        root: document.getElementById("snapshot-scroll-container"),
        rootMargin: "200px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            this.visibleItems.length < this.orderedItems.length
          ) {
            this.loadMoreItems();
          }
        });
      }, options);

      const sentinel = document.getElementById("scroll-sentinel");
      if (sentinel) {
        observer.observe(sentinel);
      }

      this.$watch("visibleItems", () => {
        const sentinel = document.getElementById("scroll-sentinel");
        if (sentinel) {
          observer.observe(sentinel);
        }
      });
    },
    changeResponseDetailSortBy(sortBy) {
      if (this.drilldownSortBy == sortBy) {
        this.$store.snapshotResultFilters.drilldownSortReverse =
          !this.drilldownSortReverse;
        setQueryStringParam("ddsr", this.drilldownSortReverse);
      } else {
        this.$store.snapshotResultFilters.drilldownSortBy = sortBy;
        this.$store.snapshotResultFilters.drilldownSortReverse = false;
        setQueryStringParam("ddsb", sortBy);
        setQueryStringParam("ddsr", false);
      }

      // Reset pagination and scroll to top
      this.currentPage = 1;
      document.getElementById("snapshot-scroll-container")?.scrollTo({
        top: 0,
        behavior: "instant",
      });
    },
    buildTrendChart() {
      const chartData = this.trendData;
      const chartMax = Math.max(...chartData.map((obj) => obj.value)) || 0;
      const elChart = document.getElementById(`response-count-trends-chart`);
      elChart.innerHTML = null;

      if (chartData.length > 1) {
        const chartParams = {
          elChart,
          startDate: chartData[0].date,
          endDate: chartData[chartData.length - 1].date,
          yAxisMin: 0,
          yAxisMax: Math.round(chartMax * 1.2),
          tooltipHtml(d) {
            const params = {
              heading: "Participation rate",
              d: {
                label: `Snapshot: ${d.label}`,
                value: d.value,
                formattedValue: `${d.value}%`,
              },
            };

            return chartTooltipHtml.participationTrend(params);
          },
          values: chartData,
        };

        new LineChart(chartParams);
      }
    },
  };
}

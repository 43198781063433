import { eqArrays, addCurrentPageEventListener } from "../../shared/utils";

function isIgnoredField(field) {
  return (
    !field.name ||
    field.type == "submit" ||
    field.type == "button" ||
    field.type == "hidden"
  );
}

export function dirtyForm() {
  let form, initialValues, fields, specialInitialValues, specialValues;
  const message = "You have unsaved changes!";

  return {
    isDirty: false,
    init() {
      form = this.$el;
      initialValues = {};
      specialInitialValues = {};
      specialValues = {};
      fields = [...form.elements];
      this.setupFields();
      this.setFormHandlers();
    },
    setupFields() {
      fields.forEach((field) => {
        if (isIgnoredField(field)) return;

        // Save initial values
        if (field.type == "checkbox" || field.type == "radio") {
          initialValues[field.name + "." + field.value] = field.checked;
        } else {
          initialValues[field.name] = field.value;
        }

        // Set handlers
        addCurrentPageEventListener(
          "change",
          this.checkIfDirty.bind(this),
          field,
        );
        addCurrentPageEventListener(
          "input",
          this.checkIfDirty.bind(this),
          field,
        );
      });

      // Handle special inputs like userMultiselect
      addCurrentPageEventListener(
        "dx:input-init",
        (event) => {
          specialInitialValues[event.detail.name] = event.detail.values;
          specialValues[event.detail.name] = event.detail.values;
        },
        form,
      );
      addCurrentPageEventListener(
        "dx:input-changed",
        (event) => {
          specialValues[event.detail.name] = event.detail.values;

          this.checkIfDirty();
        },
        form,
      );
    },
    setFormHandlers() {
      // Handle submit
      addCurrentPageEventListener("submit", this.resetToNotDirty.bind(this));
      // Leave this for the snapshot settings page so a given form will get reset
      // to not dirty
      addCurrentPageEventListener(
        "submit",
        this.resetToNotDirty.bind(this),
        form,
      );

      // Handle leaving page
      window.onbeforeunload = () => {
        if (this.isDirty) {
          return message;
        }
      };
      addCurrentPageEventListener("turbo:before-visit", (event) => {
        if (this.isDirty && !confirm(message)) {
          event.preventDefault();
        } else {
          this.isDirty = false;
        }
      });
    },
    checkIfDirty() {
      let result = false;

      fields.forEach((field) => {
        if (isIgnoredField(field)) return;

        if (field.type == "checkbox" || field.type == "radio") {
          if (initialValues[field.name + "." + field.value] != field.checked) {
            result = true;
            return;
          }
        } else if (initialValues[field.name] != field.value) {
          result = true;
          return;
        }
      });

      for (const key in specialInitialValues) {
        const v1 = specialInitialValues[key];
        const v2 = specialValues[key];

        if (Array.isArray(v1) || Array.isArray(v2)) {
          if (v1 && v2 && !eqArrays(v1, v2)) result = true;
        } else if (v1 !== v2) {
          result = true;
        }
      }

      this.isDirty = result;
    },
    resetToNotDirty() {
      for (const key in specialInitialValues) {
        specialInitialValues[key] = specialValues[key];
      }

      this.isDirty = false;
    },
  };
}

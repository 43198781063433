export function snapshotIntakeRating(args) {
  const { ratings, length } = args;

  return {
    ratings,
    focusedFactorId: null,
    init() {
      this.focusNextRow();

      this.$watch("ratings", () => this.focusNextRow());
    },
    focusNextRow() {
      this.$nextTick(() => {
        const nextRow = document.querySelector(".x-unfilled");

        if (nextRow) this.focusedFactorId = parseInt(nextRow.dataset.factor);
      });
    },
    isFilled(factorId) {
      return this.ratings[factorId];
    },
    isFilledOrFocused(factorId) {
      return this.isFilled(factorId) || factorId == this.focusedFactorId;
    },
    formValid() {
      return Object.values(this.ratings).length >= length;
    },
  };
}

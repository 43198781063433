import { prevKeys, nextKeys } from "../../shared/utils";

export function multiSelect(args) {
  const { options } = args;
  const originalOptions = options;

  return {
    options,
    selected: [],
    isOpen: false,
    focused: false,
    highlightedIndex: -1,

    toggle(option) {
      const idx = option.index;
      if (this.selected.includes(idx)) {
        this.selected = this.selected.filter((i) => i !== idx);
        this.options = originalOptions.filter(
          (o) => !this.selected.includes(o.index),
        );
      } else {
        this.selected.push(idx);
        this.options = this.options.filter((o) => o.index !== idx);
      }

      if (this.options.length === 0) {
        this.isOpen = false;
        this.highlightedIndex = -1;
      }
    },
    isSelected(option) {
      return this.selected.includes(option.index);
    },
    getOptionName(index) {
      const option = originalOptions.find((o) => o.index === index);
      return option ? option.name : "";
    },
    focus() {
      this.focused = true;
    },
    unfocus() {
      setTimeout(() => {
        if (!this.isOpen) {
          this.focused = false;
          this.highlightedIndex = -1;
        }
      }, 100);
    },
    handleInputKeydown(event) {
      if (!this.focused) this.focus(); // Handle when component is tabbed into

      if (event.key == "Backspace") {
        this.selected.pop();
        this.options = originalOptions.filter(
          (o) => !this.selected.includes(o.index),
        );

        return;
      }

      if (event.key == "Enter") {
        event.preventDefault();

        if (this.isOpen) {
          const option = this.options[this.highlightedIndex];
          if (option) {
            this.toggle(option);
          }
        } else {
          this.isOpen = true;
        }

        return;
      }

      if (event.key == "Escape") {
        event.preventDefault();
        this.isOpen = false;
        this.highlightedIndex = -1;
        return;
      }

      if (nextKeys.includes(event.key) && this.isOpen) {
        event.preventDefault();

        this.highlightedIndex =
          (this.highlightedIndex + 1) % this.options.length;
        this.scrollToHighlightedOption();

        return;
      }

      if (prevKeys.includes(event.key) && this.isOpen) {
        event.preventDefault();

        this.highlightedIndex =
          (this.highlightedIndex - 1 + this.options.length) %
          this.options.length;
        this.scrollToHighlightedOption();

        return;
      }
    },
    scrollToHighlightedOption() {
      this.$nextTick(() => {
        const optionElements = this.$refs.dropdown.querySelectorAll("li");
        if (optionElements[this.highlightedIndex]) {
          optionElements[this.highlightedIndex].scrollIntoView({
            block: "nearest",
          });
        }
      });
    },
  };
}

import Sortable from "sortablejs";

export function selfReportedTag(args) {
  const {
    placeholder = "",
    tagGroupName = "",
    surveyPrompt = "",
    arrTags = [],
    selectedOption = -2,
    showDeleteModal = false,
    deleteConfirmText = "",
  } = args;

  return {
    init() {
      const self = this;
      const sortableList = document.getElementById("sortable-list");
      const editableContents = document.querySelectorAll(".editable-content");

      const sortableInstance = new Sortable(sortableList, {
        animation: 150,
        handle: ".handle",
        onStart() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "false"),
          );
        },
        onEnd() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "true"),
          );

          const order = Array.from(
            self.$refs.sortableList.querySelectorAll(".sortable-item"),
          )
            .map((item) => item.dataset.optionId)
            .map(Number);

          // reverse Sortable's DOM updates
          const itemsToRemove = sortableList.querySelectorAll(".sortable-item");
          itemsToRemove.forEach((item) => {
            sortableList.removeChild(item);
          });

          const itemsToAdd = self.reorderArray(itemsToRemove, order);
          itemsToAdd.forEach((item) => {
            sortableList.appendChild(item);
          });

          // perform DOM updates via Alpine reactivity
          self.arrTags = order.map((index) => self.arrTags[index]);
        },
      });

      editableContents.forEach((content) => {
        content.addEventListener("focus", () => {
          sortableInstance.option("disabled", true);
        });

        content.addEventListener("blur", () => {
          sortableInstance.option("disabled", false);
        });
      });
    },

    tagGroupName,
    surveyPrompt,
    placeholder,
    arrTags,
    selectedOption,
    showDeleteModal,
    deleteConfirmText,
    removeEntry(event) {
      const itemPosition =
        event.target.closest(".sortable-item").dataset.optionId;
      this.arrTags = this.arrTags.filter(
        (_row, index) => index !== parseInt(itemPosition),
      );
    },
    addEntry() {
      this.arrTags.push({
        name: "",
      });

      this.$nextTick(() => {
        this.$refs["newOption"].focus();
      });
    },
    isDuplicate(name) {
      return this.arrTags.filter((tag) => tag.name === name).length > 1;
    },
    hasDuplicate() {
      return this.arrTags.some((tag) => this.isDuplicate(tag.name));
    },
    updateName(event, option) {
      const updatedName = event.target.textContent;
      option.name = updatedName;
    },
    canAddOption() {
      // max number of options is 11
      return this.arrTags.length < 11;
    },
    reorderArray(array, order) {
      const newArray = new Array(array.length);
      order.forEach((newIndex, originalIndex) => {
        newArray[newIndex] = array[originalIndex];
      });

      return newArray;
    },
    formValid() {
      return (
        this.tagGroupName &&
        this.surveyPrompt &&
        this.arrTags.filter((tag) => tag.name.trim() !== "").length >= 2 &&
        !this.hasDuplicate()
      );
    },
  };
}

export function datacloudSchema({ defaultTable }) {
  return {
    tableName: null,
    init() {
      this.tableName = getQueryParam("tableName") || defaultTable;
    },
    setTableName(tableName) {
      // Update url query param without reloading the page
      const url = new URL(window.location);
      url.searchParams.set("tableName", tableName);
      window.history.pushState({}, "", url);

      this.tableName = tableName;
    },
  };
}

function getQueryParam(key) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
}

import { outOfView, prevKeys, nextKeys, navKeys } from "../../shared/utils";

export function userMultiselect(selectedUsers, excludedIds, opts = {}) {
  return {
    focused: false,
    focusedUser: null,
    searchText: "",
    searchResults: [],
    selectedUsers,
    opts,
    init() {
      const form = this.$root.closest("form");

      if (form) {
        const selectedUserIds = this.selectedUsers.map((user) => user.id);
        const event = new CustomEvent("dx:input-init", {
          detail: { name: "userMultiSelect", values: selectedUserIds },
        });
        form.dispatchEvent(event);

        this.$watch("selectedUsers", () => this.publishValues(form));
      }

      if (opts.onChange) {
        this.$watch("selectedUsers", () => {
          opts.onChange(this.selectedUsers);
        });
      }
    },
    publishValues(form) {
      const selectedUserIds = this.selectedUsers.map((user) => user.id);
      const event = new CustomEvent("dx:input-changed", {
        detail: { name: "userMultiSelect", values: selectedUserIds },
      });

      form.dispatchEvent(event);
    },
    get showSearchResults() {
      return (
        this.focused &&
        (this.searchText.length > 0 || this.searchResults.length > 0)
      );
    },
    search(event) {
      if (event && (navKeys.includes(event.key) || event.key == "Enter"))
        return;

      this.focusedUser = null;

      const exclusions = excludedIds.concat(
        this.selectedUsers.map((u) => u.id),
      );

      window.userSearch.search(this.searchText, exclusions).then((results) => {
        this.searchResults = [];

        this.searchResults = results.slice(
          0,
          this.searchText.length > 0 ? 25 : 5,
        );

        if (this.searchResults.length > 0) {
          this.focusedUser = this.searchResults[0];
        }
      });
    },
    selectUser(user) {
      this.selectedUsers.push(user);

      this.searchText = "";

      this.focusedUser = null;

      this.$nextTick(() => {
        this.search();
      });
    },
    deselectUser(event, id) {
      // Prevent click event from button from triggering focus
      event.stopPropagation();

      this.selectedUsers = this.selectedUsers.filter((user) => {
        return user.id != parseInt(id);
      });

      this.unfocus();
    },
    handleInputKeydown(event) {
      if (!this.focused) this.focus(); // Handle when component is tabbed into

      if (event.key == "Backspace" && event.target.value.length == 0) {
        this.selectedUsers.pop();

        return;
      }

      if (event.key == "Enter") {
        event.preventDefault();

        if (this.focusedUser) this.selectUser(this.focusedUser);

        return;
      }

      if (nextKeys.includes(event.key) && this.showSearchResults) {
        event.preventDefault();

        if (this.focusedUser == null) {
          this.focusedUser = this.searchResults[0];
        } else {
          const index = this.searchResults.findIndex((user) => {
            return user.id == this.focusedUser.id;
          });

          const next = this.searchResults[index + 1];

          if (next) {
            this.focusedUser = next;

            const ul = this.$refs.searchResultsContainer;
            const li = ul.querySelector(`[data-userId="${next.id}"]`);

            if (outOfView(ul, li)) {
              ul.scrollTo(0, li.offsetTop);
            }
          }
        }

        return;
      }

      if (prevKeys.includes(event.key) && this.showSearchResults) {
        event.preventDefault();

        if (this.focusedUser) {
          const index = this.searchResults.findIndex((user) => {
            return user.id == this.focusedUser.id;
          });

          const prev = this.searchResults[index - 1];

          if (prev) {
            this.focusedUser = prev;

            const ul = this.$refs.searchResultsContainer;
            const li = ul.querySelector(`[data-userId="${prev.id}"]`);

            ul.scrollTo(0, li.offsetTop);
          }
        }
      }

      if (event.keyCode == 9) {
        event.preventDefault();
      }
    },
    focus() {
      this.focused = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    unfocus() {
      this.focused = false;
      this.searchText = "";
      this.$refs.searchInput.blur();
    },
  };
}

export function teamsList() {
  let metadata;

  return {
    open: true,
    teamsListLoaded: false,
    init() {
      fetch("/teams/metadata")
        .then((response) => response.json())
        .then((data) => {
          metadata = data.metadata;
          this.teamsListLoaded = true;
        });
    },
    getSquad(squad) {
      return metadata[squad.id] || {};
    },
  };
}

import {
  getQueryStringParams,
  setQueryStringParam,
  deleteQueryStringParam,
} from "../../../shared/utils";

export function studiesTable(args) {
  return {
    studies: args.studies,
    searchTerm: getQueryStringParams().get("q") || "",
    init() {},
    get items() {
      let val = this.studies;

      if (this.searchTerm) {
        const normalizedTearm = this.searchTerm.trim().toLowerCase();
        val = val.filter((study) => {
          return (
            study.name.toLowerCase().includes(normalizedTearm) ||
            study.creator?.name?.toLowerCase()?.includes(normalizedTearm)
          );
        });
      }

      return val;
    },
    onSearch(e) {
      this.searchTerm = e.target.value?.trim();

      if (this.searchTerm?.length) {
        setQueryStringParam("q", this.searchTerm);
      } else {
        deleteQueryStringParam("q");
      }
    },
  };
}

export const disableOnClick = (el) => {
  el.addEventListener("click", () => {
    setTimeout(() => {
      el.disabled = true;
    });

    el.classList.add("btn-disabled");

    el.classList.remove("btn-default");
    el.classList.remove("btn-standout");
    el.classList.remove("btn-outline");
    el.classList.remove("btn-inverted");
    el.classList.remove("btn-outline-danger");
  });
};

export const disableOnValidClick = (el) => {
  el.addEventListener("click", () => {
    const form = el.closest("form");

    if (form && form.checkValidity()) {
      form.addEventListener(
        "submit",
        () => {
          setTimeout(() => {
            el.disabled = true;
          });

          el.classList.add("btn-disabled");
          el.classList.remove(
            "btn-default",
            "btn-standout",
            "btn-outline",
            "btn-inverted",
            "btn-outline-danger",
          );
        },
        { once: true },
      );
    }
  });
};

const _elementIsInViewport = (element) => {
  let bounding = element.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

const _isScrolledToBottom = (element) => {
  const totalPageHeight = element.scrollHeight;
  const scrollPoint = element.scrollTop + element.clientHeight;
  return scrollPoint + 30 >= totalPageHeight;
};

export function atlasArticle() {
  const indexArticleContent = function () {
    const headers = document.getElementsByTagName("h2");
    let toc = [];
    for (let i = 0; i < headers.length; i++) {
      const el = headers[i];

      const headerText = el.textContent;
      const id = headerText
        .replace(/[^a-z0-9\s]/gi, "")
        .split(" ")
        .join("-")
        .toLowerCase();
      el.id = id;
      toc.push({ title: headerText, id: id });

      // Tag all elements in this section, until we hit the next header
      let sectionContent = el.nextElementSibling;
      while (sectionContent && sectionContent.tagName !== "H2") {
        sectionContent.dataset.section = id;
        sectionContent.classList.add("content-to-check-if-visible");
        sectionContent = sectionContent.nextElementSibling;
      }
    }

    return toc;
  };

  const highlightCurrentSection = function () {
    // Mark everything as not visible to start
    this.tableOfContents.forEach((toc) => {
      toc.visible = false;
    });

    // If we're at the bottom of the page, mark the last section as visible and return
    if (_isScrolledToBottom(document.querySelector("main"))) {
      this.tableOfContents[this.tableOfContents.length - 1].visible = true;
      return;
    }

    // Iterate over all sections
    const content = document.querySelectorAll(".content-to-check-if-visible");
    for (let i = 0; i < content.length; i++) {
      const element = content[i];
      const sectionId = element.dataset.section;
      if (!sectionId) continue;

      if (_elementIsInViewport(element)) {
        const tocItem = this.tableOfContents.find((t) => t.id === sectionId);
        if (tocItem) tocItem.visible = true;
        return;
      }
    }

    // If nothing has been highlighted at this point, we must be at the top of the page
    this.tableOfContents[0].visible = true;
  };

  return {
    tableOfContents: [],
    init() {
      this.tableOfContents = indexArticleContent();
      highlightCurrentSection.bind(this)();
    },
    initHandlers() {
      window.addEventListener(
        "resize",
        highlightCurrentSection.bind(this),
        false,
      );
      document
        .querySelector("main")
        .addEventListener("scroll", highlightCurrentSection.bind(this), false);
    },
    jumpTo(id) {
      window.location.hash = "";
      window.location.hash = id;
    },
  };
}

export function updateSnapshotSettings(args) {
  const { adminIds, scheduledDate } = args;

  return {
    adminIds,
    scheduledDate,
    init() {
      // Trigger dirty form
      const form = this.$root.querySelector("form");
      const event = new Event("dx:form-changed");
      this.$watch("selectedFactors", () => form.dispatchEvent(event));
    },
    changed(field, value) {
      if (field == "scheduledDate") {
        return this.scheduledDate != value;
      }
      if (field == "adminIds") {
        return this.adminIds != value;
      }
    },
  };
}

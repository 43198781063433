import { setQueryStringParam, sortFunc } from "../../shared/utils";

export function dxiTeamBreakdown(args) {
  const { dxiItems, dxi50, dxi75, dxi90, orgDxiScore } = args;

  return {
    dxiItems,
    get orderedItems() {
      if (this.dxiItems) {
        const items = this.dxiItems.sort(
          sortFunc(this.drilldownSortBy, this.compareTo, {
            dxi50,
            dxi75,
            dxi90,
            orgDxiScore,
          }),
        );

        if (this.drilldownSortReverse) {
          return items.reverse();
        }

        return items;
      }

      return [];
    },
    get compareTo() {
      return this.$store.snapshotResultFilters.compareTo;
    },
    get drilldownSortBy() {
      return this.$store.snapshotResultFilters.drilldownSortBy;
    },
    get drilldownSortReverse() {
      return this.$store.snapshotResultFilters.drilldownSortReverse;
    },
    calculateDxiComparison(item) {
      if (this.compareTo == "vs_50th") {
        // Benchmark score excludes custom drivers
        return item.benchmark_score - dxi50;
      } else if (this.compareTo == "vs_75th") {
        // Benchmark score excludes custom drivers
        return item.benchmark_score - dxi75;
      } else if (this.compareTo == "vs_90th") {
        // Benchmark score excludes custom drivers
        return item.benchmark_score - dxi90;
      } else if (this.compareTo == "vs_prev") {
        return item.previous_score != null
          ? item.score - item.previous_score
          : null;
      } else if (this.compareTo == "vs_org") {
        return item.score - orgDxiScore;
      }
    },
    changeDrilldownSortBy(sortBy) {
      if (this.drilldownSortBy == sortBy) {
        this.$store.snapshotResultFilters.drilldownSortReverse =
          !this.drilldownSortReverse;

        setQueryStringParam("ddsr", this.drilldownSortReverse);
        return;
      }

      this.$store.snapshotResultFilters.drilldownSortBy = sortBy;
      this.$store.snapshotResultFilters.drilldownSortReverse = false;

      setQueryStringParam("ddsb", sortBy);
      setQueryStringParam("ddsr", false);
    },
  };
}

import { paginateList, sortableHeaderHtml } from "./utils";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../../shared/utils";

export function frontlineUiPaginatedPrs(args) {
  let {
    prs = [],
    initialSort = "title",
    initialSortDir = "asc",
    perPage = 10,
    includeDrafts = true,
    repo = null,
    lsKey = null,
  } = args;

  if (lsKey) {
    initialSort = getLocalStorageItem(`${lsKey}-sort`, initialSort);
    initialSortDir = getLocalStorageItem(`${lsKey}-sortDir`, initialSortDir);
    includeDrafts =
      getLocalStorageItem(`${lsKey}-drafts`, includeDrafts) !== "false";
  }

  return {
    prs,
    sort: initialSort,
    sortDir: initialSortDir,
    paginatedData: { perPage },
    filters: { includeDrafts, repo },
    init() {
      this.updateList();
    },
    updateList() {
      this.filterAndSort();
      this.onPageChange(1);

      if (lsKey) {
        setLocalStorageItem(`${lsKey}-sort`, this.sort);
        setLocalStorageItem(`${lsKey}-sortDir`, this.sortDir);
        setLocalStorageItem(`${lsKey}-drafts`, this.filters.includeDrafts);
      }
    },
    onPageChange(page) {
      this.paginatedData = paginateList(page, perPage, this.prs);
    },
    changeSort(key, defaultDir = "desc") {
      if (key !== this.sort) {
        this.sort = key;
        this.sortDir = defaultDir;
      } else {
        this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
      }

      this.updateList();
    },
    setSort(key, dir) {
      this.sort = key;
      this.sortDir = dir;

      this.updateList();
    },
    changeFilter(key, val) {
      this.filters[key] = val;
      this.updateList();
    },
    filterAndSort() {
      let val = prs;

      if (!this.filters.includeDrafts) {
        val = val.filter((pr) => pr.status !== "Draft");
      }

      if (this.filters.repo && this.filters.repo !== "all_repos") {
        val = val.filter((pr) => pr.repo === this.filters.repo);
      }

      if (this.sort === "author")
        val = val.sort((a, b) => a.author_name.localeCompare(b.author_name));
      if (this.sort === "age")
        val = val.sort((a, b) => new Date(b.opened) - new Date(a.opened));
      if (this.sort === "size")
        val = val.sort((a, b) => a.total_size - b.total_size);
      if (this.sort === "staleness")
        val = val.sort((a, b) => new Date(b.updated) - new Date(a.updated));
      if (this.sort === "merged")
        val = val.sort((a, b) => new Date(a.merged) - new Date(b.merged));

      if (this.sortDir === "desc") val = val.reverse();

      this.prs = [...val];
    },
    sortableHtml(column) {
      const isCurrentSort = column === this.sort;
      const isAsc = this.sortDir === "asc";
      return sortableHeaderHtml(isCurrentSort, isAsc);
    },
  };
}

import {
  addCurrentPageEventListener,
  objectToQueryString,
} from "../../shared/utils";

export function histogramChart(args) {
  const { chartName, endpoint, chartIndex } = args;
  let fetching = false;

  return {
    histogramData: [],
    showHistogram: false,
    histogramLoaded: false,
    rawSql: "",
    showQueryModal: false,
    init() {
      addCurrentPageEventListener("dx:datacloud-filters-changed", (e) => {
        if (this.showHistogram) {
          this.fetchAndDisplayHistogram(e.detail.filters);
        }
      });
    },
    fetchAndDisplayHistogram(filters, index) {
      if (chartIndex !== undefined && chartIndex !== index) return;

      this.histogramData = []; // if we don't clear it, it won't re-render
      this.showHistogram = true;
      this.histogramLoaded = false;
      if (fetching) return;

      fetching = true;
      fetch(
        `${endpoint}${
          chartName ? `/${chartName}` : ""
        }/histogram?${objectToQueryString(filters)}`,
      )
        .then((response) => {
          fetching = false;
          if (response.ok) return response.json();

          alert("Something went wrong. Please reload the page and try again.");
        })
        .then((responseData) => {
          this.histogramData = responseData.data;
          this.rawSql = responseData.sql;
          this.histogramLoaded = true;
        })
        .catch(() => {
          alert(
            "Your connection dropped. Please reload the page and try again.",
          );
        });
    },
    maxOfSet() {
      if (Array.isArray(this.histogramData) && this.histogramData.length > 0) {
        return Math.max(...this.histogramData.map((d) => d.count));
      }
      return 1; // default value to avoid division by zero or NaN
    },
    showHistogramQueryModal() {
      this.showQueryModal = true;
    },
  };
}

export function onboardingResponseModal(path) {
  return {
    responseModalContent: "",
    isOpen: false,
    open() {
      fetch(path)
        .then((response) => response.text())
        .then((data) => {
          this.responseModalContent = data;
          this.isOpen = true;
        });
    },
  };
}

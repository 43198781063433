export function frontlineGroupSelector({ selectedId }) {
  return {
    selectedId,
    tab: "my_groups",
    open: false,
    loaded: false,
    myGroups: [],
    allGroups: [],
    searchResults: [],
    onOpen() {
      this.open = true;
      this.focusSearch();

      if (this.loaded) return;

      fetch("/frontline/groups/selector")
        .then((response) => response.json())
        .then((resp) => {
          this.myGroups = resp.my_groups;
          this.allGroups = resp.all_groups;
          this.searchResults = this.allGroups;

          const showMyGroups = this.myGroups
            .map((g) => g.encoded_id)
            .includes(selectedId);

          this.tab = showMyGroups ? "my_groups" : "all_groups";
          this.focusSearch();

          this.loaded = true;
        });
    },
    changeTab(tab) {
      if (this.tab === tab) return;

      this.tab = tab;
      this.focusSearch();
    },
    focusSearch() {
      if (this.tab === "all_groups") {
        this.$nextTick(() => {
          // focus() isn't working w/o waiting a little longer after nextTick.
          setTimeout(() => {
            this.$refs.searchInput?.focus();
          }, 50);
        });
      }
    },
    isSelected(group) {
      return group.encoded_id === selectedId;
    },
    search() {
      const searchTerm = this.$refs.searchInput.value;
      let matchingGroups = this.allGroups;

      if (searchTerm.trim().length > 0) {
        matchingGroups = matchingGroups.filter((group) => {
          return group.name
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase());
        });
      }

      this.searchResults = matchingGroups;
    },
  };
}

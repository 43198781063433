import { LineChart } from "@get-dx/d3-charts";
export function frontlineGroupPulse(chartId, endpoint) {
  return {
    loaded: false,
    data: [],
    groupSize: undefined,
    hasAnyActivity: undefined,
    chartId,
    init() {
      fetch(endpoint)
        .then((response) => response.json())
        .then((resp) => {
          this.data = resp.results;
          this.groupSize = resp.group_size;
          this.loaded = true;

          this.$nextTick(() => {
            this.buildChart();
          });
        });
    },
    buildChart() {
      let hasAnyActivity = false;
      const maxVal = this.groupSize * 10;
      const normalizedData = [];

      this.data.forEach((d) => {
        if (d.value > 0) hasAnyActivity = true;
        normalizedData.push({ date: d.date, value: Math.min(d.value, maxVal) });
      });

      this.hasAnyActivity = hasAnyActivity;

      const el = document.getElementById(chartId);

      if (!el) return;

      new LineChart({
        elChart: document.getElementById(chartId),
        startDate: this.data[0].date,
        endDate: this.data[this.data.length - 1].date,
        showXAxisTicks: false,
        showXAxisLine: false,
        yAxisMin: 0,
        yAxisMax: maxVal,
        values: normalizedData,
      });
    },
    maxOfSet() {
      return Math.max(...this.data.map((d) => d.value));
    },
  };
}

import tippy from "tippy.js";
import { userAvatar } from "../userAvatar";

export function userDetail(user, account) {
  return {
    init() {
      const buttonStyle =
        "text-gray-700 font-medium text-sm w-0 grow hover:bg-gray-50 rounded border border-gray-300 flex items-center justify-center space-x-1 py-1";
      const messageButtons = [];

      if (account.integration === "slack" && user.slack_ext_id) {
        const slackButtonHref = `slack://user?team=${account.slack_team.ext_id}&id=${user.slack_ext_id}`;
        const slackButton = `
          <a class='${buttonStyle} items-center justify-center space-x-1 py-1' href='${slackButtonHref}'>
            <i class='bx bxl-slack'></i>
            <span>
              Message
            </span>
          </a>
        `;

        messageButtons.push(slackButton);
      }

      if (account.integration === "msteams") {
        const msTeamsButtonHref = `https://teams.microsoft.com/l/chat/0/0?users=${encodeURIComponent(
          user.email,
        )}`;
        const msTeamsButton = `
          <a class='${buttonStyle} items-center justify-center space-x-1 py-1' href='${msTeamsButtonHref}'>
            <i class='bx bxl-microsoft-teams'></i>
            <span>
              Message
            </span>
          </a>
        `;

        messageButtons.push(msTeamsButton);
      }

      if (user.email) {
        const emailButton = `
          <button
            type='button'
            @click="
              navigator.clipboard.writeText(\`${user.email}\`).then(() => {
                pushNotification({
                  title: 'Email copied to clipboard!',
                  desc: 'Paste it wherever you\\'d like.',
                });
              })
            "
            class='${buttonStyle}'
          >
            <i class='bx bxs-paper-plane'></i>
            <span>Email</span>
          </button>
        `;

        messageButtons.push(emailButton);
      }

      tippy(this.$el, {
        interactive: true,
        allowHTML: true,
        arrow: false,
        delay: [250, 0],
        placement: "top-start",
        trigger: "mouseenter",
        content: `
          <div class='w-60 text-sm text-left'>
            <div class='flex items-center space-x-2'>
              ${userAvatar(user, { class: "h-10 w-10" })}
              <div>
                <div class='text-sm font-medium text-gray-700'>
                  ${user.name}
                </div>
                <div class='text-3xs text-gray-500'>
                  ${user.email}
                </div>
              </div>
            </div>
            <div class='flex items-center space-x-2 mt-3.5'>
              ${messageButtons.join("")}
            </div>
          </div>
        `,
      });
    },
  };
}

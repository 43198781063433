import { LineChart, ScatterTimeChart } from "@get-dx/d3-charts";
import { chartTooltipHtml } from "../chartTooltipHtml";

export function onboardingChart(data, timeframeStart, timeframeEnd) {
  const chartTypeLsKey = `DX-onboarding-${data.title}-chartType`;
  const valueTypeLsKey = `DX-onboarding-${data.title}-value`;
  const elId = `${data.title.replace(/ /g, "_")}-d3chart`;
  const chartTypes = Object.keys(data.chart_data);
  const normalizedSuffix = data.suffix || "";
  const hasTopValue = data.values !== undefined;
  let initialChartType = chartTypes[0];
  let initialValType = Object.keys(data.values || {})[0];

  const chartTypeLocalStorage = localStorage.getItem(chartTypeLsKey);
  if (chartTypeLocalStorage && data.chart_data[chartTypeLocalStorage])
    initialChartType = chartTypeLocalStorage; // Make sure local storage val is still valid

  const valTypeLocalStorage = localStorage.getItem(valueTypeLsKey);
  if (valTypeLocalStorage && data.values[valTypeLocalStorage])
    initialValType = valTypeLocalStorage;

  return {
    data,
    elId,
    multiChartSupport: chartTypes.length > 1,
    chartType: initialChartType,
    valueType: initialValType,
    hasTopValue,
    valTypesExpanded: false,
    normalizedSuffix,
    get value() {
      if (!hasTopValue) return null;

      let value;
      if (data.values == undefined) {
        value = data.value;
      } else {
        value = data.values[this.valueType];
      }

      return value;
    },
    comparison() {
      const diff = parseFloat(this.value - data.comparison.value).toFixed(1);
      const diffAbs = Math.abs(diff);

      let change = "";
      if (data.comparison.is_speed) {
        change = `${diffAbs}${normalizedSuffix} ${
          diff <= 0 ? "faster" : "slower"
        }`;
      } else {
        change = `${diff >= 0 ? "+" : "-"}${diffAbs}`;
      }

      let colors = "";
      const colorMap = {
        good: "text-blue-500",
        bad: "text-yellow-600",
        neutral: "text-gray-600",
      };
      if (data.comparison.is_speed) {
        colors = diff < 0 ? colorMap.good : colorMap.bad;
        if (diff == 0) colors = colorMap.neutral;
      } else {
        colors = diff > 0 ? colorMap.good : colorMap.bad;
        if (diff == 0) colors = colorMap.neutral;
      }

      return {
        change,
        colors,
      };
    },
    initD3Chart() {
      const el = document.getElementById(elId);
      if (!el) return;

      el.innerHTML = null;

      if (this.chartType === "scatterplot") {
        new ScatterTimeChart({
          elChart: el,
          values: data.chart_data.scatterplot,
          startDate: timeframeStart,
          endDate: timeframeEnd,
          showTrendline: true,
          benchmarkValue: data.comparison?.value,
          tooltipHtml: (d) => chartTooltipHtml.onboarding(d),
        });
      }

      if (this.chartType === "line") {
        new LineChart({
          elChart: el,
          values: data.chart_data.line,
          startDate: data.chart_data.line[0].date,
          endDate: data.chart_data.line[data.chart_data.line.length - 1].date,
          tooltipHtml(d) {
            return `
              <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5'>
                <div class='flex border-b pb-1.5 mb-1'>
                  <div class='grow font-medium'>
                    ${d.formatted_date}
                  </div>
                </div>
                ${d.value} hires
              </div>
            `;
          },
        });
      }
    },
    maxOfSet() {
      return Math.max(...data.chart_data.bar.map((d) => d.y));
    },
    totalOfSet() {
      let total = 0;
      data.chart_data.bar.forEach((d) => (total += d.y));
      return total;
    },
    setChartType(type) {
      this.chartType = type;
      localStorage.setItem(chartTypeLsKey, type);
      this.initD3Chart();
    },
    setValueType(type) {
      this.valueType = type;
      localStorage.setItem(valueTypeLsKey, type);
    },
    adminOnlyVariant(variant) {
      return data.restricted_variants?.includes(variant);
    },
  };
}

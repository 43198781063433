export function frontlineAsyncModal(baseEndpoint) {
  return {
    baseEndpoint,
    loaded: false,
    data: [],
    pagination: {},
    page: 1,
    perPage: 10,
    open: false,
    reset() {
      this.loaded = false;
      this.data = [];
      this.paginatedModalData = {};
      this.page = 1;
    },
    updatePage(page) {
      this.loaded = false;
      this.page = page;
      this.fetchData();
    },
    openModal() {
      this.reset();
      this.open = true;
      this.fetchData();
    },
    fetchData() {
      let endpointWithPage;
      if (baseEndpoint.includes("?")) {
        endpointWithPage = `${baseEndpoint}&page=${this.page}&per_page=${this.perPage}`;
      } else {
        endpointWithPage = `${baseEndpoint}?page=${this.page}&per_page=${this.perPage}`;
      }

      fetch(endpointWithPage)
        .then((response) => response.json())
        .then((resp) => {
          this.data = resp.results;
          this.pagination = resp.pagination_data;
          this.loaded = true;
        });
    },
    paginatorHtml() {
      const { page, totalItems, totalPages, rangeStart, rangeEnd } =
        this.pagination;

      if (totalPages === 1) return "";

      const disablePrev = page === 1;
      const disableNext = page === totalPages;

      const html = `
        <div class="text-tiny flex items-center justify-between">
          <div class="text-gray-500 text-xs">
            ${rangeStart}–${rangeEnd} of ${totalItems}
          </div>
          <div>
            <div class="flex items-center space-x-1.5 text-gray-500">
              <button @click="updatePage(${page - 1});" class="${
                disablePrev ? "opacity-60" : ""
              } text-xs font-medium rounded shadow-sm px-2.5 py-1 border" ${
                disablePrev ? "disabled" : ""
              }>
                Previous
              </button>

              <button @click="updatePage(${page + 1});" class="${
                disableNext ? "opacity-60" : ""
              } text-xs font-medium rounded shadow-sm px-2.5 py-1 border" ${
                disableNext ? "disabled" : ""
              }>
                Next
              </button>
            </div>
          </div>
        </div>
      `;

      return html;
    },
  };
}

import { pushNotification } from "../pushNotification";

export function workdayRaasForm({
  editToken,
  raasUrl,
  connectionStatus,
  downloadPath,
}) {
  return {
    editToken,
    downloadPath,
    raasUrl,
    testResult: null,
    loading: false,
    saving: false,
    connectionStatus,
    downloading: false,
    loadingMessages: [
      "Visiting URL...",
      "Authenticating...",
      "Downloading CSV...",
    ],
    currentLoadingMessage: "Testing...",
    loadingMessageIndex: 0,
    loadingInterval: null,

    // Start download process
    async startDownload() {
      this.downloading = true;
      try {
        const response = await fetch("/admin/workday_raas/download_csv", {
          method: "GET",
          headers: {
            "X-CSRF-Token": document.querySelector("[name=csrf-token]").content,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to download report");
        }

        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "report.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("Download failed:", error);
        pushNotification({
          title: "Error",
          desc: "Failed to download report. Please try again.",
          success: false,
          position: "top_right",
        });
      } finally {
        this.downloading = false;
      }
    },

    // Test credentials without saving
    async testCredentials() {
      this.loading = true;
      this.testResult = null;
      this.startLoadingMessages();

      try {
        const result = await this.testConnection();
        this.testResult = result;
        return true;
      } catch (error) {
        console.error("Test connection failed:", error);
        this.testResult = false;
        return false;
      } finally {
        this.stopLoadingMessages();
        this.loading = false;
      }
    },

    // Start cycling through loading messages
    startLoadingMessages() {
      this.loadingMessageIndex = 0;
      this.currentLoadingMessage =
        this.loadingMessages[this.loadingMessageIndex];
      this.loadingInterval = setInterval(() => {
        if (this.loadingMessageIndex < this.loadingMessages.length - 1) {
          this.loadingMessageIndex++;
          this.currentLoadingMessage =
            this.loadingMessages[this.loadingMessageIndex];
        } else {
          this.stopLoadingMessages(); // Stop the interval if on the last message
        }
      }, 2000); // Change message every 2 seconds
    },

    // Stop cycling through loading messages
    stopLoadingMessages() {
      clearInterval(this.loadingInterval);
    },

    // Test connection helper
    async testConnection() {
      const url = document.getElementById("raas-url").value;
      const username = document.getElementById("raas-username").value;
      const password = document.getElementById("raas-password").value;

      try {
        const response = await fetch("/admin/workday_raas/test_credentials", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector("[name=csrf-token]").content,
          },
          body: JSON.stringify({ url, username, password }),
        });

        return response.ok;
      } catch (error) {
        console.error("Connection test failed:", error);
        return false;
      }
    },

    // Handle form submission
    async handleSubmit() {
      this.saving = true;

      try {
        const connectionSuccess = await this.testConnection();
        this.connectionStatus = connectionSuccess; // Update the connection status

        // Create FormData from the form
        const formData = new FormData(this.$refs.form);

        // Ensure connection status is included
        formData.set("account[workday_raas_passkey]", connectionSuccess);

        // Submit the form
        const response = await fetch(this.$refs.form.action, {
          method: "POST",
          body: formData,
          headers: {
            "X-CSRF-Token": document.querySelector("[name=csrf-token]").content,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to save settings");
        }

        // Show appropriate message based on connection test
        pushNotification({
          title: "Settings Saved",
          desc: connectionSuccess
            ? "Settings saved and connection verified."
            : "Settings saved but connection test failed! Please verify your credentials.",
          success: true,
          position: "top_right",
        });
      } catch (error) {
        console.error("Save failed:", error);
        pushNotification({
          title: "Error",
          desc: "Failed to save settings. Please try again.",
          success: false,
          position: "top_right",
        });
      } finally {
        this.saving = false;
      }
    },
  };
}

export function snapshotStatusMetadata(url) {
  let snapshotStatusMetadata;

  return {
    snapshotStatusMetadataLoaded: false,
    init() {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          snapshotStatusMetadata = data.snapshot_status_metadata;
          this.snapshotStatusMetadataLoaded = true;
        });
    },
    getSquad(id) {
      if (!snapshotStatusMetadata[id])
        console.warn(
          `ID: ${id} not found in snapshot status metadata. ${JSON.stringify(
            snapshotStatusMetadata,
          )}`,
        );

      return snapshotStatusMetadata[id];
    },
  };
}

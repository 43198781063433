export function customBehavioralQuestions() {
  return {
    namePlaceholder: "Test coverage",
    name: "",
    targetLabel: "",
    targetLabelPlaceholder: "Greater than 60%",
    targetRole: "dev",
    promptPlaceholder:
      "What percentage of the code you work in is covered by automated tests?",
    prompt: "",
    suboptimalPlaceholders: ["0-20%", "21-40%", "41-60%"],
    optimalPlaceholders: ["61-80%", "100%"],
    suboptimalChoices: [],
    optimalChoices: [],
    totalSuboptimalChoices: 3,
    totalOptimalChoices: 2,
    optimalFirst: false,
    hasError: false,
    errorText: "This workflow has errors, please resolve to continue.",
    validationChecks: 0,
    init() {
      if (this.behavioralQuestionToEdit) {
        const bq = this.behavioralQuestionToEdit;
        this.name = bq.name;
        this.prompt = bq.prompt;
        this.targetLabel = bq.target_label;
        this.optimalFirst = bq.target_direction == "u";
        this.targetRole = bq.target_role;

        if (this.optimalFirst) {
          this.swapSections();
          this.optimalChoices = bq.options
            .slice(0, bq.target_position + 1)
            .map((o) => o.label);
          this.suboptimalChoices = bq.options
            .slice(bq.target_position + 1)
            .map((o) => o.label);
        } else {
          this.suboptimalChoices = bq.options
            .slice(0, bq.target_position)
            .map((o) => o.label);
          this.optimalChoices = bq.options
            .slice(bq.target_position)
            .map((o) => o.label);
        }

        this.totalSuboptimalChoices = this.suboptimalChoices.length;
        this.totalOptimalChoices = this.optimalChoices.length;
      }
    },
    get choices() {
      let array;
      if (this.optimalFirst)
        array = [...this.optimalChoices, ...this.suboptimalChoices];
      else array = [...this.suboptimalChoices, ...this.optimalChoices];

      const trimmedArray = array.filter(
        (item) => !item || (item !== null && item?.trim() !== ""),
      );
      return trimmedArray;
    },
    get formValid() {
      let isValid;

      if (this.behavioralQuestionToEdit) {
        this.validationChecks += 1;
        const bq = this.behavioralQuestionToEdit;
        const bqOptions = bq.options.map((o) => o.label);
        isValid =
          this.name != bq.name ||
          this.prompt != bq.prompt ||
          this.targetLabel != bq.target_label ||
          this.targetRole != bq.target_role ||
          (JSON.stringify(this.choices) != JSON.stringify(bqOptions) &&
            new Set(this.choices).size == this.choices.length); // Checking for duplicates

        // Makes it so the error state only shows after something has been edited.
        if (this.validationChecks > 2) this.checkForErrors(isValid);

        return isValid;
      } else {
        isValid =
          this.choices.length > 1 &&
          !!this.name &&
          !!this.prompt &&
          !!this.targetLabel &&
          new Set(this.choices).size == this.choices.length; // Checking for duplicates

        this.checkForErrors(isValid);

        return isValid;
      }
    },
    checkForErrors(valid) {
      // This method here so that the form doesn't yell at you for being invalid
      // until after you've filled out several of the fields already.
      if (valid) this.hasError = false;

      const bq = this.behavioralQuestionToEdit;
      const bqOptions = bq?.options?.map((o) => o.label);
      if (
        bq &&
        this.name == bq.name &&
        this.prompt == bq.prompt &&
        this.targetLabel == bq.target_label &&
        JSON.stringify(this.choices) == JSON.stringify(bqOptions)
      ) {
        // Ensures that if the state refers back to the same state as the original question,
        this.hasError = false;
        return;
      }

      if (
        !!this.name &&
        !!this.prompt &&
        !!this.targetLabel &&
        this.choices.length > 1
      ) {
        this.hasError = true;
      }
    },
    getPlaceholder(index, type) {
      if (type == "suboptimal") {
        return this.suboptimalPlaceholders[index] || `Option ${index}`;
      }

      if (type == "optimal") {
        return (
          this.optimalPlaceholders[index] ||
          `Option ${this.totalSuboptimalChoices + index + 1}`
        );
      }
    },
    addSuboptimalChoiceOption() {
      this.totalSuboptimalChoices = this.totalSuboptimalChoices + 1;
    },
    addOptimalChoiceOption() {
      this.totalOptimalChoices = this.totalOptimalChoices + 1;
    },
    removeChoice(index, type) {
      if (
        this.behavioralQuestionToEdit &&
        this.behavioralQuestionToEdit.has_reused_scale
      )
        return;

      if (type === "suboptimal") {
        this.suboptimalChoices.splice(index, 1);
        this.totalSuboptimalChoices -= 1;
      } else if (type === "optimal") {
        this.optimalChoices.splice(index, 1);
        this.totalOptimalChoices -= 1;
      }
    },
    invertChoices() {
      this.optimalFirst = !this.optimalFirst;
      this.suboptimalChoices = this.suboptimalChoices.reverse();
      this.optimalChoices = this.optimalChoices.reverse();
      this.optimalPlaceholders = this.optimalPlaceholders.reverse();
      this.suboptimalPlaceholders = this.suboptimalPlaceholders.reverse();

      this.swapSections();
    },
    swapSections() {
      const container = this.$refs.choicesSection;
      const block1 = this.$refs.suboptimalSection;
      const block2 = this.$refs.optimalSection;

      container.innerHTML = "";

      if (this.optimalFirst) {
        container.appendChild(block2);
        container.appendChild(block1);
      } else {
        container.appendChild(block1);
        container.appendChild(block2);
      }
    },
    checkChoiceForDupes(index, type) {
      // See if anywhere before the current index, the values are reused
      let realIndex;

      if (
        (type == "suboptimal" && !this.optimalFirst) ||
        (type == "optimal" && this.optimalFirst)
      )
        realIndex = index;
      else if (type == "suboptimal" && this.optimalFirst)
        realIndex = this.totalOptimalChoices + index;
      else if (type == "optimal" && !this.optimalFirst)
        realIndex = this.totalSuboptimalChoices + index;

      const choicesBeforeIndex = this.choices.slice(0, realIndex);
      const choiceInQuestion = this.choices[realIndex];
      const isDupe =
        choiceInQuestion?.length > 0 &&
        choicesBeforeIndex.includes(choiceInQuestion);

      return isDupe;
    },
    createOrUpdateScaleAndQuestion() {
      if (!this.formValid) return;

      const editing = !!this.behavioralQuestionToEdit;

      const targetPosition = this.optimalFirst
        ? this.totalOptimalChoices - 1
        : this.totalSuboptimalChoices;

      const behavioralQuestion = {
        name: this.name,
        prompt: this.prompt,
        target_position: targetPosition,
        target_label: this.targetLabel,
        scale_options: this.choices,
        target_direction: this.optimalFirst ? "u" : "d",
        target_role: this.targetRole,
      };

      const path = editing
        ? `/workflows/${this.behavioralQuestionToEdit?.id}`
        : "/workflows";

      fetch(path, {
        method: editing ? "PUT" : "POST",
        body: JSON.stringify(behavioralQuestion),
        headers: { "Content-Type": "application/json" },
      })
        .then((resp) => {
          if (resp.ok) {
            const path = `${window.location.pathname}?workflow_${editing ? "saved" : "created"}=true#workflows`;
            window.location = path;
          }
        })
        .catch((error) => {
          console.error("Error fetching items", error);
        });
    },
  };
}

import { outOfView, prevKeys, nextKeys } from "../../shared/utils";

export function asyncSearchSelect(args) {
  const { endpoint, selection, onChange, key, limit, freeform } = args;

  return {
    selection,
    focusedOpt: null,
    focused: false,
    searchResults: [],
    searchResultsLoaded: false,
    key,
    freeform,
    init() {
      // Fill in the text field if freeform input is allowed, so that when the input is focused
      // the selected option doesn't disappear.
      if (freeform && !!selection) {
        this.$refs.textField.value = selection.name;
      }
    },
    focus() {
      this.focusedOpt = null;
      this.focused = true;

      const input = this.$refs.textField;

      this.$nextTick(() => {
        input.focus();
      });

      this.conductSearch(this.$refs.textField.value).then((results) => {
        this.searchResults = results.slice(0, limit - 1);
        this.searchResultsLoaded = true;
      });
    },
    async conductSearch(str) {
      const param = `q=${encodeURIComponent(str)}`;

      let url;
      if (endpoint.includes("?")) {
        url = `${endpoint}&${param}`;
      } else {
        url = `${endpoint}?${param}`;
      }

      return await fetch(url)
        .then((response) => response.json())
        .then((data) => data.results);
    },
    unfocus() {
      // Keep raw user input in if freeform input is allowed.
      if (!freeform) {
        this.searchResultsLoaded = false;
        this.$refs.textField.value = "";
      }

      this.focused = false;
      this.$refs.textField.blur();
    },
    select(opt) {
      this.selection = opt;
      if (freeform) this.$refs.textField.value = opt.name;

      this.unfocus();

      this.$nextTick(() => {
        onChange(this.$root, opt);
      });
    },
    search() {
      this.focusedOpt = null;

      const searchTerm = this.$refs.textField.value;

      this.conductSearch(searchTerm).then((results) => {
        if (searchTerm.length > 0) {
          this.searchResults = results.slice(0, 25);

          if (this.searchResults.length > 0) {
            this.focusedOpt = this.searchResults[0];
          }
        } else {
          this.searchResults = results.slice(0, limit - 1);
        }
      });
    },
    handleInputKeydown(event) {
      if (event.key == "Enter") {
        event.preventDefault();

        if (this.focusedOpt) this.select(this.focusedOpt);

        return;
      }

      if (nextKeys.includes(event.key) && this.searchResultsLoaded) {
        event.preventDefault();

        if (this.focusedOpt == null) {
          this.focusedOpt = this.searchResults[0];
        } else {
          const index = this.searchResults.findIndex((opt) => {
            return opt.id == this.focusedOpt.id;
          });

          const next = this.searchResults[index + 1];

          if (next) {
            this.focusedOpt = next;

            const ul = this.$refs.searchResultsContainer;
            const li = ul.querySelector(`[data-optId="${key}-${next.id}"]`);

            if (outOfView(ul, li)) {
              ul.scrollTo(0, li.offsetTop);
            }
          }
        }

        return;
      }

      if (prevKeys.includes(event.key) && this.searchResultsLoaded) {
        event.preventDefault();

        if (this.focusedOpt) {
          const index = this.searchResults.findIndex((opt) => {
            return opt.id == this.focusedOpt.id;
          });

          const prev = this.searchResults[index - 1];

          if (prev) {
            this.focusedOpt = prev;

            const ul = this.$refs.searchResultsContainer;
            const li = ul.querySelector(`[data-optId="${key}-${prev.id}"]`);

            ul.scrollTo(0, li.offsetTop - li.clientHeight);
          }
        }
      }

      if (event.keyCode == 9) {
        event.preventDefault();
      }
    },
    handleFreeformInput(event) {
      if (!freeform) return;

      const val = event.target.value?.trim();

      if (val) {
        this.selection = { id: val, name: val };
        onChange(this.$root, this.selection);
      } else {
        this.selection = null;
        onChange(this.$root, null);
        this.search();
      }
    },
  };
}

export function snapshotSetupCadenceAndDrivers(args) {
  const {
    minFactorsPerSnapshot,
    maxFactorsPerSnapshot,
    cadence,
    selectedFactors,
    allFactors,
  } = args;

  let originalCadence = cadence;

  return {
    cadence,
    originalCadence,
    selectedFactors,
    allFactors,
    init() {
      const form = this.$root.closest("form");

      if (form) {
        const factorEvent = new CustomEvent("dx:input-init", {
          detail: {
            name: "selectedFactors",
            values: this.selectedFactors.map((e) => e),
          },
        });
        form.dispatchEvent(factorEvent);

        this.$watch("selectedFactors", () => this.publishValues(form));
      }
    },
    publishValues(form) {
      const factorEvent = new CustomEvent("dx:input-changed", {
        detail: {
          name: "selectedFactors",
          values: this.selectedFactors.map((e) => e),
        },
      });

      form.dispatchEvent(factorEvent);
    },
    emitSaved() {
      const factorsSavedEvent = new CustomEvent("dx:factors-saved");

      document.dispatchEvent(factorsSavedEvent);
    },
    resetOriginalCadence() {
      this.originalCadence = this.cadence;
    },
    cadenceChanged() {
      return this.originalCadence != this.cadence;
    },
    toggleDriver(even, id) {
      if (this.isSelected(even, id)) {
        this.deselect(even, id);
        return;
      }

      this.select(even, id);
    },
    select(even, id) {
      this.selectedFactors.push({ id, even });
    },
    deselect(even, id) {
      const i = this.findIndex(even, id);

      if (i > -1) return this.selectedFactors.splice(i, 1);
    },
    findIndex(even, id) {
      return this.selectedFactors.findIndex(
        (f) => f.id == id && f.even == even,
      );
    },
    isSelected(even, id) {
      const i = this.findIndex(even, id);

      return i > -1;
    },
    isDisabled(even, id) {
      return !this.isSelected(even, id) && this.slotsLeft(even) <= 0;
    },
    slotsUsed(even) {
      if (even) return this.selectedFactors.filter((f) => f.even).length;

      return this.selectedFactors.filter((f) => !f.even).length;
    },
    slotsLeft(even) {
      return this.maxSlots - this.slotsUsed(even);
    },
    get isConfigValid() {
      if (this.slotsUsed(true) < this.minSlots) return false;

      if (this.isEverySixWeeks && this.slotsUsed(false) < this.minSlots)
        return false;

      return true;
    },
    copyDrivers() {
      if (this.isEverySixWeeks) {
        this.selectedFactors.forEach(({ even, id }) => {
          this.selectedFactors.push({ even: !even, id });
        });
      } else {
        this.selectedFactors
          .filter((f) => !f.even)
          .forEach(({ even, id }) => {
            this.deselect(even, id);
          });
      }
    },
    get maxSlots() {
      return maxFactorsPerSnapshot;
    },
    get minSlots() {
      return minFactorsPerSnapshot;
    },
    get cycles() {
      if (this.isQuarterly || this.isSemiAnnual) return [true];

      return [true, false];
    },
    get isQuarterly() {
      return this.cadence == 1;
    },
    get isEverySixWeeks() {
      return this.cadence == 2;
    },
    get isSemiAnnual() {
      return this.cadence == 3;
    },
  };
}

export function userMultiselectV2(args) {
  const {
    selectedUserIds = new Set(args.selectedUserIds || []),
    excludedIds,
    onChange,
  } = args;

  return {
    selectedUserIds,
    focusedUser: null,
    focused: false,
    searchResults: [],
    searchResultsLoaded: false,
    userSelected(userId) {
      return this.selectedUserIds.has(userId);
    },
    focus() {
      this.focusedUser = null;
      this.focused = true;

      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });

      return window.userSearch.search("", excludedIds).then((results) => {
        if (!results) return;
        this.searchResults = results.slice(0, 4);
        this.searchResultsLoaded = true;
      });
    },
    unfocus() {
      this.searchResultsLoaded = false;
      this.focused = false;
      this.$refs.searchInput.value = "";
      this.$refs.searchInput.blur();
      this.$nextTick(() =>
        onChange.bind(this)(this.$root, Array.from(this.selectedUserIds)),
      );
    },
    clear() {
      this.selectedUserIds = new Set();
      this.unfocus();
      this.$nextTick(() => onChange.bind(this)(this.$el, []));
    },
    search() {
      this.focusedUser = null;
      const searchTerm = this.$refs.searchInput.value;

      return window.userSearch
        .search(searchTerm, excludedIds)
        .then((results) => {
          const sortedResults = results.sort(
            (a, b) => a.name.length - b.name.length,
          );
          const slicedResults =
            searchTerm.length > 0
              ? sortedResults.slice(0, 25)
              : sortedResults.slice(0, 4);

          this.searchResults = slicedResults;
          this.focusedUser =
            slicedResults.length > 0 && searchTerm.length > 0
              ? slicedResults[0]
              : null;
        });
    },
    handleInputKeydown(event) {
      const { key } = event;

      if (key === "ArrowDown" || key === "ArrowUp") {
        event.preventDefault();
        this.navigateSearchResults(key === "ArrowDown" ? 1 : -1);
        return;
      }

      if (key === "Enter") {
        event.preventDefault();
        if (this.focusedUser) {
          this.toggleUserSelection(this.focusedUser.id.toString());
        }
        return;
      }

      if (key === "Escape") {
        event.preventDefault();
        this.unfocus();
        return;
      }

      if (key === "Tab") {
        event.preventDefault();
      }
    },

    navigateSearchResults(direction) {
      if (!this.searchResultsLoaded || this.searchResults.length === 0) return;

      const currentIndex = this.focusedUser
        ? this.searchResults.findIndex(
            (user) => user.id === this.focusedUser.id,
          )
        : -1;

      const newIndex =
        (currentIndex + direction + this.searchResults.length) %
        this.searchResults.length;
      this.focusedUser = this.searchResults[newIndex];

      this.$nextTick(() => {
        const ul = this.$refs.searchResultsContainer;
        const li = ul.querySelector(`[data-userId="${this.focusedUser.id}"]`);

        if (li && this.isOutOfView(ul, li)) {
          li.scrollIntoView({ block: "nearest" });
        }
      });
    },

    isOutOfView(container, element) {
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      return (
        elementRect.bottom > containerRect.bottom ||
        elementRect.top < containerRect.top
      );
    },

    toggleUserSelection(userId) {
      if (this.selectedUserIds.has(userId)) {
        this.selectedUserIds.delete(userId);
      } else {
        this.selectedUserIds.add(userId);
      }
    },
  };
}

function questionValuesMap(questions) {
  const map = {};

  questions.forEach((q) => {
    if (q.idk) {
      map[q.id] = -1;
      return;
    }

    map[q.id] = q.behavioral_question_option_id;
  });

  return map;
}

export function snapshotIntakeBehaviors(args) {
  const { questions } = args;

  const values = questionValuesMap(questions);

  return {
    values,
    focusedQuestionId: null,
    init() {
      this.focusNextRow();

      this.$watch("values", () => this.focusNextRow());
    },
    focusNextRow() {
      this.$nextTick(() => {
        const nextRow = document.querySelector(".x-unfilled");

        if (nextRow) this.focusedQuestionId = nextRow.dataset.question;
      });
    },
    formValid() {
      for (const [_, value] of Object.entries(this.values)) {
        if (value === null) return false;
      }

      return true;
    },
    isFilled(questionId) {
      return this.values[questionId];
    },
    isFilledOrFocused(questionId) {
      return this.isFilled(questionId) || questionId == this.focusedQuestionId;
    },
  };
}

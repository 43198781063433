export function snapshotSetupBehavioralQuestions(args) {
  const {
    minBehavioralQuestionsPerSnapshot,
    maxBehavioralQuestionsPerSnapshot,
    behavioralQuestionSamplingThreshold,
    selectedBehavioralQuestions,
    allAvailableBehavioralQuestions,
  } = args;

  return {
    behavioralQuestionSamplingThreshold,
    selectedBehavioralQuestions,
    allAvailableBehavioralQuestions,
    behavioralQuestionSelections: {},
    init() {
      allAvailableBehavioralQuestions.forEach((behavioralQuestion) => {
        this.behavioralQuestionSelections[behavioralQuestion.id] =
          selectedBehavioralQuestions.includes(behavioralQuestion.id);
      });

      const form = this.$root.closest("form");

      if (form) {
        const workflowEvent = new CustomEvent("dx:input-init", {
          detail: {
            name: "behavioralQuestionSelections",
            values: this.selectedOptions(),
          },
        });
        form.dispatchEvent(workflowEvent);

        this.$watch("behavioralQuestionSelections", () =>
          this.publishValues(form),
        );
      }
    },
    publishValues(form) {
      const workflowEvent = new CustomEvent("dx:input-changed", {
        detail: {
          name: "behavioralQuestionSelections",
          values: this.selectedOptions(),
        },
      });

      form.dispatchEvent(workflowEvent);
    },
    emitSaved() {
      const workflowsSavedEvent = new CustomEvent("dx:workflows-saved");

      document.dispatchEvent(workflowsSavedEvent);
    },
    toggleBehavioralQuestion(id) {
      if (this.behavioralQuestionSelections[id]) {
        this.behavioralQuestionSelections[id] = false;

        return;
      }

      this.behavioralQuestionSelections[id] = true;
    },
    isSelected(id) {
      return this.behavioralQuestionSelections[id];
    },
    isDisabled(id) {
      return !this.behavioralQuestionSelections[id] && this.slotsLeft() <= 0;
    },
    selectedOptions() {
      const selectedItems = [];

      for (const id in this.behavioralQuestionSelections) {
        if (this.behavioralQuestionSelections[id]) {
          selectedItems.push(id);
        }
      }

      return selectedItems;
    },
    slotsUsed() {
      return this.selectedOptions().length;
    },
    slotsLeft() {
      return this.maxSlots - this.slotsUsed();
    },
    get isConfigValid() {
      const slotsUsed = this.slotsUsed();

      if (slotsUsed < this.minSlots || slotsUsed > this.maxSlots) return false;

      return true;
    },
    get maxSlots() {
      return maxBehavioralQuestionsPerSnapshot;
    },
    get minSlots() {
      return minBehavioralQuestionsPerSnapshot;
    },
  };
}

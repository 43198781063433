window.gauge = function (args) {
  const {
    radius,
    percent,
    textSuffix = "",
    suffixOffset = "-1.125em",
    fontSize = "24px",
  } = args;
  const diameter = radius * 2;
  const strokeWidth = radius * 0.125;
  const innerRadius = radius - strokeWidth / 2;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * (250 / 360);
  const dashArray = `${arc} ${circumference}`;
  const transform = `rotate(145, ${radius}, ${radius})`;
  const percentNormalized = Math.min(Math.max(percent, 0), 100);
  const offset = arc - (percentNormalized / 100) * arc;

  return `<div>
      <svg viewBox="0 0 ${diameter} ${diameter}" height=${diameter} width=${diameter}>
        <circle
          class="gauge_base"
          cx="${radius}"
          cy="${radius}"
          fill="transparent"
          r="${innerRadius}"
          stroke="#D9D9D9"
          stroke-dasharray="${dashArray}"
          stroke-width="${strokeWidth}"
          transform="${transform}"
        />
        <circle
          class="gauge_percent"
          cx="${radius}"
          cy="${radius}"
          fill="transparent"
          r="${innerRadius}"
          stroke="#6366F1"
          stroke-dasharray="${dashArray}"
          stroke-dashoffset="${offset}"
          stroke-width="${strokeWidth}"
          transform="${transform}"
        />
         <text style="font-size: ${fontSize};" x="50%" y="50%" text-anchor="middle" stroke="black" stroke-width="1px" dy=".3em">${Math.round(percent)}<tspan style="font-size:12px" stroke-width="0px" dy="${suffixOffset}">${textSuffix}</tspan></text>‍
      </svg>
   </div>`;
};

export function atlasCatalog(initialCatalog) {
  return {
    content: initialCatalog,
    search() {
      const searchTerm = this.$refs.searchInput.value;

      if (searchTerm?.trim()) {
        fetch(`/atlas?q=${searchTerm}`)
          .then((response) => response.text())
          .then((html) => {
            this.content = html;
          });
      } else {
        this.content = initialCatalog;
      }
    },
  };
}

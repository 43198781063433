import { postData } from "../../shared/utils";

export function csatComment(comment) {
  const { text } = comment;

  let originalText;

  return {
    text,
    comment,
    menuExpanded: false,
    editingResponse: false,
    commentContextMenuExpanded: false,
    editingComment: false,
    toggleMenu() {
      this.menuExpanded = !this.menuExpanded;
    },
    closeMenu() {
      this.menuExpanded = false;
    },
    startEditingResponse() {
      // Save text to allow reverting
      originalText = this.text;

      this.menuExpanded = false;

      this.editingResponse = true;

      this.$nextTick(() => {
        this.$refs.responseTextarea.readOnly = false;
        this.$refs.responseTextarea.focus();
      });
    },
    stopEditingResponse() {
      this.editingResponse = false;
      this.$refs.responseTextarea.readOnly = true;

      // Revert
      this.text = originalText;
    },
    updateResponse() {
      postData(`/internal_tool_response/${comment.id}`, { text: this.text });

      this.editingResponse = false;
      this.$refs.responseTextarea.readOnly = true;
    },
    isAuthor(object, user) {
      return object.author.id == user.id;
    },
  };
}

export function emailConfirmation() {
  return {
    handlePaste(event) {
      const { form } = this.$refs;

      setTimeout(() => {
        if (event.target.value.length === 6) {
          const btn = form.querySelector("button[type=submit]");
          btn.disabled = true;
          btn.classList.add("btn-disabled");
          btn.classList.remove("btn-standout");
          form.submit();
        }
      }, 50);
    },
  };
}

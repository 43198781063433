export function snapshotQuickjump(args) {
  const { snapshots } = args;

  return {
    open: false,
    snapshots,
    toggle() {
      if (this.open) {
        this.open = false;
        return;
      }

      this.open = true;
    },
  };
}

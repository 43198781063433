export function snapshotSetupProgram(args) {
  const { minFactorsPerSnapshot, maxFactorsPerSnapshot, selectedFactors } =
    args;

  return {
    selectedFactors,
    factorsSaved: false,
    init() {
      document.addEventListener("dx:factors-saved", () => {
        this.factorsSaved = true;
      });
    },
    isConfigValid() {
      if (
        minFactorsPerSnapshot <= selectedFactors.length &&
        selectedFactors.length <= maxFactorsPerSnapshot
      )
        return true;

      return this.factorsSaved;
    },
  };
}

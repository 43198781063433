window.targetRoleText = function (value) {
  if (value === "dev") {
    return "People who write code";
  }

  if (value === "nondev") {
    return "People who don't write code";
  }

  return "Both people who do and don't write code";
};
